import { IntlShape, useIntl } from "react-intl";
import {
  CompanyCard,
  CompanyCardProps,
} from "../../pages/dashboard/CompanyCard";
import { CompanyState } from "../../pages/dashboard/CompanyState";
import {
  checkIfValue,
  generateUniqueId,
  getNumberPage,
} from "../../utils/helper";
import { ToastContainer, Toast } from "react-bootstrap";
import CustomButton from "../buttons/CustomButton";
import Skeleton from "react-loading-skeleton";

interface AlarmsProps {
  data?: {};
  title: string;
  courts: [];
  curators: [];
  companyCurators: [];
  companyState: string;
  loading: boolean;
  // onChangePage: (page: number) => void;
  toggleClickRow: CompanyCardProps["toggleClickRow"];
  toggleClickPagination: (
    state: string,
    page: number | null,
    time: string | null
  ) => void;
  closeAlarms: () => void;
  intl: IntlShape;
}

export default function Alarms({ data, onClose, onClickAlarms }) {
  const proceduresData = data?.["hydra:member"];
  // let filterData = proceduresData?.filter((el) => {
  //   if (el.alertDate) {
  //     const date = new Date(el.alertDate);
  //     const hours = date.getHours();
  //     const minutes = date.getMinutes();
  //     const seconds = date.getSeconds();
  //     if (hours === 0 && minutes === 0 && seconds === 0) {
  //       return el;
  //     } else {
  //       return false;
  //     }
  //   }
  // });
  /**
   * Shows a toast message containing how many alarms are present
   * When clicked on it will display the alarms
   */
  return (
    <ToastContainer
      className="p-3"
      position={"top-end"}
      style={{ zIndex: 2046 }}
      onClick={() => onClickAlarms()}
    >
      <Toast
        onClose={(e) => {
          e?.stopPropagation();
          onClose(false);
        }}
        show
        bg={"light"}
      >
        <Toast.Header className="me-auto bg-warning">
          <strong className="me-auto text-dark fs-3">Attenzione </strong>
        </Toast.Header>
        <Toast.Body>
          <div className="container_toast">
            <p className="p_toast fs-6">Sono presenti {proceduresData.length} procedure da chiamare </p>
            {/*<Button variant="link">apri</Button>*/}
          </div>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
}

export function DisplayAlarms({
  data,
  courts,
  curators,
  companyCurators,
  toggleClickRow,
  closeAlarms,
  // onChangePage,
  loading,
}: AlarmsProps) {
  const intl = useIntl();
  const courtsPast = courts;
  const curatorsPast = curators;
  const companyCuratorsPast = companyCurators;

  const pastData = data?.["hydra:member"].filter((el) => {
    // if (el.alertDate) {
    //   const date = new Date(el.alertDate);
    //   console.log(date);
    //   const hours = date.getHours();
    //   console.log(hours);
    //   const minutes = date.getMinutes();
    //   console.log(minutes);
    //   const seconds = date.getSeconds();
    //   console.log(seconds);
    //   if (!(hours === 0 && minutes === 0 && seconds === 0)) {
    //     return el;
    //   }
    // }
    return el;
  });
 const handleAction = (id,name) => {
  if(toggleClickRow){
    toggleClickRow(id,name);
    closeAlarms()
  }  

 }
  return (
    <div className="main_container_alarms shadow-sm">
      <div className="header">
        <button
          onClick={closeAlarms}
          type="button"
          className="btn btn-sm btn-icon btn-active-light-primary me-n5 ms-3"
          id="kt_activities_close"
        >
          <i className="fa-solid fa-xmark"></i>
        </button>
        <h5 className="p_header ms-3">Procedure da chiamare</h5>
      </div>
      <div className="container-content">
        <div className="container_card">
          {loading ? (
            <Skeleton
              style={{ minHeight: "150px", borderRadius: "6px" }}
            ></Skeleton>
          ) : (
            <>
              {pastData && pastData.length > 0 ? (
                pastData.map(
                  (
                    company: {
                      id: string;
                      name: string;
                      state: string;
                      isFollower: boolean;
                      isImportant: boolean;
                      isUrgent: boolean;
                      hasPhotos: boolean;
                      relevance: number;
                      codRea: string;
                      court: string;
                      alertDate: string;
                      companyCurators: string[];
                      procedureNumber: string;
                      procedureTypology: string;
                    },
                    index: number
                  ) => (
                    <>
                      <CompanyCard
                        key={`${index}+${generateUniqueId()}`}
                        company={company}
                        courts={courtsPast}
                        curators={curatorsPast}
                        companyCurators={companyCuratorsPast}
                        index={index}
                        toggleClickRow={handleAction}
                        title={CompanyState.PAST}
                      />
                    </>
                  )
                )
              ) : (
                <div className="empty-element">
                  <i className="fa-regular fa-file"></i>
                  <p>{intl.formatMessage({ id: "Empty.company" })}</p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="footer p-4">
        <CustomButton onClick={closeAlarms}>Chiudi</CustomButton>
      </div>
    </div>
  );
}
