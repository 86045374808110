import { DynamicTypes, SettingDetail } from "../../typings/dn";
import { apiCall } from "../../utils/serviceCall";
import {
  HTTPMethod,
  generateUniqueId,
  initialOrderDnd,
} from "../../utils/helper";
import Settings from "../../json/settings.json";

const handleRowDelete = (order: SettingDetail, rowIndex: number) => {
  const newOrder = [...order.Rows];
  newOrder.splice(rowIndex, 1);
  const updatedOrder = { ...order, Rows: newOrder };

  return updatedOrder;
};
const handleClassNameChange = (
  order: SettingDetail,
  rowIndex: number,
  newClassName: string,
  columnIndex?: number
): SettingDetail => {
  let updatedOrder = order;
  if (columnIndex !== undefined) {
    updatedOrder.Rows[rowIndex].Columns[columnIndex!].ClassNameCol =
      newClassName;
  } else {
    updatedOrder.Rows[rowIndex].ClassNameRow = newClassName;
  }

  return updatedOrder;
};
const handleChangeMain = (
  order: SettingDetail,
  rowIndex: number,
  columnIndex: number,
  value:string
) => {
  let updatedOrder = Object.assign({}, order);
  function updateMainProperty(obj) {
    if (obj instanceof Array) {
      // Se l'oggetto è un array, esegui la funzione per ogni elemento dell'array
      obj.forEach(updateMainProperty);
    } else if (obj instanceof Object) {
      // Se l'oggetto è un oggetto, esegui la funzione per ogni proprietà dell'oggetto
      for (let key in obj) {
        if (key === "Main") {
          obj[key] = null; // Imposta "Main" su false se la chiave è "Main"
        } else {
          updateMainProperty(obj[key]); // Altrimenti, esegui la funzione ricorsivamente
        }
      }
    }
  }


  console.log(updatedOrder);
  console.log(updatedOrder.Rows[rowIndex].Columns[columnIndex!])
  if (updatedOrder.Rows[rowIndex].Columns[columnIndex!].Main) {
    updateMainProperty(updatedOrder);
  } else {
    updateMainProperty(updatedOrder);
    updatedOrder.Rows[rowIndex].Columns[columnIndex!].Main = value;
  }

  return updatedOrder;
};

const handleColumnDelete = (
  order: SettingDetail,
  rowIndex: number,
  columnIndex: number
) => {
  const newOrder = [...order.Rows];
  newOrder[rowIndex].Columns.splice(columnIndex, 1);
  const updatedOrder = { ...order, Rows: newOrder };

  return updatedOrder;
};

const handleColumnAdd = (order: SettingDetail, rowIndex: number) => {
  const newOrder = [...order.Rows];
  /**
   * @todo fix bug: Do not allow more entries if all field names are already used
   */
  newOrder[rowIndex].Columns.push({
    DynamicComponent: `space-${generateUniqueId()}`,
  });
  const updatedOrder = { ...order, Rows: newOrder };

  return updatedOrder;
};

const handleRowAdd = (order: SettingDetail) => {
  const newOrder = [...order.Rows];
  newOrder.push({ Columns: [] });
  const updatedOrder = { ...order, Rows: newOrder };

  return updatedOrder;
};

const handleColumnEdit = (
  order: SettingDetail,
  rowIndex: number,
  columnIndex: number,
  value: DynamicTypes
) => {
  const newOrder = [...order.Rows];
  newOrder[rowIndex].Columns[columnIndex].DynamicComponent = value;
  const updatedOrder = { ...order, Rows: newOrder };

  return updatedOrder;
};

const saveOrderToApi = async (
  updatedOrder: SettingDetail,
  pathName: string
) => {
  try {
    await apiCall(HTTPMethod.PATCH, `/settings/${pathName}`, {
      name: pathName,
      data: [JSON.stringify(updatedOrder)],
    });

    // store.dispatch({
    //     type: MessagesReducerTypeActions.SET, payload: {
    //         type: MessageTypeEnum.SUCCESS,
    //         message: intl.formatMessage({
    //             id: 'Settings.saveSuccess'
    //         })
    //     }
    // })
  } catch (error) {
    if (error?.response?.status === 404) {
      await createOrderToApi(updatedOrder as SettingDetail, pathName);
    }
    console.error("Failed to save order:", error);
  }
};
const createOrderToApi = async (order: SettingDetail, pathName: string) => {
  try {
    const requestData = {
      name: pathName,
      data: [JSON.stringify(order)], // Supponendo che order sia già un oggetto JSON
    };

    return await apiCall(HTTPMethod.POST, "/settings", requestData);
  } catch (error) {
    console.error(`errore durante la creazione di setting/${pathName}`, error);
    throw error;
  }
};

async function fetchInitialOrder<T>(
  pathName: string,
  setOrder: React.Dispatch<React.SetStateAction<T>>,
  initialOrderOverride?: T
) {
  if (!setOrder) {
    throw new Error('callback function "setOrder" must be provided');
  } else if (!initialOrderOverride && !pathName) {
    throw new Error("Either initialOrderOverride or location must be provided");
  }

  try {
    const response = await apiCall(HTTPMethod.GET, `/settings/${pathName}`);
    const initialOrder = response.data.data ? response.data.data[0] : null;
    setOrder(JSON.parse(initialOrder as string));
  } catch (error) {
    if (error?.response?.status === 404 || initialOrderOverride) {
      const orderToSend = initialOrderOverride
        ? initialOrderOverride
        : initialOrderDnd;
      setOrder(orderToSend as T);
      await createOrderToApi(orderToSend as SettingDetail, pathName);
    }
    console.error("Failed to fetch initial detail:", error);
  }
}

async function getSettingsByEntity(pathName: string) {
  let initialSettings = {
    name: pathName,
    data: [],
    list: [],
  };
  if (!pathName) {
    throw new Error("Either location must be provided");
  }
  let settings;
  try {
    const response = await apiCall(HTTPMethod.GET, `/settings/${pathName}`);
    settings = response.data ? response.data : null;
  } catch (e) {
    settings = Settings[pathName]
      ? { ...Settings[pathName], name: pathName }
      : initialSettings;
    await apiCall(HTTPMethod.POST, `/settings`, settings).catch(
      () => (settings = null)
    );
  }

  return settings;
}

export {
  handleClassNameChange,
  handleRowAdd,
  saveOrderToApi,
  handleColumnAdd,
  handleRowDelete,
  handleColumnEdit,
  fetchInitialOrder,
  handleColumnDelete,
  getSettingsByEntity,
  handleChangeMain,
};
