import clsx from "clsx";
import React, { useEffect, useMemo, useState } from "react";
import { apiCall } from "../../../utils/serviceCall";
import { HTTPMethod, parseDashboardDetailsPath } from "../../../utils/helper";
import { useLocation } from "react-router";
import { CombineReducersState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { NotificationReducerTypeActions } from "../../../redux/stores/notification/notificationReducer";
export default function IconBooleanEditor({ props }) {
  const [data, setData] = useState<boolean>(props.data);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { reloadAlarms } = useSelector(
    (state: CombineReducersState) => state.notificationReducer
  );
  const { icon, color, path, field, reloadAlarms: forceReloadAlarms } = props.type.editor;
  const active = props.data ? color : "grey";
  const location = useLocation();
  let pathstart = location.pathname;

  const { pathId, pathName } = parseDashboardDetailsPath(pathstart);
  const updateByItself: boolean | "makeGet" = path ? "makeGet" : false;
  const toogleSaveChange = async () => {
    const updateValue = !data;
    if (path) {
      setLoading(true);
      try {
        await apiCall(HTTPMethod.POST, `/${pathName}/${pathId}/${path}`, {
          [field]: updateValue,
        });
        if(forceReloadAlarms){
          dispatch({
            type: NotificationReducerTypeActions.SET_NOTIFICATION,
            payload: { reloadAlarms: +reloadAlarms + 1 },
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
    setData(updateValue);
    props.setValue(props.name, updateValue, updateByItself);
  };
  const state = useSelector(
    (state: CombineReducersState) => state.detailsReducer
  );

  const valueBadge = useMemo(() => {
    return state?.[pathName]?.[pathId]?.form["foto"];
  }, [state?.[pathName]?.[pathId]?.form["foto"]]);
  return (
    <div>
      {!loading ? (
        <>
          {" "}
          <i
            className={clsx(icon, 'cursor-pointer')}
            style={{ color: active }}
            onClick={() => {
              toogleSaveChange();
            }}
          ></i>
          {props.nameField === "hasPhotos" && valueBadge > 0 && (
            <span
              style={{
                fontSize: "10px",
              }}
              className="badge badge-pill badge-danger"
            >
              {valueBadge}
            </span>
          )}
        </>
      ) : (
        <div className="spinner-border " style={{ color: color }} role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      )}
    </div>
  );
}
