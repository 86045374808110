import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';

function useOutsideAlerter(ref: React.RefObject<HTMLElement>, onClickOutside: () => void) {
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                onClickOutside();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, onClickOutside]);
}

interface OutsideClickWrapperProps {
    children: any, 
    onClickOutside: () => void,
    className?:string;
}

const OutsideClickWrapper: React.FC<OutsideClickWrapperProps> = ({ children, onClickOutside,className= '' }) => {
    const wrapperRef = useRef<any | null>(null);
    useOutsideAlerter(wrapperRef, onClickOutside);
    return <div className={clsx('w-100',className)} ref={wrapperRef}>{children}</div>;
};

export default OutsideClickWrapper;
