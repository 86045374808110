import clsx from 'clsx'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import { useIntl } from 'react-intl'

/* Metronic */
import { useLayout } from '../../vendor/_metronic/layout/core'
import { checkIsActive, KTIcon, WithChildren } from '../../vendor/_metronic/helpers'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
  to,
  title,
  icon,
  children,
  fontIcon,
  hasBullet = false,
}) => {
  const intl = useIntl()
  const { pathname } = useLocation()
  const isActive = checkIsActive(pathname, to)
  const { config } = useLayout()
  const { app } = config

  return (
    <div className='menu-item'>
      <Link className={clsx('menu-link without-sub', { active: isActive })} to={to} reloadDocument={true}>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {/* {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className='menu-icon'>
            {' '}
            <KTIcon iconName={icon} className='fs-2' />
          </span>
        )} */}
        {icon  && (
          <i  className={clsx('fs-3 m-3 w-15px text-center ', icon)}></i>
        )}
        <span className='menu-title'>{intl.formatMessage({ id: title })}</span>
      </Link>
      {children}
    </div>
  )
}

export { SidebarMenuItem }
