import React from "react";

export default function CuratorsLinkEditor({ props }) {
  const cnfLinks =
    "https://www.consiglionazionaleforense.it/web/cnf/ricerca-avvocati";
    const { dataFields } = props;
  const makeCndcLinks = () => {
  
    return `https://ricerca.commercialisti.it/RicercaIscritti?Nome=${dataFields.firstName}&Cognome=${dataFields.lastName}&Sezione=-1`;
  };
  return (
    <div className="readonly">
      <a href={makeCndcLinks()} target="_blank" rel="noopener noreferrer">
        CNDC
      </a>{" "}
      -{" "}
      <a href={cnfLinks} target="_blank" rel="noopener noreferrer">
        CNF
      </a>
    </div>
  );
}
