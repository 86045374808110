import { useState, useEffect } from "react";
import {
  MessagesReducerTypeActions,
  MessageTypeEnum,
} from "../../../../redux/stores/message/messageReducer";
import { HTTPMethod } from "../../../../utils/helper";
import { apiCall } from "../../../../utils/serviceCall";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";

/**
 * A component wrapping an img tag which is filled in with a downloaded image.
 * It downloads the image at render then it applies to src tag.
 * @param Object with id and type of attachment to download -
 * @returns The ImageFull component with an image inside it
 */
const ImageFull = ({ id, type }: { id: number; type: any }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [imgSrc, setImgSrc] = useState("");
  useEffect(() => {
    getSource();
  }, []);
  const getSource = async () => {
    apiCall(HTTPMethod.GET, `/${type.editor.path}/${id}/download`)
      .then((response) => {
        const result = response.data;
        const download = result.downLoad;
        if (download) {
          setImgSrc(download);
        }
      })
      .catch((err) => {
        dispatch({
          type: MessagesReducerTypeActions.SET,
          payload: {
            type: MessageTypeEnum.DANGER,
            message: intl.formatMessage({
              id: "Attachments.errGalleryLoad",
            }),
          },
        });
      })
      .finally(() => {
        // setLoading((prev) => ({
        //   ...prev,
        //   [attachment.typology as string]: false,
        // }));
      });
  };

  return <div>{imgSrc && <img className="max-w-full" src={imgSrc}  />}</div>;
};
export default ImageFull;
