import React, { FC, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
// import { ViewDynamicComponentStructure } from "../../utils";
import PanelCanvas from "../../models/panelCanvas/panelCanvas";

const Detail: FC = () => {
  const location = useLocation()
  const path = location.pathname
  const pathName = path.split('/')[1]
  const [subPanel, setSubPanel] = useState<{
    route: string,
    id: string
  } | null>(null)
  const navigate = useNavigate()
  const returnToList = (): void => {
    navigate(`/${pathName}`)
  }
  useEffect(() => {
    if (location.hasOwnProperty("hash") && location.hash) {
      const resultHash = location.hash.split('#').pop()
      let idHash 
        if(resultHash!.split('/').length > 1){
           idHash =resultHash!.split('/').pop()
        }
      const pathHash = resultHash?.split('/')[0]
      if(pathHash){setSubPanel({ route: pathHash, id: idHash as string })}
    } else {
      setSubPanel(null)
    }


  }, [location])


  useEffect(() => {
    const offcanvasElements = document.querySelectorAll('.offcanvas-backdrop.fade.show');
    if (offcanvasElements && offcanvasElements.length > 1) {
      for (let i = 1; i < offcanvasElements.length; i++) {
        offcanvasElements[i].remove();
      }
    }
  })
  return <>

    <PanelCanvas onHide={returnToList}>
    </PanelCanvas>
    {
      subPanel && <PanelCanvas hashData={subPanel} onHide={() => setSubPanel(null)}></PanelCanvas>
    }




  </>


};

export default Detail