import React, { useEffect, useState } from "react";

import { useIntl } from "react-intl";

import { useDispatch } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { EIconType, HTTPMethod, parseDashboardDetailsPath } from "../../../utils/helper";
import { apiCall } from "../../../utils/serviceCall";
import {
  MessagesReducerTypeActions,
  MessageTypeEnum,
} from "../../../redux/stores/message/messageReducer";
import BasicInput from "./BasicInput";
import SelectEditor from "./SelectEditor";
import clsx from "clsx";
import { useLocation } from "react-router";
import moment from "moment";
import EmptyPlaceholder from "../../EmptyPlaceholder/EmptyPlaceholder";
import { Icon } from "../../Icon/Icon";

interface typeField {
  type: string;
  field?: string;
  reference?: string;
  topFields?: string[];
  bottomFields?: string[];
  icon?: string;
}
export default function SubReferenceEditor({ props }) {
  const path: string = props.type.editor.path;
  const listFields: string[] =
    props.type?.editor?.fields && Object.keys(props.type?.editor?.fields);
  const editorTranslate = props.type?.editor?.translate;
  const dataFields = props.type?.editor?.fields;
  const locationFieldDefault = props.type?.editor?.locationFieldDefault?.fields;
  const intl = useIntl();
  const labels = props.type?.editor?.labels;
  const location = useLocation();
  let pathstart = location.pathname;
  const { pathId, pathName } = parseDashboardDetailsPath(pathstart);
  const initialShowInput = Object.fromEntries(
    listFields.map((item) => [item, false])
  );
  const [showInput, setShowInput] = useState<{ [key: string]: boolean }>(
    initialShowInput
  );
  const initialElement = locationFieldDefault
    ? {
        [locationFieldDefault]: `/api/${pathName}/${pathId}`,
      }
    : {};
  const [element, setElement] = useState<{ [key: string]: any }>(
    initialElement
  );
  const [loading, setLoading] = useState<boolean>(true);
  const id = props.data
    ? typeof props.data === "number"
      ? props.data
      : props.data.split("/").pop()
    : undefined;

  const dispatch = useDispatch();
  const checkEmptyValue = (value, field) => {
    const translate = props.type.editor.fields[field]?.translate;
    return value ? (
      <>
        {dataFields[field]?.type === "datetime-local" && value ? (
          moment(new Date(element[field])).format("YYYY-MM-DD HH:mm:ss")
        ) : translate ? (
          <>
            {intl.formatMessage({
              id: `${translate}.${value}`,
            })}
          </>
        ) : (
          value
        )}
      </>
    ) : (
      <EmptyPlaceholder nameField={field} nameDetail={path} />
    );
  };
  const initialize = async () => {
    setLoading(true);

    await apiCall(HTTPMethod.GET, `/${path}/${id}`)
      .then((response) => {
        const result = response.data;
        setElement({ ...element, ...result });
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  const handleShowInput = (name, value) => {
    setShowInput((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleChange = async (name, data) => {
    if (element[name] !== data) {
      if (!props.data) {
        try {
          const res = await apiCall(HTTPMethod.POST, `/${path}`, {
            [name]: data,
            ...initialElement,
          });
          // initialize();
          props.setValue({ name, value: res.data["@id"] });
          dispatch({
            type: MessagesReducerTypeActions.SET,
            payload: {
              type: MessageTypeEnum.SUCCESS,
              message: intl.formatMessage({ id: "SaveSuccess" }),
            },
          });
        } catch {
          dispatch({
            type: MessagesReducerTypeActions.SET,
            payload: {
              type: MessageTypeEnum.DANGER,
              message: intl.formatMessage({ id: "SaveError" }),
            },
          });
        }
      } else {
        try {
          const res = await apiCall(HTTPMethod.PATCH, `/${path}/${id}`, {
            [name]: data,
          });
          initialize();
          // setdetailData(data.data)
          dispatch({
            type: MessagesReducerTypeActions.SET,
            payload: {
              type: MessageTypeEnum.SUCCESS,
              message: intl.formatMessage({ id: "SaveSuccess" }),
            },
          });
        } catch {
          dispatch({
            type: MessagesReducerTypeActions.SET,
            payload: {
              type: MessageTypeEnum.DANGER,
              message: intl.formatMessage({ id: "SaveError" }),
            },
          });
        }
      }
    }
  };
  useEffect(
    () => {
      if (id) {
        initialize();
      } else {
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    [props.data]
  );

  return (
    <>
      <div className={clsx(`${path}Editor`, "w-100")}>
        {loading ? (
          <Skeleton></Skeleton>
        ) : (
          element && (
            <>
              <div className={`${path}EditorContainer`}>
                <>
                  {listFields.map((field, idx) => {
                    const options = props.type.editor.fields[field]?.options;
                    const translate =
                      props.type.editor.fields[field]?.translate;
                    const inline = props.type.editor.fields[field]?.inline;
                    const icon = props.type.editor.fields[field]?.icon;
                    return (
                      <div
                        className={clsx(inline ? `${path}InlineLabel` : "")}
                        key={`${path}-${field}-${idx}`}
                      >
                        {icon && (
                          <Icon
                            nameIcon={icon.iconCode}
                            className="icon-editor"
                            type={
                              icon.iconCode.includes("fa-")
                                ? EIconType.FONTAWESOME
                                : EIconType.MATERIAL
                            }
                            
                          />
                        )}
                        {labels !== false && (
                          <div className="dynamic-editor-label">
                            {editorTranslate
                              ? intl.formatMessage({
                                  id: `${editorTranslate}.${field}`,
                                })
                              : field}
                          </div>
                        )}
                        {showInput[field] ? (
                          <>
                            {options ? (
                              <SelectEditor
                                props={{
                                  name: field,
                                  data: element[field],
                                  setValue: (name, value) => {
                                    handleChange(name, value);
                                  },
                                  setShowInput: (e) => {
                                    handleShowInput(field, e);
                                  },
                                  type: {
                                    type: "string",
                                    editor: {
                                      translate: translate,
                                      options: options,
                                    },
                                  },
                                }}
                              ></SelectEditor>
                            ) : (
                              <BasicInput
                                props={{
                                  data:
                                    dataFields[field]?.type &&
                                    dataFields[field]?.type ===
                                      "datetime-local" &&
                                    element[field]
                                      ? moment(new Date(element[field])).format(
                                          "YYYY-MM-DD HH:mm:ss"
                                        )
                                      : element[field],
                                  setShowInput: (e) => {
                                    handleShowInput(field, e);
                                  },
                                  name: field,
                                  setValue: (name, value) => {
                                    handleChange(name, value);
                                  },
                                }}
                                typeBasicInput={
                                  dataFields[field]?.type
                                    ? dataFields[field]?.type
                                    : "text"
                                }
                              ></BasicInput>
                            )}
                          </>
                        ) : (
                          <>
                            <div
                              className="readonly cursor-pointer"
                              onClick={() => handleShowInput(field, true)}
                            >
                              {checkEmptyValue(element[field], field)}
                            </div>
                          </>
                        )}
                      </div>
                    );
                  })}
                </>
              </div>
            </>
          )
        )}
      </div>
    </>
  );
}
