export const Entities = {
  COMPANIES: "companies",
};

export interface ApiPlatformWrapper<T> {
  data: T;
}

export interface User {
  id: number;
}
