/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'

import { useAuth } from '../../vendor/app/modules/auth'
// import { getUserByToken, login } from '../../vendor/app/modules/auth/core/_requests'
import { getUserByToken, login } from '../../utils/authRequest'
import { useDispatch } from 'react-redux'
import { actionsTypeUser } from '../../redux/stores/user/userReducer'
import { SettingsReducerTypeActions } from "../../redux/stores/setting/settingReducer";
import { apiCall } from "../../utils/serviceCall";
import { HTTPMethod } from "../../utils/helper";
import { DToast, DToast as Toast } from "../../models/toast/Toast";
import { MessagesReducerTypeActions, MessageTypeEnum } from "../../redux/stores/message/messageReducer";
import { FormattedMessage, useIntl } from "react-intl";


/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {

    const intl = useIntl()
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const { saveAuth, setCurrentUser } = useAuth()

    const loginSchema = Yup.object().shape({
        email: Yup.string()
            .email(intl.formatMessage({ id: 'LOGIN.Formato email non valido' }))
            .required(intl.formatMessage({ id: 'LOGIN.Email obbligatoria' })),
        password: Yup.string()
            .required(intl.formatMessage({ id: 'LOGIN.Password obbligaroria' }))
    })

    const initialValues = {
        email: '',
        password: '',
    }
    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, { setStatus, setSubmitting, setErrors }) => {

            setLoading(true)
            try {
                const { data: auth } = await login(values.email, values.password)
                const { token, refresh_token } = auth
                // saveAuth(auth)
                const { data: user } = await getUserByToken(token)
                const userToSave = { token: token, refresh_token: refresh_token, userData: user }
                dispatch({ type: actionsTypeUser.set, payload: userToSave })

                const { data: settings } = await apiCall(HTTPMethod.GET, '/settings')

                dispatch({ type: SettingsReducerTypeActions.SET_ENTITIES, payload: { entity: settings['hydra:member'] } })

                dispatch({
                    type: MessagesReducerTypeActions.SET, payload: {
                        type: MessageTypeEnum.SUCCESS,
                        message: intl.formatMessage({
                            id: 'LOGIN.Benvenuto {name}'
                        }, { name: user.email })
                    }
                })

                // todo questo è da cambiare???
                window.location.href = '/dashboard'

            } catch (error) {
                dispatch({
                    type: MessagesReducerTypeActions.SET, payload: {
                        type: MessageTypeEnum.DANGER,
                        message: intl.formatMessage({ id: 'LOGIN.Credenziali errate' })
                    }
                })

                // saveAuth(undefined)
                setStatus('The login details are incorrect')
                setErrors({
                    email: intl.formatMessage({ id: 'LOGIN.email error' }),
                    password: intl.formatMessage({ id: 'LOGIN.password error' }),
                })
                setSubmitting(false)
                setLoading(false)
            }
        },
    })

    return (
        <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
        >
            {/* begin::Heading */}
            <div className='text-center mb-11'>
                <h1 className='text-dark fw-bolder mb-3'>
                    <FormattedMessage id={'LOGIN.Accedi'} />
                </h1>
                <p className='text-dark mb-3'>
                    <FormattedMessage id={'LOGIN.Insert'} />
                </p>
            </div>

            <div className='fv-row mb-8'>
                <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
                <input
                    placeholder='Email'
                    {...formik.getFieldProps('email')}
                    className={clsx(
                        'form-control bg-transparent',
                        { 'is-invalid': formik.touched.email && formik.errors.email },
                        {
                            'is-valid': formik.touched.email && !formik.errors.email,
                        }
                    )}
                    type='email'
                    name='email'
                    autoComplete='off'
                />
                {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.email}</span>
                        </div>
                    </div>
                )}
            </div>

            <div className='fv-row mb-3'>
                <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
                <input
                    type='password'
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                        'form-control bg-transparent',
                        {
                            'is-invalid': formik.touched.password && formik.errors.password,
                        },
                        {
                            'is-valid': formik.touched.password && !formik.errors.password,
                        }
                    )}
                />
                {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.password}</span>
                        </div>
                    </div>
                )}
            </div>

            <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
                {/* <Link to='/auth/forgot-password' className='link-primary'>
                    Forgot Password ?
                </Link> */}
            </div>

            <div className='d-grid mb-10'>
                <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-primary'
                    disabled={formik.isSubmitting || !formik.isValid}
                >
                    {!loading && <span className='indicator-label'> <FormattedMessage id={'LOGIN.Accedi'} /></span>}
                    {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            <FormattedMessage id={'LOGIN.Accesso in corso...'} />
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>

            </div>

            {/* 
            <div className='text-gray-500 text-center fw-semibold fs-6'>
                Not a Member yet?{' '}
                <Link to='/auth/registration' className='link-primary'>
                    Sign up
                </Link>
            </div> */}
        </form>
    )
}
