import { useCallback, useEffect, useState } from "react";
import FocusWrapper from "../../FocusWrapper/FocusWrapper";
import { useLocation } from "react-router-dom";
import { handleStore, parseDashboardDetailsPath } from "../../../utils/helper";

export default function TextAreaEditor({ props }) {
  const [data, setData] = useState<string>(props.data);
  const { store } = props.type.editor;
  const location = useLocation();
  let pathstart = location.pathname;
  const { pathId, pathName } = parseDashboardDetailsPath(pathstart);
  useEffect(() => {
    if (store) {
        handleStore(data,{pathName,pathId,formField:props.nameField})
    }
  }, []);
  return (
    <>
      <FocusWrapper>
        <textarea
          value={data}
          className="form-control form-control-solid"
          onChange={(e) => {
            setData(e.target.value);
          }}
          onBlur={() => {
            handleStore(data,{pathName,pathId,formField:props.nameField})
            props.setValue(props.name, data);
            props.setShowInput(false);
          }}
        />
      </FocusWrapper>
    </>
  );
}
