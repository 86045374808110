import { Link, useNavigate } from "react-router-dom";
import { useLayout } from "../../vendor/_metronic/layout/core";
import { toAbsoluteUrl } from "../../vendor/_metronic/helpers";
import { useDispatch } from "react-redux";
import { actionsTypeFilter } from "../../redux/stores/filterReducer/filterReducer";

type PropsType = {
  logoRef: string;
};

const SidebarLogo = (props: PropsType) => {
  const { config } = useLayout();
  const logoRef = props.logoRef;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div
      className="app-sidebar-logo px-6 cursor-pointer"
      id="kt_app_sidebar_logo"
      onClick={() => {
        dispatch({
          type: actionsTypeFilter.clear,
        });
        navigate("/dashboard");
      }}
    >
      {config.layoutType === "dark-sidebar" ? (
        <>
          <img
            alt="Logo"
            src={toAbsoluteUrl(logoRef)}
            className="h-25px app-sidebar-logo-default"
          />
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/logos/logo01machinery.svg")}
            className="h-25px app-sidebar-logo-minimize"
          />
        </>
      ) : (
        <>
          <img
            alt="Logo"
            src={toAbsoluteUrl(logoRef)}
            className="h-25px app-sidebar-logo-default theme-light-show"
          />
          <img
            alt="Logo"
            src={toAbsoluteUrl(logoRef)}
            className="h-25px app-sidebar-logo-default theme-dark-show"
          />
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/logos/logo01machinery.svg")}
            className="h-25px app-sidebar-logo-minimize position-absolute top-50 start-25 translate-middle"
          />
        </>
      )}
    </div>
  );
};

export { SidebarLogo };
