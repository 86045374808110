/* React */
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Outlet } from "react-router-dom";

/* Redux */
import { Provider } from "react-redux";
import { persistor, store } from "./redux/store.ts"; // The Redux store

/* Metronic */
import { MasterInit } from "./vendor/_metronic/layout/MasterInit.tsx";
import { I18nProvider } from "./vendor/_metronic/i18n/i18nProvider.tsx";
import { LayoutProvider } from "./vendor/_metronic/layout/core/index.ts";
import { MetronicI18nProvider } from "./vendor/_metronic/i18n/Metronici18n.tsx";

/* S/CSS */
import "react-loading-skeleton/dist/skeleton.css";
import "./vendor/_metronic/assets/sass/style.scss";
import "./vendor/_metronic/assets/sass/plugins.scss";
import "./vendor/_metronic/assets/fonticon/fonticon.css";
import "./vendor/_metronic/assets/sass/style.react.scss";
import "./vendor/_metronic/assets/keenicons/solid/style.css";
import "./vendor/_metronic/assets/keenicons/outline/style.css";
import "./vendor/_metronic/assets/keenicons/duotone/style.css";

// moment locales
import "moment/locale/it";
/* custom */
import "./sass/global.scss"; // Sets the global styles for the app

/* routing */
// Provides a basic schema for routing, every page passes through this
import Routing from "./Routes.tsx";

/* loading */
import { PersistGate } from "redux-persist/integration/react";
import LoadingSplashScreen from "./pages/loading/LoadingSplashScreen.tsx";
import { DToast } from "./models/toast/Toast";
import { DashboardProvider } from "./context/DashboardContext.tsx";

/* get the root element from the DOM */
const root = ReactDOM.createRoot(document.getElementById("root")!);

/* render the App */
root.render(
  <>
    <Suspense fallback={<LoadingSplashScreen />}>
      {" "}
      {/* Show a loading screen while the App loads */}
      <Provider store={store}>
        {" "}
        {/* Have the entire App use the Redux store */}
        <DashboardProvider>
          <PersistGate loading={<LoadingSplashScreen />} persistor={persistor}>
            {/* Persist the Redux store */}
            <MetronicI18nProvider>
              <I18nProvider>
                <LayoutProvider>
                  <Outlet />
                  <Routing />{" "}
                  {/* Render the requested routes from `./Routes.tsx` dynamically */}
                  <DToast></DToast>
                  <MasterInit /> {/* Initialize the Metronic layout */}
                </LayoutProvider>
              </I18nProvider>
            </MetronicI18nProvider>
          </PersistGate>
        </DashboardProvider>
      </Provider>
    </Suspense>
  </>
);
