// import clsx from "clsx";
import { useEffect, useMemo, useRef, useState } from "react";
import { apiCall } from "../../../utils/serviceCall";
import {
  HTTPMethod,
  dateIsValid,
  generateUniqueId,
  parseDashboardDetailsPath,
} from "../../../utils/helper";
import { useLocation } from "react-router";
import { useLang } from "../../../vendor/_metronic/i18n/Metronici18n";
import moment from "moment";
import { useIntl } from "react-intl";
import clsx from "clsx";
import CustomButton from "../../buttons/CustomButton";
import {
  MessagesReducerTypeActions,
  MessageTypeEnum,
} from "../../../redux/stores/message/messageReducer";
import { useDispatch } from "react-redux";
import FocusWrapper from "../../FocusWrapper/FocusWrapper";
import { CombineReducersState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { NotificationReducerTypeActions } from "../../../redux/stores/notification/notificationReducer";
export default function DateTimeInput({ props, typeBasicInput }) {
  const defaultFormatMoment = {
    date: "YYYY-MM-DD",
    "datetime-local": "YYYY-MM-DD HH:mm:ss",
    time: "hh:mm",
  };
  const uniqueId = generateUniqueId();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [data, setData] = useState<string | null>(props.data);
  const [showInput, setShowInput] = useState<boolean>(false);
  const location = useLocation();
  const lang = useLang();
  const defaultDate = new Date().setHours(0, 0, 0, 0);
  const checkDefaultDate = (value) => {
    if (dateIsValid(new Date(data!))) {
      return moment(new Date(value)).format(
        defaultFormatMoment[typeBasicInput]
      );
    } else {
      const formattedDetaultDate = moment(defaultDate).format(
        defaultFormatMoment[typeBasicInput]
      );
      setData(formattedDetaultDate);
      return formattedDetaultDate;
    }
  };
  const formatDateMoment = props.type.editor.hasOwnProperty("format")
    ? props.type.editor.format
    : defaultFormatMoment[typeBasicInput];
  const { valueFormattedForInput, valueFormatted } = useMemo(() => {
    return {
      valueFormattedForInput:
        typeBasicInput !== "time" ? checkDefaultDate(data) : data,
      valueFormatted:
        typeBasicInput !== "time"
          ? moment(new Date(data!))
              .locale(lang)
              .format(formatDateMoment)
              .includes("00:00")
            ? moment(new Date(data!))
                .locale(lang)
                .format(formatDateMoment.split("HH:mm").join(""))
            : moment(new Date(data!)).locale(lang).format(formatDateMoment)
          : data,
    };
  }, [data]);
  const { path, field, buttonPostpone, updateByItself } = props.type.editor;
  let pathstart = location.pathname;
  const { pathId, pathName } = parseDashboardDetailsPath(pathstart);
  const { reloadAlarms } = useSelector(
    (state: CombineReducersState) => state.notificationReducer
  );
  const updateRules: boolean = updateByItself
    ? updateByItself
    : path
    ? true
    : false;
  const toogleSaveChange = async (dataToSave, postpone7days = false) => {
    let updateValue = dataToSave;
    if (path) {
      try {
        await apiCall(HTTPMethod.POST, `/${pathName}/${pathId}/${path}`, {
          [field!]: updateValue,
          postpone7days: postpone7days
        });
        dispatch({
          type: MessagesReducerTypeActions.SET,
          payload: {
            type: MessageTypeEnum.SUCCESS,
            message: intl.formatMessage({ id: "SaveSuccess" }),
          },
        });
      } catch (error) {
        return console.error(error.message);
      }
    }
    if (props.name === "alertDate") {
      dispatch({
        type: NotificationReducerTypeActions.SET_NOTIFICATION,
        payload: { reloadAlarms: +reloadAlarms + 1 },
      });
    }
    props.setValue(props.name, updateValue, updateRules);
    setShowInput(false);
  };
  const postpone = () => {
    const cloneDate = moment(new Date(data!)).clone().add(7, "days").format();
    setData(cloneDate);
    toogleSaveChange(cloneDate, true);
  };
  useEffect(() => {
    let inputEl = (document.getElementById(`${props.name}-${uniqueId}`)as HTMLInputElement);
    if(inputEl){
      inputEl.addEventListener('focus', ()=> {
        inputEl.showPicker()
      })
    }
  }, [showInput]);
  return (
    <>
      {showInput ? (
        <>
          <FocusWrapper>
            <input
              id={`${props.name}-${uniqueId}`}
              style={{ position: "relative" }}
              type={typeBasicInput}
              value={valueFormattedForInput!}
              className="form-control form-control-solid"
              onChange={(e) => {
                if (e.target.value !== "") {
                  setData(e.target.value);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  toogleSaveChange(data);
                }
              }}
              onBlur={() => {
                toogleSaveChange(data);
              }}
            />
          </FocusWrapper>
        </>
      ) : (
        <>
          <div
            className={clsx("readonly-date", "cursor-pointer")}
            onClick={() => setShowInput(true)}
          >
            {props.data ? (
              valueFormatted
            ) : (
              <i className={clsx("fa-solid fa-plus", "icon-plus")}></i>
            )}
          </div>
          {props.data && (
            <i
              onClick={() => {
                toogleSaveChange(null);
              }}
              className="fa-solid fa-minus iconStandard"
            ></i>
          )}
          {buttonPostpone && data && (
            <CustomButton
              onClick={() => postpone()}
              className={"button-postpone"}
            >
              {intl.formatMessage({ id: "Date.postpone" })}
            </CustomButton>
          )}
        </>
      )}
    </>
  );
}
