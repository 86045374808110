import React, { useRef, useEffect } from "react";

export default function FocusWrapper({ children }) {
  const focusableRef = useRef<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | null>(null);

  useEffect(() => {
    if (focusableRef.current) {
      setTimeout(() => {
        focusableRef?.current?.focus?.();
      }, 100)


    }
  }, [focusableRef.current]);

  return React.cloneElement(React.Children.only(children), {
    ref: focusableRef,
  });
}
