import { useEffect, useState } from "react";
import { Direction, DraggableChildrenFn, Droppable, DroppableId, DroppableMode, TypeId } from "react-beautiful-dnd";

export interface DroppableProps {
  children?: React.ReactNode;
  droppableId: DroppableId;
  type?: TypeId | undefined;
  mode?: DroppableMode | undefined;
  isDropDisabled?: boolean | undefined;
  isCombineEnabled?: boolean | undefined;
  direction?: Direction | undefined;
  ignoreContainerClipping?: boolean | undefined;
  renderClone?: DraggableChildrenFn | undefined;
  getContainerForClone?: (() => HTMLElement) | undefined;
  style?: React.CSSProperties;
  className?: string;
}

// StrictModeDroppable.tsx
// Credits to https://github.com/GiovanniACamacho and https://github.com/Meligy for the TypeScript version
// Original post: https://github.com/atlassian/react-beautiful-dnd/issues/2399#issuecomment-1175638194
export const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);
  if (!enabled) {
    return null;
  }
  return (
    <Droppable {...props}>
      {(provided) => {
        return (
          <div ref={provided.innerRef} {...provided.droppableProps} style={props.style} className={props.className}>
            {children}
            {provided.placeholder}
          </div>
        );
      }}
    </Droppable>
  )
};

export default function DnDDroppable({ children, ...props }: DroppableProps) {
  return (
    <StrictModeDroppable {...props}>
      {children}
    </StrictModeDroppable>
  );
}