import { DragDropContext, OnDragEndResponder } from "react-beautiful-dnd";

interface DnDContainerProps {
  children: React.ReactNode;
  name?: string | React.ReactNode;
  onDragEnd: OnDragEndResponder;
  style?: React.CSSProperties;
  className?: string;
}

const DnDContainer = ({ children, name, className = 'd-flex card card-body m-10', ...props }: DnDContainerProps) => {
  return (
    <>
      <div className={className} style={props.style}>
        {/* Header */}
        {name && <div className="flex-row-auto w-250px w-xxl-350px" id="kt_profile_aside">
          {name}
        </div>}

        <DragDropContext onDragEnd={props.onDragEnd}>
          {children}
        </DragDropContext>
      </div>
    </>
  )
}

export default DnDContainer;