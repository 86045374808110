import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { apiCall } from "../../../utils/serviceCall";
import { HTTPMethod, parseDashboardDetailsPath } from "../../../utils/helper";
import { useLocation } from "react-router";
import { useIntl } from "react-intl";
export default function YesNoEditor({ props }) {
  const [data, setData] = useState<{ [key: string]: boolean }>({});
  const [loading, setLoading] = useState<boolean>(false);
  const { path, field } = props.type.editor;
  const location = useLocation();
  let pathstart = location.pathname;
  const { pathId, pathName } = parseDashboardDetailsPath(pathstart);
  const intl = useIntl();
  const updateByItself: string = "makeGet";
  enum radioValuesEnum {
    YES = "yes",
    NO = "no",
  }
  useEffect(() => {
    handleValue(props.data);
  }, [props.data]);
  const handleValue = (v: boolean) => {
    if (v === true) {
      setData({ [radioValuesEnum.YES]: true });
    }
    if (v === false) {
      setData({ [radioValuesEnum.NO]: true });
    }
  };
  const toogleSaveChange = async (nameRadioValue, value) => {
    let valueToChange;
    if (nameRadioValue === radioValuesEnum.YES) {
      if (value === "on") {
        valueToChange = true;
      } else {
        valueToChange = false;
      }
    }
    if (nameRadioValue === radioValuesEnum.NO) {
      if (value === "on") {
        valueToChange = false;
      } else {
        valueToChange = true;
      }
    }
    handleValue(valueToChange);
    if (path) {
      setLoading(true);
      try {
        await apiCall(HTTPMethod.POST, `/${pathName}/${pathId}/${path}`, {
          [field]: valueToChange,
        });
      } catch (error) {
        console.log(error);
        return;
      } finally {
        setLoading(false);
      }
    }
    props.setValue(props.name, valueToChange, updateByItself);
  };

  return (
    <div className="input-form-field">
      <div className="d-flex align-items-center gap-4">
        <input
          className=""
          type="radio"
          checked={data[radioValuesEnum.YES]}
          onChange={(e) =>
            toogleSaveChange(radioValuesEnum.YES, e.target.value)
          }
          id={`yesRadio-${props.name}`}
        />
        <label className="form-check-label" htmlFor={`yesRadio-${props.name}`}>
          {intl.formatMessage({ id: "yes" })}
        </label>
        <input
          className=""
          type="radio"
          checked={data[radioValuesEnum.NO]}
          onChange={(e) => toogleSaveChange(radioValuesEnum.NO, e.target.value)}
          id={`noRadio-${props.name}`}
        />
        <label className="form-check-label" htmlFor={`noRadio-${props.name}`}>
          {intl.formatMessage({ id: "no" })}
        </label>
      </div>
    </div>
  );
}
