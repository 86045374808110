//Da fare 
import { typeOfObject} from "../../../utils/helper";

export enum NotificationReducerTypeActions {
    SET_NOTIFICATION = 'notification/set-notification',
}

export interface NotificationReducerActions {
    type: NotificationReducerTypeActions,
    payload: NotificationState
}

export interface NotificationState {
    notification: typeOfObject[] | null,
    reloadAlarms: number,
    showAlarms: boolean,
    showIconAlarms:boolean
}

const initialNotificationState: NotificationState = {
    notification: null,
    showAlarms: false,
    showIconAlarms: false,
    reloadAlarms:0

};

export default function notificationReducer(state: NotificationState = initialNotificationState, action: NotificationReducerActions): NotificationState {
    switch (action.type) {

        case NotificationReducerTypeActions.SET_NOTIFICATION:
        return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}