import React from "react";

import CellInterface from "./CellInterface";
import { CombineReducersState } from "../../redux/store";
import { useSelector } from "react-redux";

const CellCompaniesUser = ({ value, type, element }: CellInterface) => {
  const stateReference = useSelector(
    (state: CombineReducersState) => state.referenceReducer
  );
  const currentState = stateReference["companies_users"];
  return (
    <>
      {currentState && (
        <div className={"CellCompaniesUser"}>
          {currentState.map((el) => {
            if (value.includes(el["@id"])) {
              return <>{el.user.fullName}, </>;
            }
          })}
        </div>
      )}
    </>
  );
};

export default CellCompaniesUser;
