import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { apiCall } from "../../utils/serviceCall";
import { useSelector } from "react-redux";
import { CombineReducersState } from "../../redux/store";
import { useDispatch } from "react-redux";
import { actionsTypeReference } from "../../redux/stores/reference/referenceReducer";
import { HTTPMethod, convertToNumber } from "../../utils/helper";
// TODO: type create type


interface CellIriReferenceInterface {
    value: string | string[],
    type: any
}
const takeNameFromIri = (value: string | string[]): string => {
    let valueToFormat;
    if (typeof value === 'string') {
        valueToFormat = value.split('/')[2]
    } else {
        if (value && value.length > 0) {
            valueToFormat = value[0].split('/')[2]
        }
    }
    return valueToFormat

}
const takeId = (value: string | string[]): string | string => {
    let idFilter;
    if (typeof value === 'string') {
        idFilter = value.split('/').pop()
    } else {

        if (value.length > 0) {
            idFilter = value.map(el => el.split('/').pop())
        }
    }
    return idFilter
}

const CellIriRereference = ({ value, type }: CellIriReferenceInterface) => {
    const name = takeNameFromIri(value)
    const [loading, setLoading] = useState(true)
    const [resultString, setResultString] = useState(null)
    const [resultArray, setResultArray] = useState<any>([])
    const [mainField] = useState('name')
    const stateReference = useSelector((state: CombineReducersState) => state.referenceReducer)
    const dispatch = useDispatch()

    const getCall = async (arrayOfidNotFound = null) => {
        let parseIntIds = arrayOfidNotFound ? convertToNumber(takeId(arrayOfidNotFound)) : convertToNumber(takeId(value))
        const params = { params: { id: parseIntIds } }


        await apiCall(HTTPMethod.GET, `/${name}`, params).then((response) => {
            const data = response.data['hydra:member']
            if (data) {
                if (type.type === 'string') { setResultString(data); } else {
                    if (type.type === 'array') {
                        setResultArray([...resultArray, ...data])
                    }
                }
                dispatch({ type: actionsTypeReference.update, payload: { name: name, value: data } })
            }
            setLoading(false)
        })
    }
    const initResultTypeString = () => {
        setLoading(true)
        const currentReference = stateReference[name]
        if (!currentReference) {
            dispatch({ type: actionsTypeReference.new, payload: { name: name, value: [] } })
        } else {
            let findPreSavedResponse = currentReference.find(element => element['@id'] === value)
            if (findPreSavedResponse) {
                setResultString(findPreSavedResponse)
                setLoading(false)
                return
            }
        }
        getCall()
    };
    function returnNotFoundIri(arrayOfiri, objState) {
        const iriExist = objState.map(obj => obj['@id']);
        const arrayNotFound = arrayOfiri.filter(id => !iriExist.includes(id));
        return arrayNotFound;
    }
    const initResultTypeArray = () => {
        setLoading(true)
        let notFound
        const currentReference = stateReference[name]
        if (!currentReference) {
            dispatch({ type: actionsTypeReference.new, payload: { name: name, value: [] } })
        } else {
            let findPreSavedResponse: any = Array.isArray(value) && currentReference.filter(obj => obj['@id'] && value.includes(obj['@id']))
            notFound = Array.isArray(value) && returnNotFoundIri(value, currentReference)
            // currentReference.filter(obj => !value.includes(obj['@id']))
            if (findPreSavedResponse && findPreSavedResponse.length > 0) {

                setResultArray(findPreSavedResponse)
                setLoading(false)
                return
            }
        }
        if (notFound && notFound.length > 0) {
            getCall(notFound)
        } else {
            getCall()
        }
    };
    useEffect(() => {
        if (name) {
            if (!Array.isArray(value)) {
                if (value.length > 0) { initResultTypeString() }
            } else {
                initResultTypeArray()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            {
                loading ? <>
                    <Skeleton></Skeleton></> : <>
                    {
                        resultString && type.type === 'string' && <>{resultString[mainField]}</>
                    }
                    {
                        type.type === 'array' && <>
                            {resultArray.length > 0 && resultArray.map((element, index) => <div key={index}>
                                {element[mainField] && element[mainField] + ','}
                            </div>)}
                        </>
                    }

                </>
            }

        </>
    );
};

export { CellIriRereference };
