import { useIntl } from "react-intl";
import React, { useEffect, useState } from "react";
import { Outlet, createSearchParams, useLocation, useNavigate, useParams } from "react-router-dom";

import { HeaderWrapper } from "../../models/header/HeaderWrapper";
import { GlobalSidebar } from "../../models/sidebar/GlobalSidebar";
import { apiCall } from "../../utils/serviceCall";

import { PageTitle } from "../../vendor/_metronic/layout/core";
import Table from "../../models/table/Table";

import { CombineReducersState, store } from "../../redux/store";
import { parseDataFromGlobalContext } from "../../redux/stores/rootContext";

import CustomButton from "../../models/buttons/CustomButton";
import {
  HTTPMethod,
  eliminateDuplicates,
  getAutoFormValue,
  getCustomInitilaFilter,
  getDefaultFilterValue,
  hydra,
  parseRequestQuery,
  takeIdFromIri,
} from "../../utils/helper";
import { useSelector } from "react-redux";
import { actionsTypeReference } from "../../redux/stores/reference/referenceReducer";
import { useDispatch } from "react-redux";
import { FilterHeader } from "../../models/FilterHeader/FilterHeader";
import ColumnFilterDraggable from "../../models/table/ColumunFilterDraggable";
import { getSettingsByEntity } from "../../models/DragNDrop/common";
import Layout from "../../json/layout.json";
import { actionsTypeFilter } from "../../redux/stores/filterReducer/filterReducer";
import { Modal } from "react-bootstrap";
import Form from "../../models/Form/Form";
import {
  MessageTypeEnum,
  MessagesReducerTypeActions,
} from "../../redux/stores/message/messageReducer";
import { statusDetailReducerTypeActions } from "../../redux/stores/statusDetail/statusDetailReducer";

const ListPage = () => {
  const globalState = store.getState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  let pathstart = location.pathname;
  const pathName = pathstart.split("/")[1];
  const [modalShow, setModalShow] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(50);
  const [list, setList] = useState<any>(null);
  const [selectedIds, setSelectedIds] = useState<any>([]);
  const [view, setView] = useState(null);
  const [fields, setFields] = useState<any>(null);
  const [settings, setSettings] = useState<any>(null);
  const [assessmentsModalShow, setAssessmentModalShow] =
    useState<boolean>(false);
    const toggleClickRow = (elementId, elementName) => {
      navigate(`/${elementName}/${elementId}`);
      initList();
    };
  const userData = useSelector(
    (state: CombineReducersState) => state.userReducer.userData
  )!;
  const group = userData.groupUser?.[0]?.group["@id"];
  const [savedRoute, setSavedRoute] = useState<string>();

  const stateReference = useSelector(
    (state: CombineReducersState) => state.referenceReducer
  );
  const stateFilter = useSelector(
    (state: CombineReducersState) => state.filterReducer
  );
  const allEntity = parseDataFromGlobalContext(globalState.rootContext.apiDocs);
  const entityPath = location.pathname.split("/")[1];
  const currentLayoutJson = Layout.entity[entityPath];
  const currentEntity = allEntity
    ? allEntity.find((entity) => entity.name === entityPath)
    : null;
    const [findEntity,setFindEntity] = useState(currentEntity)
  const defaultValue = getDefaultFilterValue(findEntity);
  const [form, setForm] = useState<{ [key: string]: any }>(
    getAutoFormValue(currentLayoutJson)
  );
  const intl = useIntl();
  const [totalItems, setTotalItems] = useState(0);
  const findReference = (obj: any): string[] => {
    return Object.keys(obj).filter(
      (key) =>
        (obj[key].hasOwnProperty("format") &&
          obj[key].format === "iri-reference") ||
        (obj[key].type === "array" && obj[key].items.format === "iri-reference")
    );
  };

  async function processListKey(key: string, list: any) {
    const listId = list
      .map((element) => {
        return element[key];
      })
      .filter((el) => el);
    const listIdMerged = [].concat.apply([], listId);

    if (listIdMerged && listIdMerged.length > 0) {
      let listIdWithoutDuplicate = eliminateDuplicates(listIdMerged);
      const pathReference = listIdWithoutDuplicate[0].split("/")[2];
      const currentReference = stateReference[pathReference];

      if (!currentReference) {
        dispatch({
          type: actionsTypeReference.new,
          payload: { name: pathReference, value: [] },
        });
      }
      if (currentReference) {
        listIdWithoutDuplicate = listIdWithoutDuplicate.filter(
          (iri) => !currentReference.find((element) => element["@id"] === iri)
        );
      }

      if (listIdWithoutDuplicate.length === 0) {
        setLoading(false);
        return;
      } else {
        const listIdWithoutDuplicateFiltrated = listIdWithoutDuplicate.map(
          (element) => takeIdFromIri(element)
        );
        // controllare in caso di aggiunta altri filtri in pathReference
        let optionalFilter = {};
        if (pathReference === "companies_users") {
          optionalFilter = {
            "groupUser.group": group,
            active: true,
            "exists[deletedAt]": false,
          };
        }
        const params = {
          params: {
            id: listIdWithoutDuplicateFiltrated,
            itemsPerPage: listIdWithoutDuplicateFiltrated.length,
            ...optionalFilter
          },
        };
        apiCall(HTTPMethod.GET, `/${pathReference}`, params)
          .then(async (response) => {
            const data = response.data[hydra.MEMBER];
            dispatch({
              type: actionsTypeReference.update,
              payload: { name: pathReference, value: data },
            });
            let currentFields = findEntity?.fields[key];
            if (currentFields) {
              let mainFieldReferenceName =
                currentFields.editor?.mainFieldReference?.field;
              if (mainFieldReferenceName) {
                await processListKey(mainFieldReferenceName, data);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }
  const filteredFields = (fieldsToFilter) => {
    return Object.keys(fieldsToFilter)
      .filter((key) => fieldsToFilter[key]?.list?.post)
      .reduce((obj, key) => {
        obj[key] = fieldsToFilter[key];
        return obj;
      }, {});
  };

  const initList = async () => {
    setLoading(true);
    setSelectedIds([]);
    const filterToUse =
      location.search === "" && stateFilter[pathName]
        ? stateFilter[pathName]
        : location.search;

    const queryParsed = parseRequestQuery(filterToUse);
    if (stateFilter[pathName] !== filterToUse && filterToUse !== "") {
      dispatch({
        type: actionsTypeFilter.set,
        payload: { name: pathName, value: filterToUse },
      });
    }
    const filter = {
      itemsPerPage: itemsPerPage,
    };
    setSavedRoute(location.pathname);
    if (queryParsed["page"]) {
      setCurrentPage(+queryParsed["page"]);
    }
    if (queryParsed["itemsPerPage"]) {
      setItemsPerPage(+queryParsed["itemsPerPage"]);
    }
    const customInitialFilter = getCustomInitilaFilter(pathName);

    Object.assign(filter, { ...queryParsed, ...customInitialFilter });
    let listReferenceName;
    if (allEntity) {
      if (findEntity) {
        listReferenceName = findReference(findEntity.fields);
        if (currentLayoutJson?.list?.exclude) {
          listReferenceName = listReferenceName.filter((el) => {
            return !currentLayoutJson?.list?.exclude.includes(el);
          });
        }
        setFields(findEntity.fields);

        await apiCall(HTTPMethod.GET, `/${params.entity}`, { params: filter }, true)
          .then((response) => {
            const data = response.data;
            const list = data["hydra:member"];
            const totalItems = data["hydra:totalItems"];
            listReferenceName.forEach(async (key) => {
              await processListKey(key, list);
            });
            setList(list);
            setView(data["hydra:view"]);
            setTotalItems(totalItems);
          })
          .catch((error) => {
            console.log(error, "<----- errore");
          })
          .finally(() => setLoading(false));
        let settings = await getSettingsByEntity(entityPath);
        if (currentLayoutJson?.list?.exclude) {
          settings.list = settings.list.filter((el) => {
            return !currentLayoutJson?.list?.exclude.includes(el.name);
          });
        }
        setSettings(settings);
      }
    }
    setLoading(false);
  };

  const select =(iri: string, checked: boolean) => {
    const id = takeIdFromIri(iri);
    let currentlySelected = [...selectedIds];
    currentlySelected = currentlySelected.filter(element => element != id);
    if(checked)currentlySelected.push(id);
    setSelectedIds(currentlySelected);
  }

  const allSelect =(checked: boolean) => {
    if(!checked) {
      setSelectedIds([]);
      return;
    } 
    const allSelectedIds = list.map(element => takeIdFromIri(element['@id']));
    console.log("LISt", allSelectedIds);
    setSelectedIds(allSelectedIds);
  }

  const multiSelectAction = async(action: string) => {
    if(!window.confirm(intl.formatMessage({id: "Save.confirm"}))) return;
    await apiCall(HTTPMethod.POST, `/${params.entity}/${action}`, {ids:selectedIds})
      .then(() => {
        dispatch({
          type: MessagesReducerTypeActions.SET,
          payload: {
            type: MessageTypeEnum.SUCCESS,
            message: intl.formatMessage({
              id: "Save.success",
            }),
          },
        });
        initList();
      })
      .catch((err) => {
        dispatch({
          type: MessagesReducerTypeActions.SET,
          payload: {
            type: MessageTypeEnum.DANGER,
            message: intl.formatMessage({
              id: "Save.error",
            }),
          },
        });
      });
  }

  const deleteElement = async (id: number | string) => {
    await apiCall(HTTPMethod.DELETE, `/${params.entity}/${id}`)
      .then(() => {
        dispatch({
          type: MessagesReducerTypeActions.SET,
          payload: {
            type: MessageTypeEnum.SUCCESS,
            message: intl.formatMessage({
              id: "Delete.success",
            }),
          },
        });
        initList();
      })
      .catch((err) => {
        dispatch({
          type: MessagesReducerTypeActions.SET,
          payload: {
            type: MessageTypeEnum.DANGER,
            message: intl.formatMessage({
              id: "Delete.error",
            }),
          },
        });
      });
  };
  const createElement = async (data) => {
    await apiCall(HTTPMethod.POST, `/${params.entity}`, data)
      .then(() => {
        setModalShow(false);
        dispatch({
          type: MessagesReducerTypeActions.SET,
          payload: {
            type: MessageTypeEnum.SUCCESS,
            message: intl.formatMessage({
              id: "Create.success",
            }),
          },
        });
        initList();
      })
      .catch(() => {
        dispatch({
          type: MessagesReducerTypeActions.SET,
          payload: {
            type: MessageTypeEnum.DANGER,
            message: intl.formatMessage({
              id: "Create.error",
            }),
          },
        });
      });
  };
  const { modifiedDetails } = useSelector(
    (state: CombineReducersState) => state.statusDetailReducer
  );
  useEffect(() => {
    if (
      !savedRoute ||
      !location.pathname.includes(savedRoute) ||
      location.search !== "" ||
      (!stateFilter[pathName] && stateFilter[pathName] !== "")
    ) {
      initList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    if (modifiedDetails) {
      dispatch({
        type: statusDetailReducerTypeActions.SET,
        payload: { modifiedDetails: false },
      });
      initList();
    }
  }, [location.pathname]);
  useEffect(() => {
    if (
      stateFilter[pathName] &&
      location.pathname.split("/").length === 2 &&
      location.search === ""
    ) {
      navigate({
        search: stateFilter[pathName],
      });
    }
    if (!savedRoute || !location.pathname.includes(savedRoute)) {
      dispatch({
        type: actionsTypeFilter.clear,
      });
    }
  }, [location]);

  const navigateToItemsPerPage = (itemsPerPage: number) => {
    let queryParsed: any = parseRequestQuery(location.search);
    queryParsed.page = 1;
    queryParsed.itemsPerPage = itemsPerPage;
    navigate(`?${createSearchParams(queryParsed)}`);
  }

  const toggleNewAssessment = () => {
    setAssessmentModalShow(!assessmentsModalShow);
  };
  return (
    <>
      <HeaderWrapper    alarmData={{
          toggleClickRow: toggleClickRow
        }}/>

      <GlobalSidebar />

      {
        <div className="app-wrapper list flex-column flex-row-fluid">
          <div style={{ width: "auto" }} />
          {globalState.rootContext.apiDocs && <FilterHeader></FilterHeader>}
          <div className="card card-body m-5">
            <div className="d-flex justify-content-between">
              <div>
                {currentLayoutJson?.multiSelectAction && currentLayoutJson?.multiSelectAction.map((action, index) => (
                  <CustomButton disable={selectedIds.length == 0} onClick={() =>multiSelectAction(action.path)}>
                    {intl.formatMessage({ id: `${findEntity?.tag}.${action.label}` })}
                  </CustomButton>
                ))}
                {currentLayoutJson?.list?.post && (
                  <CustomButton onClick={() => setModalShow(true)}>
                    {intl.formatMessage({ id: `${findEntity?.tag}.CREATE` })}
                  </CustomButton>
                )}
              </div>

              {entityPath === "assessments" && (
                <div>
                  <CustomButton onClick={toggleNewAssessment}>
                    {intl.formatMessage({ id: `${findEntity?.tag}.CREATE` })}
                  </CustomButton>
                </div>
              )}
              <div className="fs-5 text-gray-400 fw-bolder m-auto">
              </div>
              <div className="fs-5 text-gray-400 fw-bolder m-auto">
                <p className="m-0">{totalItems}</p>
              </div>

              <div className="fs-5 text-gray-400 fw-bolder m-auto d-flex flex-row m-auto">
                <label className="m-auto p-2">
                  <span>Per pagina</span>
                </label>
                <select className="form-select" value={itemsPerPage} onChange={(event) => navigateToItemsPerPage(Number(event.target.value))}>
                  <option value={50}>50</option>
                  <option value={75}>75</option>
                  <option value={100}>100</option>
                  <option value={200}>200</option>
                  <option value={500}>500</option>
                </select>
              </div>
              <div className="fs-5 text-gray-400 fw-bolder">
                {!currentLayoutJson?.list?.hideColumnsFilter && (
                  <ColumnFilterDraggable
                    columns={fields}
                    columnsSelected={settings?.list}
                    loading={loading}
                    entity={findEntity}
                    path={entityPath}
                  ></ColumnFilterDraggable>
                )}
              </div>
            </div>
            <Table
              fieldToDelete={pathName.includes("settings") ? "name" : null}
              actions={currentLayoutJson?.list?.actions}
              multiSelect={currentLayoutJson?.multiSelect}
              key={JSON.stringify(list)}
              columnsSelected={settings?.list}
              fields={fields}
              loading={loading}
              list={list}
              view={view}
              currentPage={currentPage}
              entity={findEntity}
              selectedIds={selectedIds}
              deleteElement={(id) => deleteElement(id)}
              select={(iri, checked) => select(iri, checked)}
              allSelect={(checked) => allSelect(checked)}
            />
          </div>
        </div>
      }
      <Modal size={"lg"} show={modalShow} onHide={() => setModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {intl.formatMessage({
              id: "add" + pathName,
            })}
          </Modal.Title>{" "}
        </Modal.Header>
        <Modal.Body>
          <Form
            data={form}
            translate={
              params.entity!.charAt(0).toUpperCase() + params.entity!.slice(1)
            }
            nameEntity={params.entity!}
            fields={fields && filteredFields(fields)}
            onSave={(value) => {
              createElement(value);
            }}
          ></Form>
        </Modal.Body>
      </Modal>
      <Outlet />
    </>
  );
};

const ListWrapper = () => {
  const intl = useIntl();

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.DASHBOARD" })}
      </PageTitle>
      <ListPage />
    </>
  );
};

export { ListWrapper };
