export interface MessagesReducerActions {
    type: MessagesReducerTypeActions,
    payload: MessageState
}

export enum MessagesReducerTypeActions {
    SET = 'message/set',
    CLEAR = 'message/clear',
}
export enum MessageTypeEnum {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    SUCCESS = 'success',
    DANGER = 'danger',
    WARNING = 'warning',
    INFO = 'info',
    LIGHT = 'light',
    DARK = 'dark',
}

export interface MessageState {
    type: null,
    message: string
}


const initialMessageState: MessageState[] | null = null;

export default function messageReducer(state: MessageState[] | null = initialMessageState, action: MessagesReducerActions): MessageState[] | null {
    switch (action.type) {
        case MessagesReducerTypeActions.SET:
            return [...[action.payload]]

        case MessagesReducerTypeActions.CLEAR:
            return initialMessageState

        default:
            return state
    }
}