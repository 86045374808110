import clsx from "clsx";
import { FC, useEffect, useState } from "react";
import { UserControllerApi } from "../../apis/userController";

interface UserImageInterface {
  credential: {
    id?: number;
    name: string;
    surname: string;
  };
  className?: string;
}
export const UserImage: FC<UserImageInterface> = ({
  credential,
  className = "",
}) => {
  const [imgSrc, setImgSrc] = useState<string | null>("");

  const downloadAvatar = (id: number) => {
    UserControllerApi.avatar(id).then((response) => {
      if (response == null) {
        setImgSrc(null);
        return;
      }
      const result = response.data;

      let blobUrl = window.URL.createObjectURL(result);
      setImgSrc(blobUrl);
    });
  };

  useEffect(() => {
    if (credential.id) downloadAvatar(credential.id);
  }, []);

  return (
    <div className={clsx("symbol symbol-45px symbol-circle", className)}>
      {imgSrc ? (
        <img src={imgSrc} />
      ) : (
        <span className="symbol-label bg-light-primary text-primary fs-6 fw-bolder cursor-pointer">
          {credential.name.charAt(0)}
          {credential.surname.charAt(0)}
        </span>
      )}
    </div>
  );
};
