export enum CompanyState {
    WAITING_PROCESSING = 'wait_process', // da processare
    NEW = 'new', // nuova
    ACQUIRED = 'acquired', // acquisita
    ARRIVING = 'arriving', // in arrivo
    EVALUATION = 'evaluation', // inventario/perizia ricevuta
    OFFERDO = 'offerdo', // fare offerta
    OFFERMADE = 'offermade', // offerta fatta
    PROCESSING = 'processing', // in lavorazione
    PUBLISHED = 'published', // pubblicata
    TODOVISIT = 'todovisit', // visita da fare
    PLANNEDVISIT = 'plannedvisit', // visita pianificata
    DONEVISIT = 'donevisit', // visita effettuata
    CLOSED = 'closed', // chiusa
    WAIT = 'wait', // aspettare
    WHATTODO = 'whattodo', // cosa fare
    PAST = 'past', // in lavorazione con data alert <= di today
    FUTURE = 'future', // in lavorazione con data alert > di today
}

export enum FilterCompanyAlert {
    FILTER_BEFORE = 'companiesUser.alert[before]',
    FILTER_STRICTLY_BEFORE = 'companiesUser.alert[strictly_before]',
    FILTER_AFTER = 'companiesUser.alert[after]',
    FILTER_STRICTLY_AFTER = 'companiesUser.alert[strictly_after]',
}
