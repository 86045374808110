import axios from "axios";
import { StatusCode } from "status-code-enum";
import { actionsTypeUser } from "../redux/stores/user/userReducer";
import { refreshToken } from "./authRequest";
import { store } from "../redux/store";
import { HTTPMethod } from "./helper";

const axiosCache = {};
function axiosApi(cache = false) {
  const navigate = (path: string) => (window.location.href = path);
  const dispatch = store.dispatch;
  const state = store.getState();
  const { token, refresh_token } = state.userReducer;

  function addAuthToken(data, headers) {
    if (token) {
      Object.assign(headers, {
        Authorization: `Bearer ${token}`,
      });
    } else {
      Object.assign(headers, {
        Authorization: "",
      });
    }
    return data;
  }

  async function checkAuthToken(error) {
    let originalRequest = error.config;

    if (
      error &&
      error.response &&
      error.response.status === StatusCode.ClientErrorUnauthorized &&
      error.response.config.url === "/api/token/refresh" &&
      error.response.config.url !== "/api/login_check"
    ) {
      dispatch({ type: actionsTypeUser.clean });
      navigate("/auth");
      return Promise.reject(error);
    }
    // make refresh token
    if (
      error &&
      error.response &&
      error.response.status === StatusCode.ClientErrorUnauthorized &&
      error.response.config.url !== "/api/token/refresh" &&
      error.response.config.url !== "/api/login_check"
    ) {
      try {
        let { data } = await refreshToken(refresh_token as string);

        dispatch({
          type: actionsTypeUser.set,
          payload: { refresh_token: data.refresh_token, token: data.token },
        });

        originalRequest.headers.Authorization = `Bearer ${data.token}`;
        window.location.reload()
        return Promise.reject(error);
      } catch {
        dispatch({ type: actionsTypeUser.clean });
        navigate("/auth");
        return Promise.reject(error);
      }
    }
    if(error && error.response && (error.response.status < 200 || error.response.status >= 400)){
      return Promise.reject(error);
    }
  }
  const API_URL = process.env.REACT_APP_API_URL;

  const CONFIG = {
    baseURL: API_URL,
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
    transformRequest: [addAuthToken],
  };
  const axiosIstance = axios.create(CONFIG);

  axiosIstance.interceptors.request.use(
    (config) => {
      const cacheKey = buildCacheKey(config);
      console.debug("cache key", cacheKey);
      if (cache && axiosCache[cacheKey]) {
        console.debug("found cache", cacheKey);
        return Promise.reject();
      }
      axiosCache[cacheKey] = true;
      let cloneConfig = { ...config };
      // if (config.headers.Accept === "" || !config.headers.Accept) {
      //   accept = "application/ld+json";
      // } else {
      //   accept = config.headers.Accept;
      // }

      if (
        cloneConfig.method === HTTPMethod.PUT ||
        cloneConfig.method === HTTPMethod.POST
      ) {
        Object.assign(cloneConfig.headers, {
          "Content-Type": "application/json",
          Accept: "application/json, text/plain, */*", // Modifica Accept solo per put e post
        });
      }
      if (cloneConfig.method === HTTPMethod.PATCH) {
        Object.assign(cloneConfig.headers, {
          "Content-Type": "application/merge-patch+json",
          Accept: "application/json, text/plain",
        });
      }
      return cloneConfig;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axiosIstance.interceptors.response.use(async (response) => {
    const cacheKey = buildCacheKey(response.config);
    // Store the response in the cache
    axiosCache[cacheKey] = false;
    console.debug("clean cache");
    return response;
  }, checkAuthToken);

  return axiosIstance;

}

function buildCacheKey(config: any) {
  return JSON.stringify({ url: config.url, method: config.method, params: config.params });
}
export default axiosApi;
