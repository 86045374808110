/* CellEditor */
import CellAsLabel from "./CellAsLabel";
import CellAsString from "./CellAsString";
import CellAsIri from "./CellAsIri";
import CellAsVote from "./CellAsVote";
import CellAsTitle from "./CellAsTitle";
import CellAsFormat from "./CellAsFormat";
import {CellIriRereference} from "./CellIriRereference";
import CellStatusBoolean from "./CellStatusBoolean";
import CellImportsCompany from "./CellImportsCompany";
import CellCompaniesUser from "./CellCompaniesUser";

export const DynamicCellComponents = {
    CellStatusBoolean,
    CellAsLabel,
    CellAsString,
    CellAsVote,
    CellCompaniesUser,
    CellAsTitle,
    'date-time' : CellAsFormat,
    'date' : CellAsFormat,
    'time' : CellAsFormat,
    '__iri-reference' : CellAsIri,
    CellIriRereference,
    CellImportsCompany


};