import Skeleton from "react-loading-skeleton";
import { useEffect, useMemo, useState } from "react";

import AsyncSelect from "react-select/async";
import { PropsValue } from "react-select";
import { DefaultOption, provinceOptions } from "../../json/data";
import { customStyles } from "./styleReactSelect";

interface autocompleteProps {
  name: string;
  value: string | string[];
  type?: any;
  typeFilter?: boolean;
  setValue: (name: string, value: any) => void;
  setShowInput?: (value: boolean) => void;
  customFilter?: string;
  options: DefaultOption[];
}

const promiseOptions = (inputValue: string, options: DefaultOption[]) =>
  new Promise<DefaultOption[]>((resolve) => {
    setTimeout(() => {
      const filteredOptions = options.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      resolve(filteredOptions);
    }, 1200);
  });

export default function SimpleAsyncSelectFilter({
  name,
  value,
  type,
  setValue,
  setShowInput,
  customFilter,
  options,
}: autocompleteProps) {
  const defaultValue: PropsValue<any> | null = value ? options.find((el) => el.value === value ) : options[0];

  const handleSelectChange = (e) => {
    if (e !== null) {
      setValue(name, e.value);
    } else {
      setValue(name, defaultValue);
    }
  };

  return (
    <>
      <AsyncSelect
        cacheOptions
        styles={customStyles}
        defaultOptions
        defaultValue={defaultValue}
        onChange={handleSelectChange}
        loadOptions={(inputValue) => promiseOptions(inputValue, options)}
      />
    </>
  );
}
