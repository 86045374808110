import React from "react";

import CellInterface from "./CellInterface";
import { useIntl } from "react-intl";

const CellAsLabel = ({value, type, element}: CellInterface) => {
    const intl = useIntl()
    let variant = 'primary';
    if(type?.list?.variant.hasOwnProperty(value)){
        variant = type.list.variant[value]
    }
    const translate = type.list.translate
    return (<>
        <div className={'cellAsLabel'}>
            <span className={`badge badge-light-${variant}`}>{translate ? intl.formatMessage({id:`${translate}.${value}`}): value }</span>
        </div>
    </>);
}

export default CellAsLabel;