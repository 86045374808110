import React, { useState } from "react";
import FocusWrapper from "../../FocusWrapper/FocusWrapper";

interface InputAsButtonEditorProps {
    props: {
        name: string;
        data: string;
        setValue: (name: string, value: string) => void;
        setShowInput: (show: boolean) => void;
    };
}

export default function InputAsButtonEditor({ props }: InputAsButtonEditorProps) {
    const [data, setData] = useState(props.data);

    return (
        <FocusWrapper>
            <input
                style={{ position: "relative" }}
                type="text"
                value={data}
                className="form-control form-control-solid"
                onChange={(e) => {
                    setData(e.target.value);
                }}
                onBlur={() => {
                    props.setValue(props.name, data);
                    props.setShowInput(false);
                }}
            /></FocusWrapper>
    );
}
