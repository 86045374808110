import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
interface RadioInputProps {
  name: string;
  value: boolean;
  setValue: (e) => void;
  note: string | undefined;
}
enum radioValuesEnum {
  YES = "yes",
  NO = "no",
}
export const RadioInput: FC<RadioInputProps> = ({
  name,
  note,
  value,
  setValue,
}) => {
  const intl = useIntl();
  const [radioValues, setRadioValues] = useState<{ [key: string]: boolean }>(
    {}
  );
  useEffect(() => {
    handleValue(value);
  }, [value]);
  const handleValue = (v: boolean) => {
    if (v === true) {
      setRadioValues({ [radioValuesEnum.YES]: true });
    }
    if (v === false) {
      setRadioValues({ [radioValuesEnum.NO]: true });
    }
  };
  const handleChange = (nameRadioValue: string, value: string) => {
    let valueToChange;
    if (nameRadioValue === radioValuesEnum.YES) {
      if (value === "on") {
        valueToChange = true;
      } else {
        valueToChange = false;
      }
    }
    if (nameRadioValue === radioValuesEnum.NO) {
      if (value === "on") {
        valueToChange = false;
      } else {
        valueToChange = true;
      }
    }
    setValue({ name, value: valueToChange });
    handleValue(valueToChange);
  };
  return (
    <>
      <div className="d-flex align-items-center gap-4">
        <input
          className=""
          type="radio"
          checked={radioValues[radioValuesEnum.YES]}
          onChange={(e) => handleChange(radioValuesEnum.YES, e.target.value)}
          id={`yesRadio-${name}`}
        />
        <label className="form-check-label" htmlFor={`yesRadio-${name}`}>
          {intl.formatMessage({ id: "yes" })}
        </label>
        <input
          className=""
          type="radio"
          checked={radioValues[radioValuesEnum.NO]}
          onChange={(e) => handleChange(radioValuesEnum.NO, e.target.value)}
          id={`noRadio-${name}`}
        />
        <label className="form-check-label" htmlFor={`noRadio-${name}`}>
          {intl.formatMessage({ id: "no" })}
        </label>
        <input
          type="text"
          id={`inputNote-${name}`}
          value={note}
          placeholder={intl.formatMessage({ id: `note` })}
          onChange={(e) =>
            setValue({ name: `${name}Note`, value: e.target.value })
          }
        ></input>
      </div>
    </>
  );
};
