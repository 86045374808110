import { HTTPMethod } from "../utils/helper";
import { apiCall } from "../utils/serviceCall";

/**
 * API Controller for Company entity
 */
export class CompanyControllerApi {
  /**
   * Retrieves a company entity by id
   * @param id - Id of company to retrieve
   * @param params - Optional parameters
   * @returns The found company
   */
  static async getCompanyById(id: string, params?: any) {
    return apiCall(HTTPMethod.GET, `/companies/${id}`, {params: params});
  }
}
