import React, { createContext, useState } from "react";
export const DashboardContext = createContext<any>({});
export const DashboardProvider = ({children}) => {
  const [enableFilterState, setEnableFilterState] = useState<boolean>(true);
  return (
    <DashboardContext.Provider value={[enableFilterState, setEnableFilterState]}>
      {children}
    </DashboardContext.Provider>
  );
};
