import React from "react";
import {Badge} from "react-bootstrap";
import CellInterface from "./CellInterface";

const CellAsVote = ({value, type, element}: CellInterface) => {

    return (<>
        <div className={'cellAsVote'}>
            <h3>{value}</h3>
        </div>
    </>);
}

export default CellAsVote;