import { ViewTabulatingLayout } from "../../tabs/TabsPanel";
export default function TabsView({ ...props }: any) {
  return (
    <ViewTabulatingLayout
      insideTabs={true}
      tabs={props.order.pages}
      propsNestable={props.propsNestable}
    />
  );
}
