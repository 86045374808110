import React from "react";
import SimpleAsyncSelectFilter from "../Input/SimpleAsyncSelectFilter";
import {
  DefaultOption,
  regionOptions,
  provinceOptions,
  relevanceOptions,
  importsCompaniesProcessedOptions,
  updatedTypologyOptions,
  companyStateOptions,
  followOptions,
} from "../../json/data";
import moment from "moment";
import { useIntl } from "react-intl";
import AutocompleteFilter from "../Input/AutoCompleteFilter";
export function FilterInput({ filter, idx, form, handleValue }) {
  const { schema } = filter;
  const intl = useIntl();
  const createIri = (value: string | string[], path: string) => {
    if (Array.isArray(value)) {
      return value.map((el) => `/api/${path}/${el}`);
    } else {
      return `/api/${path}/${value}`;
    }
  };

  if (!schema) {
    console.error(`Schema non definito per il filtro ${filter.name}`);
    return null;
  }

  const {
    filterType,
    path,
    mainField,
    type,
    format,
    items,
    label,
    icon,
    colorIcon,
    options,
    customFilter,
    filterToUse,
    startYear,
    isClearable
  } = schema;

  const defaultProps = {
    key: idx,
    onChange: (e) => handleValue({ [filter.name]: e.target.value }),
    value: form[filter.name] ? form[filter.name] : "",
  };

  const defaultPropsSelect = {
    key: idx,
    setValue: (name, value) => handleValue({ [name]: value }),
    value: form[filter.name] ? form[filter.name] : null,
  };
  
  const defaultPropsYear = {
    key: idx,
    setValue: (name, value) => {
      value !== "" &&
        handleValue({
          ["createdAt[after]"]: moment(`${value}-01-01`).format("YYYY-MM-DD"),
          ["createdAt[before]"]: moment(`${value}-12-31`).format("YYYY-MM-DD"),
        });
    },
    value: form[filter.name],
  };

  const defaultCheckboxProps = {
    key: idx,
    onChange: (e) => {
      handleValue({ [filter.name]: e.target.checked });
    },
    checked: form[filter.name] ? (form[filter.name] === "false" ? false : true) : false
  };
  const createListYear = (
    startYear: number
  ): { value: string; label: string }[] => {
    const currentYear = moment().year();
    let list: { value: string; label: string }[] = [
      { value: "", label: intl.formatMessage({ id: "addYear" }) },
    ];
    for (let i: number = startYear; i <= currentYear; i++) {
      let stringifyNumber = i.toString();
      list.push({
        value: stringifyNumber,
        label: stringifyNumber,
      });
    }
    return list;
  };

  let dataOptions: DefaultOption[] =
    filter.name === "location.region1" || filter.name === "region"
      ? regionOptions
      : filter.name === "location.region2"
      ? provinceOptions
      : filter.name === "companyGroups.relevance[between]"
      ? relevanceOptions
      : filter.name === "company.companyGroups.isProcessed"
      ? importsCompaniesProcessedOptions
      : filter.name === "procedures.typology"
      ? updatedTypologyOptions
      : filter.name === "companyGroups.state"
      ? companyStateOptions
      : filter.name === "following"
      ? followOptions
      : [];

  switch (filterType) {
    case "textFilter":
      return (
        <div className="input-custom mb-3">
          <input placeholder="Cerca..." type="text" {...defaultProps} />
          {icon && <i className={icon}></i>}
        </div>
      );
    case "selectFilterWithOptions":
      return (
        <select {...defaultProps} className="form-select mb-3">
          {options &&
            Object.keys(options).map((key, id) => (
              <option key={`${key}-${id}`} value={key}>
                {options[key]}
              </option>
            ))}
        </select>
      );
    case "checkboxFilter":
      return (
        <div className="d-flex gap-2 align-items-center mb-5">
          <input
            className="form-check-input p-1"
            type="checkbox"
            {...defaultCheckboxProps}
          />
          {icon && <i className={`w-15px text-center ${icon}`} style={{ color: colorIcon }}></i>}
          {label && <p className="m-0">{intl.formatMessage({ id: label })}</p>}
        </div>
      );
    case "referenceFilter":
      return path ? (
        <div className="mb-3">
          <AutocompleteFilter
            {...defaultPropsSelect}
            type={{ type, format, items, editor: { mainField, path } }}
            name={filter.name}
            customFilter={customFilter}
            filterToUse={filterToUse}
            isClearable={isClearable}
          />
        </div>
      ) : (
        <></>
      );
    case "simpleSelectFilter":
      return (
        <div className="mb-3">
          <SimpleAsyncSelectFilter
            {...defaultPropsSelect}
            type={{ type, format, items, editor: { mainField, path } }}
            name={filter.name}
            options={dataOptions}
          />
        </div>
      );
    case "filterYear":
      return (
        <div className="mb-3">
          <SimpleAsyncSelectFilter
            {...defaultPropsYear}
            type={{ type, format, items, editor: { mainField, path } }}
            name={filter.name}
            options={createListYear(startYear)}
          />
        </div>
      );
    default:
      return <></>;
  }
}
