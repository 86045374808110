import React, { useRef, useEffect, useState } from 'react';
import Tagify  from '@yaireo/tagify'
import { useIntl } from 'react-intl';
interface TagifyWraperProps {
    value: any,
    name: string,
    onChange: (e) => void
}
function TagifyWraper({ value,onChange }) {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [elements, setElements] = useState<string[]>(value || [])
    const intl = useIntl()
    useEffect(() => {
        if (inputRef.current) {
            const tagify = new Tagify(inputRef.current, {
                callbacks: {
                    'add': (event) => {
                        if(!elements.includes(event.detail.data.value)){
                        const newElements = [...elements, event.detail.data.value];
                        setElements(newElements);
                        onChange(newElements)
                    }
                    },
                    'remove': (event) => {
                        const removedTag = event.detail.data.value;
                        const newElements = elements.filter((tag) => tag !== removedTag);
                        setElements(newElements);
                        onChange(newElements)
                    },
                },
            });
            tagify.addTags(value)
            return () => {
                // tagify.destroy();
            };
        }
    }, []);

    return (
        <div>
            <input className='w-100' ref={inputRef} placeholder={intl.formatMessage({id:"Action.addTag"})}/>

        </div>
    );
}

export default TagifyWraper;
