import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useLayout } from "../../vendor/_metronic/layout/core";
import {
  checkIsActive,
  KTIcon,
  WithChildren,
} from "../../vendor/_metronic/helpers";

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
  insideRoutes?: string[];
};

const SidebarMenuItemWithSub: React.FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
  insideRoutes,
}) => {
  const { pathname } = useLocation();
  const [isActive,setIsActive] = useState(insideRoutes && insideRoutes.includes(pathname));
  const { config } = useLayout();
  const { app } = config;
  const [forceReload, setForceReload] = useState<number>(0);
  useEffect(() => {
    setTimeout(() => {
      setForceReload(forceReload + 1);
    }, 2000);
  }, []);
  return (
    <div
      key={forceReload}
      className={clsx("menu-item", { "here show": isActive }, "menu-accordion")}
     onClick={()=> setIsActive(!isActive)}
    >
      <span className="menu-link">
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}
        {icon && <i className={clsx("fs-3 m-3 w-15px text-center ", icon)}></i>}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === "font" && (
          <i className={clsx("bi fs-3", fontIcon)}></i>
        )}
        <span className="menu-title">{title}</span>
        <span className="menu-arrow"></span>
      </span>
      <div
        className={clsx("menu-sub menu-sub-accordion", {
          "menu-active-bg": isActive,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export { SidebarMenuItemWithSub };
