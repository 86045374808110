/* React utils */

import React, { lazy } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

/* Metronic */
import { ErrorsLayout } from "./pages/errors/ErrorsLayout";
import { Error404 } from "./pages/errors/components/Error404";
import { Error500 } from "./pages/errors/components/Error500";

/* 
Each page is a React component that is rendered by the router.
Every page is a child of the router, and the router is a child of the indexing structure (index.tsx).
All pages should map to their respective directory in `app/src/pages` as a route (for better comprehension).
*/

// import Details from "./pages/table/Details-old";
import { ListWrapper } from "./pages/crud/list";
import Details from "./pages/crud/details";
import { useSelector } from "react-redux";
import { CombineReducersState } from "./redux/store.ts";
import { AuthPage } from "./pages/auth/AuthPage.tsx";

const Dashboard = lazy(() => import('./pages/dashboard/Dashboard'))
const SettingsLayout = lazy(() => import('./pages/settings/Layout.tsx'))
const Profile = lazy(() => import('./pages/settings/Profile'))
//----------------//

/* Export the routing schema */
export default function Routing() {
  const { userData} = useSelector((state: CombineReducersState) => state.userReducer)
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Routes>
        {/* Pages */}
        {userData ? <>
        
          <Route path="/" element={<Navigate to='/dashboard'></Navigate>} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/dashboard" element={<Dashboard />} >
              {/*<Route path=':entity/' element={<ListWrapper />}>*/}
                  <Route path=':entity/:id' element={<Details />} />
              {/*</Route>*/}
          </Route>
          <Route path="/settings/layout" element={<SettingsLayout />} />

          {/* Dynamic Routing */}
          <Route path=':entity/' element={<ListWrapper />}>
            <Route path=':id' element={<Details />} />
          </Route>
          <Route path=':entity/edit' element={<SettingsLayout />} />
        </> : <>
          <Route path='auth/*' element={<AuthPage />} />
          <Route path='*' element={<Navigate to='/auth' />} />
        </>}
        {/* Errors */}
        <Route element={<ErrorsLayout />}>
          <Route path='*' element={<Error404 />} />
          <Route path='500' element={<Error500 />} />
        </Route>
      </Routes>
    </Router>
  );
}
