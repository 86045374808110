import React from "react";
import CellInterface from "./CellInterface";

const CellAsString = ({value, type, element, key}: CellInterface) => {

    return (<>
        <div className={'cellAsString'}>
            <p>{value}</p>
        </div>
    </>);
}

export default CellAsString;