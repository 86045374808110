import { Draggable, DraggableId } from 'react-beautiful-dnd';

export interface DraggableProps {
  children?: React.ReactNode;
  draggableId: DraggableId;
  index: number;
  isDragDisabled?: boolean | undefined;
  disableInteractiveElementBlocking?: boolean | undefined;
  shouldRespectForcePress?: boolean | undefined;
  grip?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

export default function DnDDraggable({ children, ...props }: DraggableProps) {
  return (
    <Draggable {...props}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps} >
          {props.grip ? (
            <div className={props.className} style={props.style}>
              <div {...provided.dragHandleProps}>
                {props.grip}
              </div>
              {children}
            </div>
          ) : (
              <div className={props.className} {...provided.dragHandleProps} style={props.style}>
                {children}
              </div>
          )}
        </div>
      )}
    </Draggable>
  );
}