import clsx from "clsx";
import { EIconType } from "../../utils/helper";
import { FC } from "react";
type TIconType = `${EIconType}`;
interface IconProps {
  type?: TIconType;
  nameIcon: string;
  className?: string;
  title?: string;
  children?: any;
  style?: any;
}

export const Icon: FC<IconProps> = ({
  children,
  type = children ? EIconType.FONTAWESOME : EIconType.MATERIAL,
  nameIcon,
  className,
  title,
  style
}) => {
  switch (type) {
    case EIconType.FONTAWESOME:
      return <i style={style}  className={clsx(nameIcon,className)} title={title}></i>;
    case EIconType.MATERIAL:
      return <span style={style} className={clsx("material-icons-two-tone", className)} title={title}>{nameIcon}</span>;
  }
};
