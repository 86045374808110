import React, { FC, useEffect, useState } from "react";
import * as yup from "yup";
import CustomButton from "../buttons/CustomButton";
import {
  createYupSchema,
  filterObject,
  isObjectEquals,
} from "../../utils/helper";
import { useIntl } from "react-intl";

import { Field } from "../FormModal/Field";
import clsx from "clsx";
interface FormProps {
  fields: { [key: string]: any };
  key?: any;
  data?: { [key: string]: any };
  nameEntity: string;
  onSave?: (e: { [key: string]: any }) => void;
  onStatusChange?: (e: boolean) => void;
  translate?: string;
}

interface fieldSchema {
  type: null | string;
  attributes: string[];
}

const Form: FC<FormProps> = ({
  fields,
  data = {},
  key,
  nameEntity,
  onSave,
  onStatusChange,
  translate
}) => {
  const intl = useIntl();
  const createYupSpecific = (fields: { [key: string]: fieldSchema }): any => {
    let result = Object.keys(fields)
      .map((fieldkey) => {
        let field: any = fields[fieldkey];
        let arrayOfControlls: fieldSchema = { type: null, attributes: [] };
        if (field.type) {
          arrayOfControlls.type = field.type;
        }

        if (field.required) {
          arrayOfControlls.attributes.push("required");
        }
        return { [fieldkey]: arrayOfControlls };
      })
      .reduce((acc, obj) => ({ ...acc, ...obj }), {});
    return result;
  };
  // filtra i fields in base a form.hidden
  const filteredFields = filterObject(
    fields,
    (value) =>
      value.form?.hidden !== true || typeof value.form?.auto !== "undefined"
  );
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [disableStatusButton, setDisableStateButton] = useState<boolean>(true);
  const schema = yup
    .object()
    .shape(createYupSchema(createYupSpecific(filteredFields)));
  const checkValidation = () => {
    schema
      .validate(form, { abortEarly: false })
      .then(() => {
        setDisableStateButton(false);
        setFormErrors({});
      })
      .catch((validationErrors: yup.ValidationError) => {
        setDisableStateButton(true);
        parsedErrorForm(validationErrors);
      });
  };
  const [order, setOrder] = useState<string[][]>();
  const parsedErrorForm = (errors: yup.ValidationError) => {
    const parsedErrors = errors.inner.reduce((errorList, error) => {
      if (error.path) {
        errorList[error.path] = error.message;
      }
      return errorList;
    }, {});
    setFormErrors(parsedErrors);
  };
  const [form, setForm] = useState<any>(data);
  useEffect(() => {
    if (!isObjectEquals(data, form)) {
      checkValidation();
    }
  }, [form]);
  useEffect(() => {
    setForm(data);
  }, [data]);
  const handleUpdate = (nameInput: string, value: any) => {
    setForm({ ...form, ...{ [nameInput]: value } });
  };
  function createOrder(obj) {
    const result: any = [];
    const valueMap = {};
    const noRow: any = [];
    for (const key in obj) {
      const value = parseFloat(obj[key].form?.row); // Converto il valore in un numero
      if (!isNaN(value)) {
        if (!valueMap[value]) {
          valueMap[value] = [key];
        } else {
          valueMap[value].push(key);
        }
      } else {
        noRow.push(key);
      }
    }

    const sortedValues = Object.keys(valueMap)
      .map(parseFloat)
      .sort((a, b) => a - b);

    sortedValues.forEach((value) => {
      result.push(valueMap[value]);
    });
    noRow.forEach((key) => {
      result.push([key]);
    });
    setOrder(result);
  }
  useEffect(() => {
    createOrder(filteredFields);
  }, [fields]);
  return (
    <>
      <div className="container"></div>
      {fields && order && (
        <>
          {order.map((row) => {
            return (
              <div className="row">
                {row.map((fieldName) => {
                  const fieldInfo = fields[fieldName];
                  const colSize = row.length === 1 ? "col-12" : "col-5";
                  return (
                    <>
                      <div
                        className={clsx(colSize, "input-form-field")}
                        key={`${fieldName}-${key}`}
                      >
                        <Field
                          translate={translate}
                          nameEntity={nameEntity}
                          formErrors={formErrors}
                          name={fieldName}
                          required={fields[fieldName]?.required}
                          form={form}
                          value={form[fieldName]}
                          output={handleUpdate}
                          fieldInfo={fieldInfo}
                        ></Field>
                      </div>
                    </>
                  );
                })}
              </div>
            );
          })}
        </>
      )}

      <CustomButton
        disable={disableStatusButton}
        onClick={() => onSave && onSave(form)}
      >
        {intl.formatMessage({ id: "MAIN.Salva" })}
      </CustomButton>
    </>
  );
};

export default Form;
