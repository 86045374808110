import { addRequiredProperty, excludeFieldByName } from "../../utils/helper";
import { EntityParsed } from "../store";
import Layout from "../../json/layout.json";

export interface GlobalContextState {
  /**
   * Loading state of the application
   */
  loading: boolean;
  /**
   * API Docs from the backend
   */
  apiDocs: apiDoc | null;
  /**
   * current location
   */
  location: string;
  /**
   * In a table view, the selected row item
   */
  selectedRowItem: any;
}
export interface LayoutJson {
  entity: {
    [key: string]: {
      filters: {
        [key: string]: filterSchemaData;
      };
    };
  };
}

export enum typeFilter {
  RF = "referenceFilter",
  SFWO = "selectFilterWithOptions",
  TF = "textFilter",
  AVF = "addValueFilter",
  CF = "checkboxFilter",
}
export interface filterSchemaData {
  name: string;
  schema: {
    filterType?: typeFilter;
    customFilter?: string;
    path?: string;
    mainField?: string;
    icon?: string;
    colorIcon?: string;
    options?: { [key: string]: string };
    label?: string;
    type?: string;
    format?: string;
    items?: { format: string };
    column?: number;
  };
}
export interface GlobalContextAction {
  /**
   * Type of action
   */
  type: "SET_GLOBAL_STATE";
  /**
   * Payload of the action
   */
  payload: GlobalContextState;
}

const initialState: GlobalContextState = {
  loading: true,
  apiDocs: null,
  location: "",
  selectedRowItem: {},
};

/**
 * Provides the application of all the global states and actions
 */
export default function rootContext(
  state: GlobalContextState = initialState,
  action: GlobalContextAction
): GlobalContextState {
  switch (action.type) {
    case "SET_GLOBAL_STATE":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * @todo reorganize this function
 */

const findTypeAndFormat = (name, filters) => {
  const currentFilters = Layout.entity[name]?.filters;
  if (currentFilters) {
    const listCurrentFilters: string[] = Object.keys(currentFilters);
    const filtersFiltered = filters.filter((filter) =>
      listCurrentFilters.find(
        (nameCurrentFilter) => nameCurrentFilter === filter.name
      )
    );

    const filtersWithSchemaOverride = filtersFiltered.map((currentFilter) => {
      const currentFilterName = currentFilter.name;

      const currentSchemaToOverrite = currentFilters[currentFilterName];

      return {
        ...currentFilter,
        ["schema"]: { ...currentFilter.schema, ...currentSchemaToOverrite },
      };
    });

    return filtersWithSchemaOverride;
  }

  return filters;
};

export function parseDataFromGlobalContext(
  data: apiDoc | null
): EntityParsed[] | null {
  if (!data) return null;
  const listComponents = data.components.schemas;
  const allEntity = Object.keys(data.paths)
    .filter(
      (entity) =>
        entity.includes("email") ||
        (entity.split("/").length === 3)
    )
    .map((entity) => {
      let $ref =
        data.paths[entity]?.get?.responses[200]?.content[
          "application/ld+json"
        ].schema.properties["hydra:member"].items.$ref
          .split("/")
          .slice(-1)[0] ||
        data.paths[entity]?.post?.responses[201]?.content[
          "application/ld+json"
        ].schema.$ref
          .split("/")
          .slice(-1)[0];
      let fields = listComponents[$ref]?.properties;
      let name = entity.split("/")[2];
      let fieldsRequired = listComponents[$ref].required
        ? listComponents[$ref].required
        : [];
      return {
        name: name,
        $ref: $ref,
        tag: data.paths[entity].get ? data?.paths[entity].get?.tags[0] : null,
        filters: Layout.entity[name]?.filters,
        fields: addRequiredProperty(excludeFieldByName(fields), fieldsRequired),
        required: listComponents[$ref].required
          ? listComponents[$ref].required
          : [],
      };
    });

  return allEntity;
}
