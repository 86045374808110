import React, { useMemo, useState } from "react";
import CellInterface from "./CellInterface";
import { useIntl } from "react-intl";
import {
  MessagesReducerTypeActions,
  MessageTypeEnum,
} from "../../redux/stores/message/messageReducer";
import { HTTPMethod } from "../../utils/helper";
import { apiCall } from "../../utils/serviceCall";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { ImportsCompanyControllerApi } from "../../apis/importsCompanyController";
const onClickStopPropagation = (e: any) => e.stopPropagation();

const CellImportsCompany = ({ value, type, element }: CellInterface) => {
  const companyId = value["@id"].split("/").pop();
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    name,
    court,
    companyCurators,
    location,
    procedureTypology,
    sales,
    activity,
    relevance,
    capital,
    companiesUser,
    activitiesCode
  } = value;
  const [companyRelevance, setCompanyRelevance] = useState<number>(relevance);
  const [processed, setProcessed] = useState<boolean>(element.isProcessed);
  const handlePostRelevance = async (value) => {
    try {
      await apiCall(HTTPMethod.POST, `/companies/${companyId}/relevance`, value);
      dispatch({
        type: MessagesReducerTypeActions.SET,
        payload: {
          type: MessageTypeEnum.SUCCESS,
          message: intl.formatMessage({ id: "SaveSuccess" }),
        },
      });
    } catch {
      console.log("alert");
    }
  };

  return (
    <>
      <div className={"CellImportsCompany tableImports"}>
        <div className="rowImports">
          <div className="cellStatusImports">
          <div className="p-4  flex-column">
            <div className="m-auto">
              <span>{intl.formatMessage({id: `ImportsCompanies.${processed ? 'processed' : 'to_process'}` })}</span>
            </div>
            <div className="m-auto">
              <i
                className={clsx('m-auto fa-solid fs-lg-2', {
                  ['fa-clipboard-check text-success']: processed,
                  ['fa-clipboard-list text-warning']: !processed
                })}
              ></i>
            </div>
          </div>
          </div>
          <div className="cellImports">
        <div className="rowImports">
              <a
                href={`https://www.google.com/search?q=${encodeURIComponent(value?.name)} ${court.name}`}
                onClick={onClickStopPropagation}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i
                  className="fa-brands fa-google fs-2 google-icon"
                ></i>
              </a>
            {name && <h3 className="inline mx-2">{value?.name}</h3>}
            </div>
            {companyCurators &&
              companyCurators.map((el, idx) => {
                return (
                  el.main && (
                    <div key={`${el.curator.fullName}-${idx}`}>
                      <i className="fa-solid fa-briefcase text-primary"></i>
                      <span className="mx-2">{el.curator.fullName}</span>
                    </div>
                  )
                );
              })}
            {court && (
              <div>
                <i className="fa-solid fa-gavel text-primary "></i>
                <span className="mx-2">{court.name}</span>
              </div>
            )}
            <div>
              <i className="fa-solid fa-tag text-primary "></i>
              <span className="mx-2">{location ? intl.formatMessage({id: `ProcedureTypology.${procedureTypology}`, defaultMessage: "-"}) : "-"}</span>
            </div>
          </div>
          <div className="cellImports">
            <div>
            {intl.formatMessage({ id: "Companies.activitiesCode" })}:{" "}
              {activitiesCode ? `${activitiesCode}` : "-"}
            </div>
            {/* <div> */}
            {/*   {intl.formatMessage({ id: "Companies.sales" })}:{" "} */}
            {/*   {sales ? `${sales} €` : "-"} */}
            {/* </div> */}
            {/* <div> */}
            {/*   {intl.formatMessage({ id: "Companies.capital" })}:{" "} */}
            {/*   {capital ? `${capital} €` : "-"} */}
            {/* </div> */}
            <div>
              {intl.formatMessage({ id: "Companies.city" })}:{" "}
              {location ? location.region3 : "-"}
            </div>
            <div>
              {intl.formatMessage({ id: "Companies.province" })}:{" "}
              {location ? location.region2 : "-"}
            </div>
          </div>

          <div className="cellImports">{activity}</div>
          <div className="cellImports">
            <select
              onClick={onClickStopPropagation}
              className="form-select"
              value={companyRelevance}
              onChange={(e) => {
                setCompanyRelevance(+e.target.value);
                handlePostRelevance({ relevance: +e.target.value });
              }}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((number) => (
                <option value={number} key={`relevance-option-${number}`}>
                  {number}
                </option>
              ))}
            </select>
          </div>

          <div className="cellImports">
            <div className="d-flex flex-column">
              {companiesUser
                .filter((el) => companiesUser.indexOf(el))
                .map((el, idx) => (
                  <span key={`${el.user.fullName}-${idx}`}>
                    <i className="fa-solid fa-user-group text-primary" />
                    <span className="mx-2">{el.user.fullName}</span>
                  </span>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CellImportsCompany;
