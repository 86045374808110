import { HTTPMethod } from "../utils/helper";
import { apiCall } from "../utils/serviceCall";

/**
 * API Controller for AttachmentDirectory entity
 */
export class AttachmentDirectoryControllerApi {
  static async patch(id: string, params?: any) {
    return apiCall(HTTPMethod.PATCH, `/attachment_directories/${id}`, params);
  }
  static async delete(id: string) {
    return apiCall(HTTPMethod.DELETE, `/attachment_directories/${id}`);
  }
}
