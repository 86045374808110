
import { SettingDetail } from "../../../typings/dn"
import { initialOrderDnd } from "../../../utils/helper";

interface DndCoordinate {
    row: number;
    col: number;
    tabsKey?: string;
}
export interface DndState {
    order: SettingDetail,
    modifiedStatus: boolean,
    listComponentInUse: string[],
    selectedTabs: string | null,
    currentPositionSelected: { 'row': number, 'col': number, 'tabs'?: number,'key'?:string } | null
}
const initialDndState: DndState = {
    order: initialOrderDnd,
    modifiedStatus: false,
    listComponentInUse: [],
    selectedTabs: null,
    currentPositionSelected: null
}
export enum actionsTypeDnd {
    set = 'SET_DND_STATE',
    clean = 'CLEAN_DND_STATE'
}
export interface DndReducerAction {
    type: actionsTypeDnd,
    payload: DndState

}
export default function dndReducer(state: DndState = initialDndState, action: DndReducerAction): DndState {
    switch (action.type) {
        case actionsTypeDnd.set:
            return {
                ...state,
                ...action.payload
            }
        case actionsTypeDnd.clean:
            return {
                ...state,
                ...initialDndState
            }
        default:
            return state
    }
}