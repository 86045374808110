import { useMemo, useState } from "react";
import { UserImage } from "../../UserImage/UserImage";


export default function UserIcon({ props }) {
    const { name, surname }: { name: string | null, surname: string | null } = useMemo(() => {
        const elements = props.data && props.data.split(' ')
        return { name: elements[0], surname: elements[1] }
    }, [props.data])
    return <>
       {name && surname && <UserImage credential={{ name: name, surname: surname }}></UserImage>}
    </>
}