import { DefaultEditorProps, DynamicComponents, EditorProps } from "."
import { DynamicTypes } from "../../typings/dn"

interface DynamicComponentProps {
  type: keyof typeof DynamicComponents
  [key: string]: EditorProps & any
}

export default function DynamicComponent({ ...props }: DynamicComponentProps): React.ReactNode {
  const { type, ...rest } = props
  const Component = DynamicComponents[type]

  if (!Component) {
    return <div
      style={{
        border: "1px solid red",
        padding: "1rem",
        margin: "1rem",
        color: "red",
        backgroundColor: "rgba(255,0,0,0.1)",
      }}
    >Component not implemented/found for this field</div>
  } else {
    return <Component {...DefaultEditorProps} {...rest} />
  }
}

/**
 * Dynamic Component To String
 * @param component Dynamic Component to be parsed
 * @returns String representation of the Dynamic Component
 */
export function dcts(component: DynamicTypes) {
  if (component instanceof Object && 'pages' in component) {
    return "Tabs";
  }
  return component;
}