/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect } from "react";
import Pagination from "react-bootstrap/Pagination";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import {
  checkIfValue,
  getNumberPage,
  parseRequestQuery,
} from "../../utils/helper";
import clsx from "clsx";

type Props = {
  view?: any;
  currentPage: number;
  callback?: (number) => void;
};
const Paginator: React.FC<Props> = ({ view, currentPage, callback }) => {
  //
  const classActionsCells = "pagination-actions-cells";
  //
  const location = useLocation();
  const navigate = useNavigate();
  const firstPage = getNumberPage(checkIfValue(view, "hydra:first"));
  const previousPage = getNumberPage(checkIfValue(view, "hydra:previous"));
  const nextPage = getNumberPage(checkIfValue(view, "hydra:next"));
  const lastPage = getNumberPage(checkIfValue(view, "hydra:last"));
  const arrayWithAllPosition = Array(lastPage)
    .fill(1)
    .map((x, i) => i + 1);
  let count = 0;
  const manageRoute = (numberPage: number): void => {
    if (!callback) {
      let queryParsed: any = parseRequestQuery(location.search);
      if (!queryParsed["page"]) {
        Object.assign(queryParsed, { page: numberPage });
      } else {
        queryParsed.page = numberPage;
      }
      navigate(`?${createSearchParams(queryParsed)}`);
    } else {
      callback(numberPage);
    }
  };
  return (
    <ul className="pagination">
      <Pagination.Item
        className={clsx(classActionsCells)}
        onClick={() => manageRoute(1)}
      >
        <i className="fa-solid fa-angles-left"></i>
      </Pagination.Item>
      {previousPage && (
        <Pagination.Item
          className={clsx(classActionsCells)}
          onClick={() => manageRoute(previousPage)}
        >
          <i className="fa-solid fa-chevron-left"></i>
        </Pagination.Item>
      )}
      {arrayWithAllPosition.map((number, index) => {
        if (lastPage && lastPage <= 5) {
          return (
            <Pagination.Item
              key={`pagination-${index}`}
              className={clsx(
                number === currentPage && "pagination-current-page"
              )}
              onClick={() => manageRoute(number)}
            >
              {number}
            </Pagination.Item>
          );
        } else {
          if (
            number === lastPage ||
            number === currentPage ||
            number === firstPage
          ) {
            return (
              <Pagination.Item
                className={clsx(
                  number === currentPage && "pagination-current-page"
                )}
                key={`pagination-${index}`}
                onClick={() => manageRoute(number)}
              >
                {number}
              </Pagination.Item>
            );
          }
          if (number < currentPage || number > currentPage) {
            if (count === 0) {
              count++;
              return <Pagination.Ellipsis key={`pagination-${index}`} />;
            } else {
              if (number === lastPage || number === currentPage + 1) {
                count = 0;
              }
              return null;
            }
          } else {
            return null;
          }
        }
      })}
      {nextPage && (
        <Pagination.Item
          className={clsx(classActionsCells)}
          onClick={() => manageRoute(nextPage)}
        >
          <i className="fa-solid fa-chevron-right"></i>
        </Pagination.Item>
      )}
      {lastPage && (
        <Pagination.Item
          className={clsx(classActionsCells)}
          onClick={() => manageRoute(lastPage)}
        >
          <i className="fa-solid fa-angles-right"></i>
        </Pagination.Item>
      )}
    </ul>
  );
};

export { Paginator };
