import { useState, useEffect } from "react";
import {
  takeIdFromIri,
  HTTPMethod,
  hydra,
  getListOfIdFromIri,
  generateUniqueId,
  parseDashboardDetailsPath,
  handleStore,
  saveNumberOfElementOnStore,
} from "../../../utils/helper";
import { apiCall } from "../../../utils/serviceCall";
import {
  CompanyState,
  FilterCompanyAlert,
} from "../../../pages/dashboard/CompanyState";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";

import { useIntl } from "react-intl";
import { CompanyCard } from "../../../pages/dashboard/CompanyCard";
import { Colors } from "../../../utils/colors";

export default function CuratorsCardsEditor({ props }) {
  const path: string = props.type.editor.path;
  const intl = useIntl();
  const [filterCard, setFilterCard] = useState({
    past: true,
    future: true,
    new: true,
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [curators, setCurators] = useState<any>([]);
  const [listResponse, setListResponse] = useState<any[]>([]);
  const [courts, setCourts] = useState<any>([]);
  const [companyCurator, setCompanyCurators] = useState<any[]>([]);
  const location = useLocation();
  let pathstart = location.pathname;
  let hashData = location.hash;
  const { pathId, pathName, hashName, hashId } = parseDashboardDetailsPath(
    pathstart,
    hashData
  );
  let pathToUtilize = {
    pathName: pathName,
    pathId: pathId,
  };
  if (hashName && hashId) {
    pathToUtilize.pathName = hashName;
    pathToUtilize.pathId = hashId;
  }

  // varie fetch
  const newDashboard = async (filter) => {
    await fetchData(filter, CompanyState.NEW, 1, null);
  };
  const processingFutureDashboard = async (filter) => {
    // dateAlarm >= today
    await fetchData(filter, CompanyState.PROCESSING, 1, CompanyState.FUTURE);
  };
  const processingPastDashboard = async (filter) => {
    // dateAlarm < today
    await fetchData(filter, CompanyState.PROCESSING, 1, CompanyState.PAST);
  };
  async function takeCurators(listOfCompaniesId, companyCuratorsFromPropsIri) {
    await apiCall(HTTPMethod.GET, `/curator_companies`, {
      params: {
        companies: listOfCompaniesId,
        itemsPerPage: 1000,
      },
    }).then(async (response) => {
      const listCompanyCurators = response.data["hydra:member"];
      // companyCurators
      setCompanyCurators((prev) => [...prev, ...listCompanyCurators]);
      let curatorsId = takeIdFromIri(
        getListOfIdFromIri(listCompanyCurators.map((el) => el.curator))
      );
      await apiCall(HTTPMethod.GET, "/curators", {
        params: { id: curatorsId, itemsPerPage: listCompanyCurators.length },
      }).then((response) => {
        const listCurators = response.data["hydra:member"];
        setCurators(listCurators);
      });
    });
  }
  const handleSaveResponse = (name: string, response: any) => {
    const now = new Date();
    const responseWithStatus: any[] = response[hydra.MEMBER].map((el) => ({
      ...el,
      statusCard: name,
    })).filter(el=>name != CompanyState.PAST || el.alertDate == null || new Date(el.alertDate) < now);
    // NOTE: this filter is needed in order to prevent companies to show both in past and future when
    // they are followed by other users. It should be filtered by backend but we cannot
    // define if past/future for those cases.
    setListResponse((prev) => [...prev, ...responseWithStatus]);
  };

  const initialize = async (page?: number) => {
    setListResponse([]);
    setLoading(true);
    const arrayOfId: number[] = props.data.map((iri) => takeIdFromIri(iri));
    const params = {
      params: { id: arrayOfId, itemsPerPage: arrayOfId.length },
    };
    await apiCall(HTTPMethod.GET, `/${path}`, params)
      .then((response) => {
        const result = response.data[hydra.MEMBER];
        let listOfCompanies = takeIdFromIri(
          getListOfIdFromIri(result.map((el) => el.companies))
        );
        const checkPage = page ? { page: page } : { page: 1 };
        const objectToSend = { id: listOfCompanies,  itemsPerPage: 1000,  ...checkPage };
        takeCurators(listOfCompanies, result);
        newDashboard(objectToSend);
        processingFutureDashboard(objectToSend);
        processingPastDashboard(objectToSend);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const fetchData = async (filter, state, page, time) => {
    let cloneFilter = Object.assign({}, filter);
    delete cloneFilter[FilterCompanyAlert.FILTER_BEFORE];
    delete cloneFilter[FilterCompanyAlert.FILTER_STRICTLY_AFTER];

    cloneFilter["companyGroups.state"] = state;

    if (time === CompanyState.PAST) {
      cloneFilter[FilterCompanyAlert.FILTER_BEFORE] = moment(new Date()).format(
        "YYYY-MM-DDTHH:mm"
      );
      cloneFilter["companyGroups.state"] = [
        "processing",
        "evaluation",
        "offerdo",
        "offermade",
        "wait",
        "whattodo",
        "todovisit",
        "closed",
        "acquired",
        "plannedvisit",
        "donevisit",
      ];
    }
    if (time === CompanyState.FUTURE) {
      cloneFilter[FilterCompanyAlert.FILTER_STRICTLY_AFTER] = moment(
        new Date()
      ).format("YYYY-MM-DDTHH:mm");
      cloneFilter["companyGroups.state"] = [
        "processing",
        "evaluation",
        "offerdo",
        "offermade",
        "wait",
        "closed",
        "acquired",
        "whattodo",
        "todovisit",
        "plannedvisit",
        "donevisit",
      ];
    }

    await apiCall(HTTPMethod.GET, `/companies`, {
      params: { ...cloneFilter },
    })
      .then((response) => {
        const data = response.data;
        const dynamicList = data["hydra:member"];
        // const totalItems = data["hydra:totalItems"];
        // const pagination = data["hydra:view"];

        // let listOfCompanyCuratorsId = takeIdFromIri(
        //   getListOfIdFromIri(dynamicList.map((el) => el.companyCurators))
        // );
        let listOfCourtsId = takeIdFromIri(dynamicList.map((el) => el.court));

        if (listOfCourtsId && (listOfCourtsId as number[]).length > 0) {
          takeCourts(listOfCourtsId, state, time);
        }

        switch (state) {
          case CompanyState.NEW:
            handleSaveResponse(CompanyState.NEW, data);
            break;
          case CompanyState.PROCESSING:
            if (time === CompanyState.PAST) {
              handleSaveResponse(CompanyState.PAST, data);
            }
            if (time === CompanyState.FUTURE) {
              handleSaveResponse(CompanyState.FUTURE, data);
            }
            break;
          default:
            break;
        }
      })
      .catch((error) => {
        console.log(error, "<----- errore");
      })
      .finally(() => setLoading(false));
  };
  async function takeCourts(filter, state, time = null) {
    const keyToUse = time ? state + time : state;
    await apiCall(HTTPMethod.GET, `/courts`, { params: { id: filter } }).then(
      async (response) => {
        const listCourts = response.data["hydra:member"];
        setCourts(prevCourts => [...prevCourts, ...listCourts]);
      }
    );
  }
  const toggleClickRow = (id, name) => {
    // console.log(pathstart, name, id);
    const pathDashboard = pathstart.includes("dashboard") ? "/dashboard" : "";
    navigate(`${pathDashboard}/${name}/${id}`);
  };
  useEffect(() => {
    if (listResponse) {

      saveNumberOfElementOnStore(pathstart, props.name, listResponse);
    }
  }, [listResponse]);
  useEffect(
    () => {
      if (props.data.length > 0) {
        initialize();
      }
    },
    // eslint-disable-next-line
    [props.data]
  );
  const companiesColum = {
    [CompanyState.NEW]: {
      companyState: CompanyState.NEW,
      data: "dashboardNew",
      courtsState: CompanyState.NEW,
      textColor: 'black',
      color: Colors.COMPANIES_NEW_BORDER,
    },
    [CompanyState.FUTURE]: {
      companyState: CompanyState.PROCESSING,
      data: "dashboardFutureProcessing",
      courtsState: CompanyState.PROCESSING + CompanyState.FUTURE,
      textColor: 'white',
      color: Colors.COMPANIES_FUTURE_BORDER,
    },
    [CompanyState.PAST]: {
      companyState: CompanyState.PROCESSING,
      data: "dashboardPastProcessing",
      courtsState: CompanyState.PROCESSING + CompanyState.PAST,
      textColor: 'white',
      color: Colors.COMPANIES_PAST_BORDER,
    },
  };
  function sortIsFollower(a, b) {
    if (a.isFollower && !b.isFollower) {
      return -1;
    } else if (!a.isFollower && b.isFollower) {
      return 1;
    }

    if (b.relevance - a.relevance !== 0) {
      return b.relevance - a.relevance;
    }
    // Altrimenti, mantieni l'ordine originale
    return 0;
  }
  return (
    <div className="cardsEditor">
      <div className="container">
        <div className="row">
          <div className="py-4 d-flex gap-5">
            {Object.keys(companiesColum).map((el, index) => (
              <div
                className="d-flex align-items-center gap-2"
                key={`checkbox-${index}`}
              >
                <input
                  id={`radio-${el}`}
                  className="form-check-input p-1"
                  type="checkbox"
                  onChange={(e) => {
                    setFilterCard((preState) => ({
                      ...preState,
                      [el]: e.target.checked,
                    }));
                  }}
                  checked={filterCard[el]}
                />

                <div
                  className="badge text-uppercase"
                  style={{ backgroundColor: companiesColum[el].color, color: companiesColum[el].textColor }}
                >
                  {intl.formatMessage({ id: el })}
                </div>
              </div>
            ))}
            <span className="badge badge-secondary">
              {
                listResponse
                  .sort(sortIsFollower)
                  .filter((el) => filterCard[el.statusCard]).length
              }
            </span>
          </div>
          {listResponse.length > 0 &&
            listResponse
              .sort(sortIsFollower)
              .filter((el) => filterCard[el.statusCard])
              .map((el, idx) => (
                <CompanyCard
                  time={false}
                  key={`${idx}+${generateUniqueId()}`}
                  company={el}
                  courts={courts}
                  curators={curators}
                  companyCurators={companyCurator}
                  index={idx}
                  toggleClickRow={toggleClickRow}
                  title={el.statusCard}
                  showState
                />
              ))}
        </div>
      </div>
    </div>
  );
}
