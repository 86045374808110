import { useSelector } from "react-redux";
import { CombineReducersState } from "../../../redux/store";
import { useLocation } from "react-router-dom";
import { parseDashboardDetailsPath } from "../../../utils/helper";

const ConditionHidden = (caseValue: string): boolean => {
  const location = useLocation();
  let pathstart = location.pathname;
  const { pathId, pathName } = parseDashboardDetailsPath(pathstart);
  const state = useSelector(
    (state: CombineReducersState) => state.detailsReducer
  );

  const dynamicCase = {
    // example:
    // activitiesCode: () => {
    //   const activity = rootState?.form?.activity;
    //   if (!activity || activity.trim() === "") {
    //     return false;
    //   }
    //   return true;
    // },
    default: () => {
      return false;
    },
  };

  const action = dynamicCase[caseValue] || dynamicCase.default;

  return action();
};
export default ConditionHidden;
