export interface userDataInterface {
  "@id": string;
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  avatar: string | null;
  customImport?: string;
  courts?: string[];
  roles?: string[];
  active: boolean;
  states:string[],
  groupUser: { [key: string]: any }[];
}
export interface UserState {
  token: string | null;
  refresh_token: string | null;
  userData: userDataInterface | null;
}
const initialUserState: UserState = {
  token: null,
  refresh_token: null,
  userData: null,
};
export enum actionsTypeUser {
  // save_user= 'SAVE_USER_STATE',
  // save_refresh_token= 'SAVE_REFRESH_TOKEN_STATE',
  // save_token= 'SAVE_TOKEN_STATE',
  set = "SET_USER_STATE",
  clean = "CLEAN_USER_STATE",
}
export interface UserReducerAction {
  type: actionsTypeUser;
  payload: UserState;
}

export default function userReducer(
  state: UserState = initialUserState,
  action: UserReducerAction
): UserState {
  switch (action.type) {
    case actionsTypeUser.set:
      return {
        ...state,
        ...action.payload,
      };
    case actionsTypeUser.clean:
      return {
        ...state,
        ...initialUserState,
      };
    default:
      return state;
  }
}
