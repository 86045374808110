import React from "react";
import { useIntl } from "react-intl";

export default function EmptyPlaceholder({
  nameField,
  nameDetail = null,
}: {
  nameField: string;
  nameDetail?: null | string;
}) {
  const customEmpty: string[] = ["note"];
  const exclude: string[] = [];
  const intl = useIntl();
  const renderTranslate = () => {
    switch (true) {
      case exclude.includes(nameField): {
        return;
      }
      case customEmpty.includes(nameField): {
        return `Empty.${nameField}`;
      }
      case nameDetail !== null:
        return `${
          nameDetail!.charAt(0).toUpperCase() + nameDetail!.slice(1)
        }.${nameField}`;
      default: {
        return nameField;
      }
    }
  };
  return (
    <span className="empty-placeholder">
      [{intl.formatMessage({ id: renderTranslate() })}]
    </span>
  );
}
