/* React */
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { FC, useEffect, useState } from "react";

/* Components */
import Sidebar from "./Sidebar";
import { SidebarMenuItem } from "./Item";
import { SidebarMenuItemWithSub } from "./ItemSub";
import Layout from "../../json/layout.json";
/* Utils */
import { getDocJson } from "../../utils/serviceCall";
import LoadingSplashScreen from "../../pages/loading/LoadingSplashScreen";

/* Redux */
import { store } from "../../redux/store";
import { parseDataFromGlobalContext } from "../../redux/stores/rootContext";

export const GlobalSidebar: FC = () => {
  const intl = useIntl();
  const location = useLocation();
  const [localData, setLocalData] = useState<apiDoc | null>();
  const menuEntity: string[] = Layout.routeToShow;
  const globalState = store.getState();
  const iconList = {
    "/api/companies": "fa-solid fa-building",
    "/api/curators": "fa-solid fa-briefcase",
    "/api/courts": "fa-solid fa-gavel",
    "/api/users": "fa-solid fa-user",
    tools: "fa-solid fa-screwdriver-wrench",
  };

  // const navigate = useNavigate()
  useEffect(() => {
    store.dispatch({
      type: "SET_GLOBAL_STATE",
      payload: { location: location.pathname },
    });
    
    
    if (!globalState.rootContext.apiDocs) {
      getDocJson()
        .then((data) => {
          store.dispatch({
            type: "SET_GLOBAL_STATE",
            payload: { apiDocs: data },
          });
        })
        .finally(() => {
          setLocalData(store.getState().rootContext.apiDocs);
          store.dispatch({
            type: "SET_GLOBAL_STATE",
            payload: { loading: false },
          });
        });
    } else {
      setLocalData(globalState.rootContext.apiDocs);
      store.dispatch({ type: "SET_GLOBAL_STATE", payload: { loading: false } });
    }

    // Needs to execute only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [forceReload, setForceReload] = useState<number>(0);
  let menuItems: any = null;
  const paths = localData?.paths;
  if (localData === null || localData === undefined) {
    return <LoadingSplashScreen />;
  } else {
    menuItems =
      paths &&
      Object.keys(paths)
        ?.filter((path) => menuEntity.includes(path))
        .map((item: string) => {
          const name = item.split("/")[2];
          return (
            <SidebarMenuItem
              key={name}
              to={`/${name}`}
              icon={iconList[item]}
              title={name}
            />
          );
        });
  }

  return (
    <>
      <Sidebar>
        <SidebarMenuItem
          to="/dashboard"
          icon="fa-solid fa-table"
          title={intl.formatMessage({ id: "dashboard" })}
        />
        {menuItems && menuItems}
        <SidebarMenuItemWithSub
          key={`${JSON.stringify(localData)}`}
          to="/"
          title={intl.formatMessage({ id: "tools" })}
          icon={iconList["tools"]}
          insideRoutes={["/imports_companies", "/mail_models", "/settings"]}
        >
          <SidebarMenuItem
            key={"imports_companies"}
            to={`/imports_companies?company.companyGroups.isProcessed=false`}
            title={"imports_companies"}
            hasBullet
          />
          <SidebarMenuItem
            key={"mail_models"}
            to={`/mail_models`}
            title={"mail_models"}
            hasBullet
          />{
            globalState.userReducer.userData?.roles?.includes('ROLE_ADMIN') &&
          <SidebarMenuItem
            key={"settings"}
            to={`/settings`}
            title={"settings"}
            hasBullet
          />}
        </SidebarMenuItemWithSub>
      </Sidebar>
    </>
  );
};
