import { useState, useRef } from "react";
import { HTTPMethod } from "../../../../utils/helper";
import { apiCall } from "../../../../utils/serviceCall";
import { useIntl } from "react-intl";
import useFetchOnVisible from "../../../../hooks/useFetchOnVisible";

const ImagePreview = ({ id, type }: { id: number; type: any }) => {
  const intl = useIntl();

  const [imgSrc, setImgSrc] = useState("");
  const [failed, setFailed] = useState(false);

  // create a ref for the component's root element
  const ref = useRef(null);

  // use the custom hook with the ref and a fetch function
  const [data, loading] = useFetchOnVisible(ref, () => getSource());

  const getSource = async () => {
    if ((imgSrc != null && imgSrc != "") || failed)
      return;

    await apiCall(HTTPMethod.GET, `/${type.editor.path}/${id}/preview`, {
      headers: "accept: image/jpeg",
      responseType: "blob",
    })
      .then((response) => {
        const result = response.data;

        let blobUrl = window.URL.createObjectURL(result);
        setImgSrc(blobUrl);
      })
      .catch((err) => {
        console.log(err);
        setFailed(true);
      });
  };

  if (!imgSrc && !failed) {
    return (
      <>
        <div ref={ref}>{intl.formatMessage({ id: "Attachments.loading" })}</div>
      </>
    );
  }
  if (!imgSrc) {
    return (
      <>
        <div ref={ref}>
          {intl.formatMessage({ id: "Attachments.noPreview" })}
        </div>
      </>
    );
  }
  return (
    <div ref={ref}>
      {imgSrc ? imgSrc && <img src={imgSrc} /> : <p>NotVisible</p>}
    </div>
  );
};

export default ImagePreview;
