import React, {useState} from 'react';
import Toast from 'react-bootstrap/Toast';
import {ToastContainer} from "react-bootstrap";
import {useSelector, useDispatch} from "react-redux";
import {CombineReducersState} from "../../redux/store";
import {MessagesReducerTypeActions, MessageTypeEnum} from "../../redux/stores/message/messageReducer";

export function DToast({}) {
    const dispatch = useDispatch()

    const messages = useSelector((state: CombineReducersState) => state.messageReducer)

    const [showToast, setShowToast] = useState(true);
    const toggleToast = () => {
        //setShowToast(!showToast);
        dispatch({
            type: MessagesReducerTypeActions.CLEAR
        })
    };
    return (messages && messages.map((message, index) => {

            return <ToastContainer
                key={index}
                className="p-3"
                position={'bottom-end'}
                style={{zIndex: 2046}}
            >
                <Toast
                    onClose={toggleToast}
                    show={true}
                    delay={3000} // Durata del toast in millisecondi (in questo caso, 3 secondi)
                    bg={message.type ? message.type : 'info'}
                    autohide
                >
                    <Toast.Body style={{color:'white'}}>
                        {message.message}
                    </Toast.Body>
                </Toast>
            </ToastContainer>
        })

    );
}

