import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import SelectEditor from "./SelectEditor";
import { Modal } from "react-bootstrap";
import CustomButton from "../../buttons/CustomButton";
import { HTTPMethod, parseDashboardDetailsPath } from "../../../utils/helper";

import { apiCall } from "../../../utils/serviceCall";
import { useSelector } from "react-redux";
import { CombineReducersState } from "../../../redux/store";
import { useLocation } from "react-router-dom";
import { CompanyState } from "../../../pages/dashboard/CompanyState";
import { useLang } from "../../../vendor/_metronic/i18n/Metronici18n";
import moment from "moment";

interface SelectEditorProps {
  props: {
    name: string;
    type: {
      type: string;
      editor: {
        default?: string;
        translate?: string;
        options: Record<string, string>;
        path?: string;
        field?: string;
      };
    };
    data: string;
    setValue: (
      name: string,
      value: string,
      updateByItself?: boolean | "makeGet"
    ) => void;
    setShowInput: (show: boolean) => void;
  };
}

export default function SelectCloseProcedureEditor({
  props,
}: SelectEditorProps) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const intl = useIntl();
  const [showInput, setShowInput] = useState<boolean>(false);
  const defaultMessage = intl.formatMessage({
    id: "defaultNoteCloseProcedure",
  });
  const initialMessage =
    defaultMessage +
    intl.formatMessage({
      id: "closeProceduresMotivation.NOTINTERESTING",
    });

  const location = useLocation();
  let pathstart = location.pathname;

  const { path, pathId, pathName } = parseDashboardDetailsPath(pathstart);
  const userData = useSelector(
    (state: CombineReducersState) => state.userReducer.userData
  )!;

  const [selectData, setSelectData] = useState<string>(props.data);
  const currentUser = userData.id;
  const [textMessage, setTextMessage] = useState<string>(initialMessage);
  const handleClose = () => {
    setShowModal(false);
  };
  const detailsState = useSelector(
    (state: CombineReducersState) => state.detailsReducer
  );

  const followerText = useMemo(() => {
    const companiesUser =
      detailsState[pathName]?.[pathId]?.["form"]?.companiesUser;
    if (companiesUser?.length > 0) {
      return `\nFollower: ${companiesUser.join(", ")}`;
    } else {
      return "";
    }
  }, [detailsState[pathName]?.[pathId]?.["form"]?.companiesUser]);

  // const lang = useLang();
  const group = userData.groupUser?.[0]?.group["@id"];
  const sendMessage = async () => {
    const formattedMessage = textMessage.replace(/\n/g,"<br />").replace(/Follower/g, '<b>Follower</b>')
    await apiCall(HTTPMethod.POST, "/notes", {
      content:formattedMessage,
      user: `/api/users/${currentUser}`,
      company: "/api" + path,
      group: group,
      createdAt: moment().format(),
    })
      .then(async () => {
        let updateValue = CompanyState.CLOSED;
        setSelectData(updateValue);
        await apiCall(HTTPMethod.POST, `${path}/state`, {
          state: updateValue,
        }).then(() => {
          props.setValue(props.name, updateValue, "makeGet");
        });
        setShowModal(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    const visitNeeded = detailsState[pathName]?.[pathId]?.["form"]?.visitNeeded;

    if (visitNeeded === true &&
        props.data !== CompanyState.TODOVISIT &&
        props.data !== CompanyState.PLANNEDVISIT &&
        props.data !== CompanyState.DONEVISIT
    ) {
      const updateValue = CompanyState.TODOVISIT;
      setSelectData(updateValue);
      apiCall(HTTPMethod.POST, `${path}/state`, {
        state: updateValue,
      }).then(() => {
        props.setValue(props.name, updateValue, "makeGet");
      });
    }
  }, [detailsState[pathName]?.[pathId]?.["form"]]);
  useEffect(() => {
    if (showModal) {
      setTextMessage(initialMessage + followerText);
    }
  }, [showModal]);
  return (
    <>
      <Modal show={showModal} onHide={() => handleClose()}>
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="mb-5">
            <p className="dynamic-editor-label">
              {intl.formatMessage({ id: "closeNoteMotivation" })}
            </p>
            <select
              onChange={(e) => {
                if (e.target.value === "NOTSELECT") {
                  setTextMessage(defaultMessage + followerText);
                } else {
                  const value = intl.formatMessage({
                    id: `closeProceduresMotivation.${e.target.value}`,
                  });
                  setTextMessage(`${defaultMessage} ${value}` + followerText);
                }
              }}
              className="form-control form-control-solid"
            >
              {[
                "NOTSELECT",
                "NOTINTERESTING",
                "NOMOVABLEGOODS",
                "NOTINTERESTINGATTHEMOMENT",
                "SOLD",
              ].map((el, idx) => {
                const textToUse = intl.formatMessage({
                  id: `closeProceduresMotivation.${el}`,
                });
                return (
                  <option
                    key={"el" + idx}
                    selected={el === "NOTINTERESTING"}
                    value={el}
                  >
                    {textToUse}
                  </option>
                );
              })}
            </select>
          </div>

          <p className="dynamic-editor-label">
            {intl.formatMessage({ id: "addNote" })}
          </p>
          <textarea
            className="form-control form-control-solid"
            value={textMessage}
            name="insertclass"
            onChange={(e) => setTextMessage(e.target.value)}
          ></textarea>
        </Modal.Body>
        <Modal.Footer>
          <CustomButton
            onClick={() => {
              sendMessage();
            }}
          >
            {intl.formatMessage({ id: "closeProcedure" })}
          </CustomButton>
        </Modal.Footer>
      </Modal>
      {/* {selectData !== CompanyState.CLOSED && (
        <CustomButton
          onClick={() => setShowModal(true)}
          className="button-select-extended"
          type="secondary"
        >
          {intl.formatMessage({
            id: "closeProcedure",
          })}
        </CustomButton>
      )} */}
      {showInput ? (
        <SelectEditor
          props={{
            ...props,
            setValue: (name, value) => {
              if(value === CompanyState.CLOSED) {
                setShowModal(true)
              }
              setSelectData(value);
              props.setValue(name, value, true);
            },
            data: selectData,
            setShowInput: () => setShowInput(false),
          }}
        />
      ) : (
        <CustomButton
          onClick={() => setShowInput(true)}
          className="button-select-extended"
          type="primary"
        >
          {intl.formatMessage({
            id: `${props.type.editor.translate}.${selectData}`,
          })}
        </CustomButton>
      )}
    </>
  );
}
