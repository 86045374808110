import axios from "axios";
import { actionsTypeUser, userDataInterface } from "../redux/stores/user/userReducer";
import { store } from "../redux/store";
const API_URL = process.env.REACT_APP_API_URL
export const LOGIN_URL = `${API_URL}/login_check`
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/user/me`
export const REFRESH_TOKEN_URL = `${API_URL}/token/refresh`
interface autModel {
    token: string,
    refresh_token: string
}
export function login(email: string, password: string) {
    return axios.post<autModel>(LOGIN_URL, {
        email,
        password,
    })
}
export function getUserByToken(token: string) {
    return axios.get<userDataInterface>(GET_USER_BY_ACCESSTOKEN_URL, {
        headers: {
            Authorization: 'Bearer ' + token
        },
    })
}
export function refreshToken(refresh_token: string){
    return axios.post<autModel>( REFRESH_TOKEN_URL,{refresh_token:refresh_token})
}
export function logout(){
    window.localStorage.clear();
    window.location.reload()
}