import React, { FC } from "react";
import { toAbsoluteUrl } from "../../vendor/_metronic/helpers";
import moment from "moment";
import { ETypeNotifications } from "../../utils/helper";
import { useIntl } from "react-intl";

export default function Messages({
  onClick,
  notifications,
  typeNotifications,
}: {
  onClick: (e: any) => void;
  notifications: any;
  typeNotifications;
}) {
  const checkColorNotification = (type) => {
    switch (type) {
      case ETypeNotifications.MESSAGES:
        return "#369BFF";
      case ETypeNotifications.ATTACHMENTS:
      case ETypeNotifications.INVENTORY:
      case ETypeNotifications.APPRAISAL:
      case ETypeNotifications.NOTICE_OF_SALE:
      case ETypeNotifications.CHANGE_RELEVANCE:
      case ETypeNotifications.CLOSE_PROCEDURE:
      case ETypeNotifications.INSERT_PROCEDURE:
      case ETypeNotifications.IMPORT:
        return "#FFA800";
      default:
        return "#369BFF";
    }
  };
  const checkLabel = (type) => {
    switch (type) {
      case ETypeNotifications.MESSAGES:
        return "messageaLabelNotification";
      case ETypeNotifications.CLOSE_PROCEDURE:
        return "closeProcedureLabelNotification";
      case ETypeNotifications.ATTACHMENTS:
        return "attachmentsLabelNotification";
      case ETypeNotifications.INVENTORY:
        return "inventoryLabelNotification";
      case ETypeNotifications.APPRAISAL:
        return "appraisalLabelNotification";
      case ETypeNotifications.NOTICE_OF_SALE:
        return "noticeOfSaleLabelNotification";
      case ETypeNotifications.IMPORT:
        return "importLabelNotification";
      case ETypeNotifications.INSERT_PROCEDURE:
        return "insertProcedureLabelNotification";
      case ETypeNotifications.CHANGE_RELEVANCE:
        return "changeRelevanceLabelNotification";
      default:
        return null;
    }
  };
  const checkImage = (type) => {
    switch (type) {
      case ETypeNotifications.MESSAGES:
        return "messaggi.svg";
      case ETypeNotifications.ATTACHMENTS:
      case ETypeNotifications.INVENTORY:
      case ETypeNotifications.APPRAISAL:
      case ETypeNotifications.NOTICE_OF_SALE:
        return "allegati.svg";
      case ETypeNotifications.CHANGE_RELEVANCE:
      case ETypeNotifications.CLOSE_PROCEDURE:
      case ETypeNotifications.INSERT_PROCEDURE:
        return "notifications_yellow.svg";
      case ETypeNotifications.IMPORT:
        return "import_yellow.svg";
      default:
        return "messaggi.svg";
    }
  };
  const intl = useIntl();
  const data = notifications.params.split("__");
  const date = notifications.createdAt;
  let sender;
  let place;
  let note;
  let firstname;
  let surname;
  let initial;
  if (data.length === 1) {
    sender = notifications?.author ? notifications?.author.fullName : notifications?.note?.user?.fullName;
    place = notifications?.company?.name;
    note = notifications?.params;
  } else {
    sender = data[1];
    place = data[0].split("(")[0];
    note = data[3];
  }
  if (sender && sender !== 'Anonymous') {
    firstname = sender.split(" ")[0];
    surname = sender.split(" ")[1];

    initial = firstname[0] + surname[0];
  }

  const totData = date?.split("T");
  let day;
  let listDate;
  let hour;
  if (totData) {
    day = moment(totData[0]).format("DD/MM/YYYY");
    listDate = totData[1].split("+")[0].split(":");
    hour = listDate[0] + ":" + listDate[1];
  }

  return (
    <div className="timeline-item">
      {/*--begin::Timeline icon*/}
      <div className="timeline-icon">
        {/* <i className="ki-duotone ki-message-text-2 fs-2 text-gray-500"><span className="path1"></span><span className="path2"></span><span className="path3"></span></i> */}
        <img
          width={'40px'}
          height={'40px'}
          src={toAbsoluteUrl(`/assets/${checkImage(typeNotifications)}`)}
          className="position-relative pt-5 pr-2"
        ></img>
        <p className="text-normal fs-8 fw-lighter lh-1 mt-1 pr-2">
          {hour || "-"}
        </p>
        <p
          className="text-normal fs-8 fw-lighter lh-1"
          style={{ position: "relative", bottom: "10px" }}
        >
          {day || "-"}
        </p>
      </div>
      {/*--end::Timeline icon*/}
      {/*--begin::Timeline line*/}
      <div className="timeline-line"></div>
      {/*--end::Timeline line*/}
      {/*--begin::Timeline content*/}
      <div
        className="timeline-content mb-10x  border-2 rounded-2"
        style={{
          border: "2px solid",
          borderColor: `${checkColorNotification(typeNotifications)}`,
        }}
        onClick={onClick}
      >
        {/*--begin::Timeline heading*/}
        <div className="pe-3">
          {/*--begin::Title*/}
          <div className="px-7">
            <p className="my-3">
              {typeNotifications != ETypeNotifications.IMPORT &&
                <span className="text-normal fs-6 fw-bold lh-1 me-1">
                  {sender || intl.formatMessage({id: 'noneUser'})}
                </span>
              }
            <span className="text-normal fs-7 fw-normal lh-1 me-1">
              {checkLabel(typeNotifications) &&
                intl.formatMessage({
                  id: checkLabel(typeNotifications) as string,
                })}
            </span>
            {place && (
              <span className="text-capitalize fs-6 fw-bold lh-1 me-1">
                {" "}
                {place}{" "}
              </span>
            )}
            </p>
          </div>
          {/*--end::Title*/}
        </div>
        {/*--end::Timeline heading*/}
        {/*--begin::Timeline details*/}
        <div className="overflow-auto pb-5 ">
          {/*--begin::Record*/}
          <div className="d-flex align-items-center min-w-750px px-7 py-3 mb-5">
            {/*--begin::Title*/}
            <p className="fs-8 text-danger fw-semibold fst-italic w-700px min-w-200px">
              {note}
            </p>
            {/*--end::Title*/}
          </div>
          {/*--end::Record*/}
        </div>
        {/*--end::Timeline details*/}
      </div>
      {/*--end::Timeline content*/}
      {/* --start::Timeline User image  */}
      <div
        className="symbol symbol-circle symbol-25px position-absolute start-100 translate-middle"
        style={{ top: "40%" }}
      >
        <div
          className="symbol-label fs-8 fw-semibold text-inverse-primary "
          style={{ background: checkColorNotification(typeNotifications) }}
        >
          {initial ? initial : ""}
        </div>
      </div>
      {/* --end::Timeline User image  */}
    </div>
  );
}
