import React, { FC, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import moment from "moment";

import Skeleton from "react-loading-skeleton";

import {
  BasicInput,
  InputAsButtonEditor,
  WysiwygEditor,
  SelectCloseProcedureEditor,
  DateTimeInput,
  TextAreaEditor,
  CurrencyEditor,
  YesNoEditor,
  IconBooleanEditor,
  CheckBoxEditor,
  SubReferenceEditor,
  UserIcon,
  ListEditor,
  ManyToManyComplex,
  AssessmentFormEditor,
  ChatWithTagEditor,
  MainCurators,
  SelectEditor,
  FormAccordionEditor,
  LinkDetails,
  CuratorsCardsEditor,
  CuratorsLinkEditor,
  CuratorsPositionCodeEditor,
  BadgeAssessment,
  LocationAutoComplete
} from "../Editors";

import AutocompleteCompaniesUser from "../../Input/AutocompleteCompaniesUser.tsx";
import Autocomplete from "../../Input/Autocomplete.tsx";
import { apiCall } from "../../../utils/serviceCall.tsx";
import CustomButton from "../../buttons/CustomButton.tsx";
import Attachments from "../Editors/Attachments/Attachments.tsx";

import EmptyPlaceholder from "../../EmptyPlaceholder/EmptyPlaceholder.tsx";
import { Icon } from "../../Icon/Icon.tsx";
import { useIntl } from "react-intl";
import { useLocation } from "react-router";
import ConditionHidden from "./conditionHidden.ts";
import {
  ECustomColor,
  EIconType,
  HTTPMethod,
  deepExists,
  handleStore,
  parseDashboardDetailsPath,
  printStringArray,
} from "../../../utils/helper.tsx";
import { useSelector } from "react-redux";
import { CombineReducersState } from "../../../redux/store.ts";
const dynamicEditorList: { [key: string]: FC<any> } = {
  CheckBoxEditor,
  CuratorsCardsEditor,
  FormAccordionEditor,
  CuratorsPositionCodeEditor,
  LinkDetails,
  AssessmentFormEditor,
  BadgeAssessment,
  SelectAsButtonEditor: SelectEditor,
  SelectEditor,
  LocationAutoComplete,
  WYSIWYGEditor: WysiwygEditor,
  DateTimeEditor: DateTimeInput,
  DateEditor: DateTimeInput,
  TimeEditor: DateTimeInput,
  YesNoEditor,
  TextAreaEditor,
  AutocompleteCompaniesUser,
  SelectCloseProcedureEditor,
  Autocomplete,
  MainCurators,
  CurrencyEditor,
  SubReferenceEditor,
  InputAsButtonEditor,
  IconBooleanEditor,
  ChatEditor: ChatWithTagEditor,
  ManyToManyComplex,
  Attachments,
  BasicInput,
  UserIcon,
  ListEditor,
  CuratorsLinkEditor,
  
};

interface DynamicEditorProps {
  nameDetail: string;
  nameField: string;
  type: any;
  data: any;
  outputInput: (value: any) => void;
  dataFields: any;
}

// TODO Mic spostare da qualche parte in saas?
const googleIconStyle = {
  background:
    "conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat",
  WebkitBackgroundClip: "text",
  backgroundClip: "text",
  color: "transparent",
  WebkitTextFillColor: "transparent",
};

const DynamicEditor: FC<DynamicEditorProps> = (props) => {
  const intl = useIntl();
  const setValue = (name: string, value: any, updateByItself) => {
    return props.outputInput({ [name]: value, updateByItself });
  };
  const location = useLocation();
  let pathstart = location.pathname;
  const { pathId, pathName } = parseDashboardDetailsPath(pathstart);
  const state = useSelector(
    (state: CombineReducersState) => state.detailsReducer
  );
  const [prevCourts, setPrevCourts] = useState();
  const courts = useMemo(() => {
    const currentValue = state?.[pathName]?.[pathId]?.form?.["court"];
    if (currentValue !== prevCourts) {
      setPrevCourts(currentValue);
      return currentValue;
    } else {
      return prevCourts;
    }
  }, [state?.[pathName]?.[pathId]?.form?.["court"]]);
  const [showInput, setShowInput] = useState<boolean>(false);
  const propsInner: any = {
    ...props,
    name: props.nameField,
    setValue,
    setShowInput,
    disabled: false,
  };

  const listTypeBasicInput: { [key: string]: string } = {
    string: "text",
    integer: "number",
    number: "number",
    DateEditor: "date",
    TimeEditor: "time",
    DateTimeEditor: "datetime-local",
  };

  const excludeToReadonlyComponent = [
    "IconBooleanEditor",
    "ChatEditor",
    "YesNoEditor",
    "SelectCloseProcedureEditor",
    "ManyToManyComplex",
    "CuratorsPositionCodeEditor",
    "Attachments",
    "DateTimeEditor",
    "BadgeAssessment",
    "UserIcon",
    "ListEditor",
    "MainCurators",
    "AutocompleteCompaniesUser",
    "SubReferenceEditor",
    "CheckBoxEditor",
    "AssessmentFormEditor",
    "LinkDetails",
    "FormAccordionEditor",
    "CuratorsCardsEditor",
    "CuratorsLinkEditor",
    "LocationAutoComplete"
  ];
  const customEditor = (typeCustomEditor: any) => {
    let DynamicEditor;

    if (
      typeCustomEditor.editor &&
      dynamicEditorList.hasOwnProperty(typeCustomEditor.editor.name)
    ) {
      DynamicEditor = dynamicEditorList[typeCustomEditor.editor.name];
      if (typeCustomEditor.format === "date-time") {
        return (
          <DynamicEditor
            props={propsInner}
            typeBasicInput={listTypeBasicInput[typeCustomEditor.editor.name]}
          />
        );
      }
      if (typeCustomEditor.editor.name.includes("Autocomplete")|| typeCustomEditor.editor.name.includes("LocationAutoComplete") ) {
        const propsAutocomplete = {
          name: propsInner.nameField,
          value: propsInner.data,
          type: propsInner.type,
          setValue: propsInner.setValue,
          setShowInput: setShowInput,
          nameField: propsInner.nameField,
          nameDetail:propsInner.nameDetail

        };
        return <DynamicEditor {...propsAutocomplete} />;
      }
    }
    if (DynamicEditor) {
      return <DynamicEditor props={propsInner} />;
    } else {
      return <p>{typeCustomEditor.editor.name} non implementato</p>;
    }
  };

  const renderFunction = () => {
    switch (true) {
      case props.type.hasOwnProperty("editor"):
        return customEditor(props.type);
      case props.type.format === "iri-reference":
        return customEditor(props.type);
      default:
        return (
          <BasicInput
            key={props.data}
            props={propsInner}
            typeBasicInput={listTypeBasicInput[props.type.type]}
          />
        );
    }
  };
  const excludeLabel: string[] = [];

  const iconCode = props.type?.editor?.icon?.iconCode;
  return (
    <div
      className={clsx(
        "wrapper-dynamic-editor",
        ConditionHidden(props.nameField) ? "d-none" : ""
      )}
    >
      <div className={clsx(`Input-${props.nameField}`, "dynamic-input")}>
        <div className="d-flex flex-row gap-2">
          {iconCode && (
            <Icon
              nameIcon={iconCode}
              className="icon-editor"
              type={
                iconCode.includes("fa-")
                  ? EIconType.FONTAWESOME
                  : EIconType.MATERIAL
              }
              title={( props.type?.editor?.label && props.type?.editor?.label.text ) ? intl.formatMessage({ id: props.type?.editor?.label.text }) : ''}
              style={{
                color: props.type.editor.icon.iconColor
                  ? props.type.editor.icon.iconColor
                  : "#CCE5FF",
              }}
            />
          )}
          {props.type?.editor?.google && courts && (
            <a
              href={`https://www.google.com/search?q=${encodeURIComponent(
                props.data
              )} ${courts}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i
                className="fa-brands fa-google fs-2 "
                style={googleIconStyle}
              ></i>
            </a>
          )}
          {props.type?.editor?.label &&
            props.type?.editor?.label.text &&
            !props.type?.editor?.label.hide &&
            !excludeLabel.includes(props.type?.editor?.name) && (
              <div
                className={clsx(
                  "dynamic-editor-label",
                  props.type.editor.label.class
                    ? props.type.editor.label.class
                    : ""
                )}
              >
                {intl.formatMessage({ id: props.type?.editor?.label.text })}
              </div>
            )}
        </div>
        {showInput || deepExists(excludeToReadonlyComponent, props.type)
          ? renderFunction()
          : props.type && (
              <ReadOnly
                nameDetail={propsInner.nameDetail}
                nameField={propsInner.nameField}
                data={props.data}
                setShowInput={setShowInput}
                type={props.type}
              />
            )}
      </div>
    </div>
  );
};

interface ReadOnlyInterface {
  nameDetail: string;
  nameField: string;
  data: string | string[] | number | boolean;
  setShowInput: Function;
  type?: any;
}

export const ReadOnly: FC<ReadOnlyInterface> = ({
  nameDetail,
  nameField,
  data,
  setShowInput,
  type,
}) => {
  let defaultClass = "readonly cursor-pointer ";
  const intl = useIntl();
  const location = useLocation();
  let pathstart = location.pathname;
  const { pathId, pathName } = parseDashboardDetailsPath(pathstart);
  if (type.editor?.class) {
    defaultClass += type.editor.class;
  }
  const mainField = type.editor?.mainField ? type.editor?.mainField : "name";
  const readonly = type?.editor?.readonly;
  const [dataToShow, setDataToShow] = useState(data);
  const [dataToShowReference, setDataToShowReference] = useState<
    string | string[]
  >();
  const [loading, setLoading] = useState(false);
  const defaultFormatMoment: { [key: string]: string } = {
    DateEditor: "YYYY-MM-DD",
    DateTimeEditor: "YYYY-MM-DD HH:mm:ss",
    TimeEditor: "HH:mm",
  };

  const onClick = () => setShowInput(true);

  const valueFormatted = () => {
    const formatDateMoment = type.editor?.hasOwnProperty("format")
      ? type.editor.format
      : defaultFormatMoment[type.editor?.name];
    return (
      typeof data !== "boolean" &&
      !Array.isArray(data) &&
      (type.editor?.name !== "TimeEditor"
        ? moment(new Date(data)).format(formatDateMoment)
        : data)
    );
  };
  useEffect(() => {
    if (dataToShowReference && nameField === "court") {
      handleStore(dataToShowReference, {
        pathName,
        pathId,
        formField: nameField,
      });
    }
  }, [dataToShowReference]);
  const getValue = async () => {
    let numericValues: string | string[] | null = null;

    if (Array.isArray(dataToShow)) {
      numericValues = dataToShow.map((data) => data.split("/").slice(-1)[0]);
    } else {
      numericValues = (dataToShow as string).split("/").slice(-1)[0];
    }

    setLoading(true);

    if (Array.isArray(numericValues) && numericValues.length === 0) {
      setDataToShowReference([]);
      setLoading(false);
    } else {
      try {
        if (numericValues !== null) {
          // Check if numericValues is not null
          const { data } = await apiCall(
            HTTPMethod.GET,
            `/${type.editor.path}`,
            { params: { id: numericValues } }
          );
          if (data["hydra:member"].length > 1) {
            setDataToShowReference(
              data["hydra:member"].map((element) => element[mainField])
            );
          } else {
            setDataToShowReference(data["hydra:member"].pop()[mainField]);
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }
  };
  const initialize = async () => {
    setLoading(true);
    try {
      await getValue();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (type.editor?.name === "Autocomplete") {
      initialize();
    }
    // eslint-disable-next-line
  }, [data, type]);
  const sameActions = { onClick: readonly ? () => null : onClick };
  switch (true) {
    case type.editor?.name === "Autocomplete":
      return (
        <>
          {loading ? (
            <div style={{ width: "100%" }}>
              <Skeleton
                style={{ flex: 1, minWidth: "100%", minHeight: "auto" }}
              />
            </div>
          ) : (
            <div className={defaultClass} {...sameActions}>
              {dataToShowReference ? (
                <>
                  {Array.isArray(dataToShowReference) ? (
                    <>{printStringArray(dataToShowReference)}</>
                  ) : (
                    <>{dataToShowReference}</>
                  )}
                </>
              ) : (
                <EmptyPlaceholder
                  nameDetail={nameDetail}
                  nameField={nameField}
                />
              )}
            </div>
          )}
        </>
      );
    case type.format === "date-time":
      return (
        <>
          <div className={defaultClass} {...sameActions}>
            {data ? (
              valueFormatted()
            ) : (
              <i className={clsx("fa-solid fa-plus", "icon-plus")}></i>
            )}
          </div>
        </>
      );
    case type.editor?.name === "WYSIWYGEditor":
      return (
        <div className={defaultClass} {...sameActions}>
          {data ? (
            <div
              dangerouslySetInnerHTML={{
                __html: data.toString(),
              }}
            />
          ) : (
            <EmptyPlaceholder nameDetail={nameDetail} nameField={nameField} />
          )}
        </div>
      );
    case type.editor?.name === "InputAsButtonEditor" ||
      type.editor?.name === "SelectAsButtonEditor":
      const { iconButton } = type.editor;
      const classWithIcon = iconButton ? "classWithIcon" : "";
      const iconAbsolute = iconButton ? "dynamic-editor-icon" : "";
      return (
        <div className={clsx(defaultClass)} {...sameActions}>
          {data !== undefined && data !== null && data !== "" ? (
            <CustomButton className={clsx(classWithIcon)} type={"editor"}>
              {data}
              {iconButton && (
                <i
                  className={clsx(iconAbsolute, iconButton.iconCode)}
                  style={{
                    color: iconButton.iconColor
                      ? `${iconButton.iconColor} !important`
                      : "#CCE5FF",
                  }}
                ></i>
              )}
            </CustomButton>
          ) : (
            <EmptyPlaceholder nameDetail={nameDetail} nameField={nameField} />
          )}
        </div>
      );
    case (type.editor?.name === "SelectEditor" ||
      type.editor?.name === "SelectCloseProcedureEditor") &&
      !type.editor?.notButtonStyle:
      const checkCustom = () => {
        switch (data) {
          case "interestAt": {
            return ECustomColor.RED;
          }
          case "waitAt": {
            return ECustomColor.GREY;
          }
          case "evaluationAt": {
            return ECustomColor.DARK;
          }
        }
      };
      const customColor =
        nameField === "auctionTermState" ? checkCustom() : null;
      return (
        <div className={clsx(defaultClass)} {...sameActions}>
          {data && data !== "" ? (
            <CustomButton
              className={clsx("SelectEditor", "button-select-extended")}
              type={"primary"}
              color={customColor}
              negative={checkCustom() === ECustomColor.DARK}
            >
              {type.editor?.translate
                ? intl.formatMessage({ id: data as string })
                : data}
            </CustomButton>
          ) : (
            <EmptyPlaceholder nameField={nameField} />
          )}
        </div>
      );
    default:
      return (
        <div className={defaultClass} {...sameActions}>
          {data && data !== "" ? (
            type.editor?.translate ? (
              intl.formatMessage({ id: data as string })
            ) : (
              data
            )
          ) : (
            <EmptyPlaceholder nameDetail={nameDetail} nameField={nameField} />
          )}
        </div>
      );
  }
};

export default DynamicEditor;
