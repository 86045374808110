import React, { useEffect, useState } from "react";
import { parseDashboardDetailsPath } from "../../../utils/helper";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { CombineReducersState } from "../../../redux/store";
import { useIntl } from "react-intl";
export default function BadgeAssessment({ props }) {
  const intl = useIntl();
  const location = useLocation();
  let pathstart = location.pathname;
  const { pathId, pathName } = parseDashboardDetailsPath(pathstart);
  const state = useSelector(
    (state: CombineReducersState) => state.detailsReducer
  );
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    const visitNeeded = state[pathName]?.[pathId]?.["form"]?.visitNeeded;
    if (visitNeeded) {
      setShow(visitNeeded);
    }
  }, [state]);
  enum visitStatus {
    "visita da fare" = "todovisit",
    "visita pianificata" = "plannedvisit",
    "visita effettuata" = "donevisit",
  }
  enum visitStatusColor {
    "visita da fare" = "#FFC107",
    "visita pianificata" = "#3699FF",
    "visita effettuata" = "#28C76F",
  }
  const renderBadge = () => {
    if (props.data) {
      return (
        <div
          className="customBadge"
          style={{ backgroundColor: visitStatusColor[props.data] }}
        >
          {intl.formatMessage({ id: visitStatus[props.data] })}
        </div>
      );
    } else {
      return <></>;
    }
  };
  return <>{show && renderBadge()}</>;
}
