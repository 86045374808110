const customStyles = {
  container: (defaultStyles) => ({
    ...defaultStyles,
    width: "100%",
  }),
  control: (defaultStyles) => ({
    ...defaultStyles,
    padding: "0px 15px",
    border: "1px solid #ececec",
    boxShadow: "none",
    minHeight: "35px",
    borderRadius: "6px",
  }),
  input: (defaultStyles) => ({
    ...defaultStyles,
    height: "auto",
  }),
  placeholder: () => ({
    display: "none",
  }),
  valueContainer: (defaultStyles) => ({
    ...defaultStyles,
    paddingLeft: "0px",
  }),
};
const customStyles2 = {
  container: (defaultStyles) => ({
    ...defaultStyles,
    width: "100%",
  }),
  control: (defaultStyles) => ({
    ...defaultStyles,
    padding: "0px 10px",
    border: "1px solid #ececec",
    boxShadow: "none",
    maxHeight: "30px",
    borderRadius: "6px",
  }),
  input: (defaultStyles) => ({
    ...defaultStyles,
    height: "auto",
    maxHeight:"30px"
  }),
  placeholder: () => ({
    display: "none",
  }),
  valueContainer: (defaultStyles) => ({
    ...defaultStyles,
    paddingLeft: "0px",
  }),
};
export { customStyles,customStyles2};
