import '../../sass/App.scss';
import logo from '../../icons/logo01machinery.svg';

function LoadingSplashScreen() {
  return (
    <div className="App" style={{
      height: '100vh', width: '100vw', 
      overflow: 'hidden', zIndex: 9999,
      top: 0, left: 0, position: 'fixed',
    }}>
      <header className="App-header">
        <div className='app-logo'>
          <img src={logo} className="app-img" alt="logo" />
          <div className="blob" />
        </div>
      </header>
    </div>
  );
}

export default LoadingSplashScreen;
