import Skeleton from "react-loading-skeleton";
import { useEffect, useMemo, useRef, useState } from "react";

import AsyncSelect from "react-select/async";
import { apiCall } from "../../utils/serviceCall";
import {
  HTTPMethod,
  getOptionsAsyncSelect,
  hydra,
  takeIdFromIri,
  typeOfObject,
  uniteValues,
} from "../../utils/helper";

import { customStyles } from "./styleReactSelect";
import { useIntl } from "react-intl";
import {useSelector} from "react-redux";
import {CombineReducersState} from "../../redux/store";
interface autocompleteProps {
  value: string | string[];
  setValue: (value: any) => void;
  iriList?: string | string[];
  customFilter?: string;
}

export default function UserAutocomplete({
  value,
  setValue,
  iriList,
  customFilter,
}: autocompleteProps) {
  const [loading, setLoading] = useState<boolean>(true);
  const [completeSelected, setcompleteSelected] = useState<
    typeOfObject | typeOfObject[] | null
  >(null);
  const timerRef = useRef<any>(null);
  const makeCall = useRef<boolean>(true);
  const [iriOfSelectElement, setIriOfSelectElement] = useState<
    string | string[]
  >(value);
  const path: string = "users";
  const intl = useIntl();
  const mainField: string | string[] = "fullName";
  const userData = useSelector(
      (state: CombineReducersState) => state.userReducer.userData
  )!;
  const group = userData.groupUser?.[0]?.group["@id"];

  interface selectValueStandard {
    value: string;
    label: string;
  }

  const initializeValue = (): void => {
    setLoading(true);
    try {
      getValue();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getOptions = (inputValue: string = "") => {
    return getOptionsAsyncSelect(inputValue, timerRef, async () => {
      const defaultParams = {'groupUser.group': group , 'active': true,  'exists[deletedAt]': false}
      const params =
        inputValue === "" ? { params: defaultParams } : { params: { ["firstName"]: inputValue, ...defaultParams } };
      try {
        const { data } = await apiCall(HTTPMethod.GET, `/${path}`, params);
        const response = data[hydra.MEMBER].map((element) =>
          computedList(element)
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    });
  };

  const computedList = (
    element
  ): selectValueStandard | selectValueStandard[] => {
    if (Array.isArray(element)) {
      if (element.length === 0) return [];
      return element.map((item) => ({
        value: customFilter ? item[customFilter] : item["@id"],
        label: uniteValues(item, mainField),
      }));
    } else {
      return (
        element && {
          value: customFilter ? element[customFilter] : element["@id"],
          label: uniteValues(element, mainField),
        }
      );
    }
  };
  const saveMultiIri = (elements) => {
    console.debug("Multi IRI", elements);
    const iriToSave = elements.map((element) => element.value);

    setIriOfSelectElement(iriToSave);
    setValue(iriToSave);
  };
  const getValue = async () => {
    const params = takeIdFromIri(iriOfSelectElement)
      ? { params: { id: takeIdFromIri(iriOfSelectElement) } }
      : {};
    const { data } = await apiCall(HTTPMethod.GET, `/${path}`, params);
    const selected = data[hydra.MEMBER];
    setcompleteSelected(selected);
  };
  const { computedSelectedValue } = useMemo(() => {
    return {
      computedSelectedValue: completeSelected
        ? computedList(completeSelected)
        : null,
    };
  }, [completeSelected]);
  useEffect(() => {
    if (Array.isArray(iriOfSelectElement) && iriOfSelectElement.length > 0) {
      initializeValue();
    } else {
      setcompleteSelected([]);
      if(iriList && Array.isArray(iriList) && iriList.length > 0){
        saveMultiIri(iriList);
      }
    }
    setLoading(false);
  }, [iriOfSelectElement, iriList]);


  return (
    <>
      {loading ? (
        <Skeleton />
      ) : (
        <>
          {" "}
          <AsyncSelect
            cacheOptions
            defaultOptions
            isMulti
            placeholder={intl.formatMessage({ id: "User" })}
            styles={customStyles}
            classNamePrefix="react-select"
            value={computedSelectedValue}
            onChange={(e: any) => {
              saveMultiIri(e);
            }}
            loadOptions={getOptions}
          />
        </>
      )}
    </>
  );
}
