import React, { useMemo } from "react";
import { CombineReducersState } from "../../redux/store";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { toAbsoluteUrl } from "../../vendor/_metronic/helpers";
interface INotificationIcon {
  style?: any;
  showNotification: boolean;
  toggleNotification: (value: boolean) => void;
}
export default function NotificationIcon({
  showNotification,
  toggleNotification,
  style = {},
}: INotificationIcon) {
  const { notification } = useSelector(
    (state: CombineReducersState) => state.notificationReducer
  );
  const notificationToReadNumber = useMemo(() => {
    return notification ? notification?.filter((el) => !el.isRead).length : 0;
  }, [notification]);
  return (
    <>
      <div
        onClick={() => toggleNotification(true)}
        className={clsx(
          "btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary",
          showNotification ? "active" : ""
        )}
        style={{
          position: "relative",
          right: "5px",
          top: "12px",
          cursor: "pointer",
          justifyContent: "center",
          textAlign: "center",
          alignItems: "center",
          ...style,
        }}
        id="kt_activities_toggle"
      >
        {!!notificationToReadNumber && notificationToReadNumber > 0 && <span
          style={{
            fontSize: "10px",
            position: "absolute",
            // padding: "2px",
            margin:'0 auto ',
            top: "45%",
            bottom: "23%",
            right: "3%",
            maxHeight: "17px",
            minWidth: "17px",
            lineHeight: "12px",
          }}
          className="badge badge-pill badge-danger"
        >
          {notificationToReadNumber}
        </span>}
        <img src={toAbsoluteUrl("/assets/notifications.svg")} />
        {notification && notification.some((el) => el.isRead === false) && (
          <span className="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink"></span>
        )}
      </div>
    </>
  );
}
