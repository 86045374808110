import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import {
  HTTPMethod,
  handleStore,
  parseDashboardDetailsPath,
} from "../../../utils/helper";
import {
  DetailsState,
  actionsTypeDetails,
} from "../../../redux/stores/details/detailsReducer";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { CombineReducersState } from "../../../redux/store";
import { apiCall } from "../../../utils/serviceCall";
export default function CheckBoxEditor({ props }) {
  const [data, setData] = useState<boolean>(props.data);
  const { path, store, field } = props.type.editor;
  const updateByItself: string | boolean = path ? "makeGet" : false;
  const state = useSelector(
    (state: CombineReducersState) => state.detailsReducer
  );
  // location
  const location = useLocation();
  let pathstart = location.pathname;
  const { pathId, pathName } = parseDashboardDetailsPath(pathstart);

  //store
  const dispatch = useDispatch();
  let structureStore: DetailsState = {
    [pathName]: {
      [pathId]: {
        form: {},
      },
    },
  };
  let prevStore: DetailsState = state;
  // const handleStore = (value: boolean) => {
  //   if (store) {
  //     let stateToSave;
  //     if (prevStore?.[pathName]?.[pathId]?.["form"]) {
  //       structureStore[pathName][pathId]["form"] = {
  //         ...prevStore?.[pathName]?.[pathId]?.["form"],
  //         [props.name]: value,
  //       };
  //       stateToSave = structureStore;
  //     } else {
  //       structureStore[pathName][pathId]["form"] = {
  //         [props.name]: value,
  //       };
  //       stateToSave = structureStore;
  //     }
  //     dispatch({
  //       type: actionsTypeDetails.set,
  //       payload: stateToSave,
  //     });
  //   }
  // };

  useEffect(() => {
    handleStore(data, {
      pathName,
      pathId,
      formField: props.name,
    });
    // handleStore(data);
  }, [data]);

  const toogleSaveChange = async (value) => {
    setData(value);
    if (path) {
      // setLoading(true);
      try {
        await apiCall(HTTPMethod.POST, `/${pathName}/${pathId}/${path}`, {
          [field]: value,
        });
      } catch (error) {
        console.log(error);
        return;
      } finally {
        // setLoading(false);
      }
    }
    props.setValue(props.name, value, updateByItself);
    handleStore(data, {
      pathName,
      pathId,
      formField: props.name,
    });
  };
  useEffect(() => {
    if (data !== props.data) {
      setData(props.data);
    }
  }, [props.data]);
  return (
    <div>
      <input
        className="form-check-input p-1"
        type="checkbox"
        onChange={(e) => toogleSaveChange(e.target.checked)}
        checked={data}
      />
    </div>
  );
}
