import React, { FC, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { KTIcon } from "../../vendor/_metronic/helpers";
import { FormattedMessage } from "react-intl";
import { apiCall } from "../../utils/serviceCall";
import { HTTPMethod } from "../../utils/helper";

interface ColumnSelectedInterface {
    selected: boolean;
    name: string;
    id: string;
    order: number;
}

interface ColumnFilterDraggableProps {
    columns: {},
    loading: boolean,
    entity: any,
    columnsSelected: ColumnSelectedInterface[],
    path:string
}

const ColumnFilterDraggable: FC<ColumnFilterDraggableProps> = ({ columns, loading, entity, columnsSelected,path }) => {
    const [columnsList, updateColumns] = useState(columnsSelected);
    const [columnsSelectedList, updateColumnsSelected] = useState(columnsSelected);

    useEffect(() => {
        if (columnsSelected && columnsSelected.length) {
            updateColumns(columnsSelected)
        } else {
            /*

            @todo
            Questo è da sistemare, se non ho impostato le preferenze.
            Tutta la SETTINGS sarebbe da mettere nello STORE, altrimenti è un casino gestirne i cambiamenti da un punto all'altro!
   */

            let columnsArray: ColumnSelectedInterface[] = [];
            if (columns && Object.values(columns) ) {
                columnsArray = (Object.keys(columns))
                    .filter((item) => (!item.startsWith("@")))
                    .map((item, index) => ({
                        id: index.toString(),
                        name: item,
                        selected: true,
                        order: index
                    }))

                updateColumns(columnsArray)
            }
                  


        }
    }, [columns, columnsSelected]);

    function handleOnDragEnd(result) {
        if (!result.destination) return;

        const items = Array.from(columnsList);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        updateColumns(items);
    }

    const handleOnChange = (event, option, index) => {
        const values = [...columnsList];
        values[index].selected = event.target.checked;
        updateColumns(values);

    };

    function addDragAndDrop() {
        if (!columnsList) {
            return <FormattedMessage id={`MAIN.Caricamento in corso`} />
        }
        return <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="columnsList">
                {(provided) => (
                    <ul className="columnsList" {...provided.droppableProps} ref={provided.innerRef}>
                        {columnsList.map((item, index) => {
                            return (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided) => (
                                        <li className="p-1 m-1"
                                            ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                            <div
                                                className="d-flex p-1 gap-2 align-items-center">
                                                <input
                                                    className='form-check-input p-1'
                                                    type='checkbox'
                                                    checked={item.selected}
                                                    onChange={(e) => handleOnChange(e, item, index)}
                                                />
                                                <p className="m-0"><FormattedMessage
                                                    id={`${entity?.tag}.${item.name}`} />
                                                </p>
                                            </div>
                                        </li>
                                    )}
                                </Draggable>
                            );
                        })}
                        {provided.placeholder}
                    </ul>
                )}
            </Droppable>
        </DragDropContext>
    }

    async function saveColumnList(e) {
        e.stopPropagation();

        await apiCall(HTTPMethod.PATCH, '/settings/' + path, {
            'list': columnsList.map(
                (item, index) => ({
                    id: index.toString(),
                    name: item.name,
                    selected: item.selected,
                    order: index
                }))
        });

        document.getElementById('reset-column')?.click()
        window.location.reload()
    }

    return (<>
        <button
            type='button'
            className='btn btn-light-primary me-3'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
        >
            <KTIcon iconName='filter' className='fs-2' />
        </button>
        <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px'  data-kt-menu='true'>
            <div className='px-7 py-5' style={{height:'500px',overflow:'scroll'}}>
                <div className='fs-5 text-dark fw-bolder'>
                    <p><FormattedMessage id="MAIN.Seleziona le colonne da visualizzare" /></p>
                </div>
                <div className='separator border-gray-200'></div>
                <div className='py-5' data-kt-user-table-filter='form'>
                    <div className='mb-10'>
                        <p><FormattedMessage
                            id="MAIN.Scegli quali colonne vuoi visualizzare e il loro ordinamento" /></p>

                    </div>
                    {addDragAndDrop()}
                    <div className='d-flex justify-content-end'>
                        <button
                            id="reset-column"
                            type='button'
                            className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                            data-kt-menu-dismiss='true'
                            data-kt-user-table-filter='reset'
                        >
                            <FormattedMessage
                                id="MAIN.Annulla" />
                        </button>
                        <button
                            type='button'
                            className='btn btn-primary fw-bold px-6'
                            data-kt-menu-dismiss='true'
                            data-kt-user-table-filter='filter'
                            onClick={saveColumnList}
                        >
                            <FormattedMessage
                                id="MAIN.Salva" />

                        </button>
                    </div>
                </div>
            </div>

        </div>
    </>
    );

}
export default ColumnFilterDraggable;