import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { CombineReducersState, store } from "../../../redux/store";
import {
  MessagesReducerTypeActions,
  MessageTypeEnum,
} from "../../../redux/stores/message/messageReducer";
import {
  excludeToObjIfIsReadOnly,
  HTTPMethod,
  createYupSchema,
  parseDashboardDetailsPath,
  excludeToObjectThisKeys,
} from "../../../utils/helper";
import { apiCallFile, apiCall } from "../../../utils/serviceCall";
import CustomButton from "../../buttons/CustomButton";
import * as yup from "yup";
import { parseDataFromGlobalContext } from "../../../redux/stores/rootContext";
import { Field } from "../../FormModal/Field";
import { useLocation } from "react-router-dom";
interface fieldSchema {
  type: null | string;
  attributes: string[];
}

export default function AssessmentFormEditor({ show, updateFather, props }) {
  const globalState = store.getState();
  const allEntity = parseDataFromGlobalContext(globalState.rootContext.apiDocs);
  const [forceUpdate, setForceUpdate] = useState<number>(0);
  const entity = allEntity
    ? allEntity.find((entity) => entity.name === "assessments")
    : null;
  const fieldsWithoutReadonly =
    entity?.fields && excludeToObjIfIsReadOnly(entity.fields);
  const fields = excludeToObjectThisKeys(fieldsWithoutReadonly, [
    "companyAssestments",
  ]);
  const location = useLocation();
  let pathstart = location.pathname;
  const { pathId, pathName } = parseDashboardDetailsPath(pathstart);
  const intialForm =
    pathId && pathName
      ? { companyAssestments: `/api/${pathName}/${pathId}` }
      : {};
  const didMount = useRef<boolean>(false);
  const name = entity?.name;
  const [form, setForm] = useState<any>(intialForm);
  const intl = useIntl();
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [disableStatusButton, setDisableStateButton] = useState<boolean>(false);
  const path = "/assessments";
  const pathImage = "/attachments";
  const [textInput, setTextInput] = useState<string>(
    "Trascina i file o premi Scegli File"
  );
  const userData = useSelector(
    (state: CombineReducersState) => state.userReducer.userData
  )!;
  const dispatch = useDispatch();
  const handleUpdate = (nameInput: string, value: any) => {
    setForm({ ...form, ...{ [nameInput]: value } });
  };

  const makePost = async () => {
    //Chiamata per caricare file di assassments
    if (form.file) {
      form.file.forEach(async (element, index) => {
        const formData = new FormData();
        formData.append("typology", "file");
        formData.append("company", form.companyAssestments);
        formData.append("user", userData["@id"]);
        for (const key in form) {
          if (key === "file") {
            if (form[key].length === 1) {
              formData.append(key, form[key][0]);
            } else {
              formData.append(key, element.file);
            }
          }
        }
        await apiCallFile(HTTPMethod.POST, `${pathImage}`, formData)
          .then((response) => {
            dispatch({
              type: MessagesReducerTypeActions.SET,
              payload: {
                type: MessageTypeEnum.SUCCESS,
                message: intl.formatMessage({
                  id: "Attachments.addSuccess",
                }),
              },
            });
          })
          .catch((error) => {
            dispatch({
              type: MessagesReducerTypeActions.SET,
              payload: {
                type: MessageTypeEnum.DANGER,
                message: intl.formatMessage({
                  id: "Attachments.addError",
                }),
              },
            });
          });
      });
    }

    // // Chiamata per creare un nuovo assessments
    // await apiCall(HTTPMethod.POST, path, form)
    //   .then(() => {
    //     resetForm();

    //     dispatch({
    //       type: MessagesReducerTypeActions.SET,
    //       payload: {
    //         type: MessageTypeEnum.SUCCESS,
    //         message: intl.formatMessage({
    //           id: "Attachments.addSuccess",
    //         }),
    //       },
    //     });
    //   })
    //   .catch((error) => {
    //     dispatch({
    //       type: MessagesReducerTypeActions.SET,
    //       payload: {
    //         type: MessageTypeEnum.DANGER,
    //         message: intl.formatMessage({
    //           id: "Attachments.addError",
    //         }),
    //       },
    //     });
    //     console.log(error);
    //   });
    const objToSend = { companyAssestments: `/api/${pathName}/${pathId}` };
    if (!props.data) {
      try {
        await apiCall(HTTPMethod.POST, `${path}`, { ...form, ...objToSend });
        dispatch({
          type: MessagesReducerTypeActions.SET,
          payload: {
            type: MessageTypeEnum.SUCCESS,
            message: intl.formatMessage({ id: "SaveSuccess" }),
          },
        });
      } catch (error) {
        dispatch({
          type: MessagesReducerTypeActions.SET,
          payload: {
            type: MessageTypeEnum.DANGER,
            message: intl.formatMessage({ id: "SaveError" }),
          },
        });
      }
    } else {
      const idToChange = props.data.split("/").pop();
      try {
        await apiCall(HTTPMethod.PATCH, `/${path}/${idToChange}`, {
          ...form,
          ...objToSend,
        });
      } catch (error) {
        console.error(error);
      }
    }
  };
  useEffect(() => {
    if (props.data) {
      getValue();
    }
  }, [props.data]);
  const getValue = async () => {
    const id = props.data.split("/").pop();
    try {
      const { data } = await apiCall(HTTPMethod.GET, `/${path}/${id}`);
      setForm(data);
    } catch (err) {
      console.log(err);
    }
  };
  const createYupSpecific = (fields: { [key: string]: fieldSchema }): any => {
    let result = Object.keys(fields)
      .map((fieldkey) => {
        let field: any = fields[fieldkey];
        let arrayOfControlls: fieldSchema = { type: null, attributes: [] };
        if (field.type) {
          arrayOfControlls.type = field.type;
        }

        if (field.required) {
          arrayOfControlls.attributes.push("required");
        }
        return { [fieldkey]: arrayOfControlls };
      })
      .reduce((acc, obj) => ({ ...acc, ...obj }), {});
    return result;
  };

  const schema = yup.object().shape(createYupSchema(createYupSpecific(fields)));

  const checkValidation = () => {
    schema
      .validate(form, { abortEarly: false })
      .then(() => {
        setDisableStateButton(false);
        setFormErrors({});
      })
      .catch((validationErrors: yup.ValidationError) => {
        setDisableStateButton(true);
        parsedErrorForm(validationErrors);
      });
  };
  const parsedErrorForm = (errors: yup.ValidationError) => {
    const parsedErrors = errors.inner.reduce((errorList, error) => {
      if (error.path) {
        errorList[error.path] = error.message;
      }
      return errorList;
    }, {});
    setFormErrors(parsedErrors);
  };

  useEffect(() => {
    if (didMount.current) {
      checkValidation();
    } else {
      didMount.current = true;
    }
  }, [form]);

  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files: FileList = e.target.files;

      if (files.length === 1) {
        const file = files[0];
        setForm((prevState) => ({
          ...prevState,
          file: [file],
        }));
        setTextInput(`Hai caricato ${files.length} file`);
      } else {
        const fileArray = Array.from(files).map((file) => ({ file }));
        fileArray.forEach((element) => {
          setForm((prevState) => ({
            ...prevState,
            file: [...(prevState.files || []), ...fileArray],
          }));
        });
        setTextInput(`Hai caricato ${files.length} file`);
      }
    }
  };

  const resetForm = () => {
    setForm(intialForm);
    setForceUpdate(forceUpdate + 1);
    setTextInput("Trascina i file o premi Scegli File");
  };

  return (
    <>
      <div key={forceUpdate} className="modal-content">
        <div>
          {fields &&
            Object.keys(fields).map((fieldName, key) => {
              const fieldInfo = fields[fieldName];
              return (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className="input-form-field"
                  key={`${fieldName}-${key}`}
                >
                  <Field
                    required={fields[fieldName].required}
                    nameEntity={name!}
                    formErrors={formErrors}
                    name={fieldName}
                    value={form[fieldName]}
                    output={handleUpdate}
                    fieldInfo={fieldInfo}
                    id={key}
                  ></Field>
                </div>
              );
            })}
          <div className="d-flex justify-content-between align-items-center">
            <input
              type="file"
              id="fileInput"
              style={{
                color: "transparent",
                position: "relative",
                backgroundColor: "#f1faff",
                border: "1px dashed #3E97FF",
                width: "400px",
              }}
              name="file"
              onChange={(e) => handleFileInputChange(e)}
              multiple
            ></input>
            <label
              htmlFor="fileInput"
              style={{
                position: "relative",
                border: "none",
                cursor: "pointer",
                zIndex: "1",
              }}
            >
              {textInput}
            </label>
          </div>
        </div>
        <div className="py-5 d-flex justify-content-end">
          <CustomButton disable={disableStatusButton} onClick={makePost}>
            {intl.formatMessage({ id: "MAIN.Salva" })}
          </CustomButton>
        </div>
      </div>
    </>
  );
}
