import { removeDuplicateObjects } from "../../../utils/helper";

export interface ReferenceState {
    [key: string]: any[]
}
const initialReferenceState: ReferenceState = {}
export enum actionsTypeReference {
    new = 'NEW_REFERENCE_STATE',
    update = 'UPDATE_REFERENCE_STATE'
}
interface payloadReference {
    name: string,
    value: any
}
export interface ReferenceReducerAction {
    type: actionsTypeReference,
    payload: payloadReference

}
function implementsPayloadReference(obj: any): obj is payloadReference {
    return 'name' in obj && 'value' in obj;
}
export default function referenceReducer(state: ReferenceState = initialReferenceState, action: ReferenceReducerAction): ReferenceState {
    const payload = action.payload
    if (payload && implementsPayloadReference(payload)) {
        const name = action.payload.name
        const value = action.payload.value

        switch (action.type) {

            case actionsTypeReference.new:
                if (!state[name]) {
                    return {
                        ...state,
                        ...{ [name]: [] }
                    }
                } else {
                    return state
                }

            case actionsTypeReference.update:
                return {
                    ...state,
                    ...{ [name]: removeDuplicateObjects([...state[name], ...value]) }
                }
            default:
                return state
        }
    } else {
        return state
    }
}