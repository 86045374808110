import clsx from "clsx";
import React, { FC, useEffect, useRef } from "react";
import { hexToRgb } from "../../utils/helper";
interface CustomButtonProps {
  disable?: boolean;
  className?: string;
  children?: React.ReactNode;
  style?: { [key: string]: string };
  type?: string;
  onClick?: (e) => void;
  loading?: boolean;
  color?: null | string;
  negative?: boolean;
}

const CustomButton: FC<CustomButtonProps> = ({
  disable = false,
  children,
  type = "primary",
  onClick = null,
  className = "",
  loading = false,
  color = null,
  negative = false,
}) => {
  const elementRef = useRef<HTMLButtonElement>(null);
  const disableClass: string = disable ? "button-disabled-extended" : "";
  const classCustomColor = color ? negative ? 'button-custom-negative' : "button-custom-color" : "";

  useEffect(() => {
    const currentElemnt = elementRef.current;
    if (currentElemnt && color) {
      currentElemnt.style.setProperty("--custom-color", hexToRgb(color));
    }
  }, [elementRef.current, color]);
  return (
    <button
      ref={elementRef}
      className={clsx(
        "button-normalize",
        `button-${type}-extended`,
        classCustomColor,

        disableClass,
        className
      )}
      onClick={(e) => onClick && !disable && !loading && onClick(e)}
    >
      {loading ? (
        <span className="indicator-progress" style={{ display: "block" }}>
          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
      ) : (
        children && children
      )}
    </button>
  );
};

export default CustomButton;
