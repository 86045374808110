import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { CombineReducersState } from "../../../redux/store";
import { parseDashboardDetailsPath } from "../../../utils/helper";
import { useIntl } from "react-intl";

export default function CuratorsPositionCodeEditor({ props }) {
  const state = useSelector(
    (state: CombineReducersState) => state.detailsReducer
  );
  const location = useLocation();
  let pathstart = location.pathname;
    const { pathId, pathName } = parseDashboardDetailsPath(pathstart);
  const intl = useIntl();
  return (
    <div className="readonly">
      {state?.[pathName]?.[pathId]?.["form"]?.["curators"]
        ? state[pathName][pathId]['form']["curators"]?.positionCode && intl.formatMessage({ id: `CuratorTypology.${state[pathName][pathId]['form']["curators"]?.positionCode}` })
        : ""}
    </div>
  );
}
