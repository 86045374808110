import React, { useEffect, useState } from "react";
import { apiCall } from "../../../utils/serviceCall";
import { HTTPMethod, parseDashboardDetailsPath } from "../../../utils/helper";
import { useLocation } from "react-router";
import OutsideClickWrapper from "../../OutsideWrapper/OutsideWrapper";
import { useIntl } from "react-intl";
import clsx from "clsx";

interface SelectEditorProps {
  className?: string;
  props: {
    name: string;
    type: {
      type: string;
      editor: {
        default?: string;
        translate?: string;
        options: Record<string, string>;
        path?: string;
        field?: string;
      };
    };
    data: string;
    setValue: (name: string, value: string, updateByItself?: boolean) => void;
    setShowInput: (show: boolean) => void;
  };
}

export default function SelectEditor({
  className = "",
  props,
}: SelectEditorProps) {
  const defaultValue = props.type?.editor?.default;
  const location = useLocation();
  const translate: string | undefined = props.type.editor.translate;
  const [data, setData] = useState(defaultValue ? defaultValue : props.data);
  const { type, editor } = props.type;
  const { options, path, field } = editor;
  const updateByItself: boolean = path ? true : false;
  const intl = useIntl();
  let pathstart = location.pathname;

  const { pathId, pathName } = parseDashboardDetailsPath(pathstart);
  const toogleSaveChange = async (value) => {
    let updateValue = value;

    if (type === "number" || type.includes("number")) {
      updateValue = +updateValue;
    }
    if (path) {
      try {
        await apiCall(HTTPMethod.POST, `/${pathName}/${pathId}/${path}`, {
          [field!]: updateValue,
        });
      } catch (error) {
        return console.error(error.message);
      }
    }
    props.setValue(props.name, updateValue, updateByItself);
    props.setShowInput(false);
  };

  return (
    <OutsideClickWrapper
      onClickOutside={() => {
        props.setShowInput(false);
      }}
    >
      <select
        className={clsx("form-select", className)}
        value={data}
        onChange={(e) => {
          toogleSaveChange(e.target.value);
        }}
      >
        {options &&
          Object.keys(options).map((key) => (
            <option value={key} key={`${props.name}-option-${key}`}>
              {translate ? (
                <>
                  {intl.formatMessage({ id: `${translate}.${options[key]}` })}
                </>
              ) : (
                options[key]
              )}
            </option>
          ))}
      </select>
    </OutsideClickWrapper>
  );
}
