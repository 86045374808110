import React, { FC, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";


import { apiCall } from "../../utils/serviceCall";
import * as yup from 'yup'
import CustomButton from "../buttons/CustomButton";
import Autocomplete from "../Input/Autocomplete";
import { excludeToObjIfIsReadOnly, createYupSchema, HTTPMethod } from "../../utils/helper";
import { useIntl } from "react-intl";
import TagifyWraper from "../TagifyWrapper/TagifyWrapper";
import { Field } from "./Field";
import { useDispatch } from "react-redux";
import { MessagesReducerTypeActions, MessageTypeEnum } from "../../redux/stores/message/messageReducer";
interface FormModalProps {
    entity: any,
    type: 'put' | 'post' | 'patch'
    data?: {} | { [key: string]: any },
    show: boolean,
    customPath?: string,
    handleClose: () => void
}
interface fieldProps {
    value: any,
    output: (name: string, value: any) => void,
    fieldInfo: any,
    name: string,
    nameEntity: string,
    formErrors: { [key: string]: string }
}

interface fieldSchema {
    type: null | string, attributes: string[]
}


const FormModal: FC<FormModalProps> = ({ entity, type, data = {}, customPath, show, handleClose }) => {
    const didMount = useRef<boolean>(false)
    const intl = useIntl()
    const dispatch = useDispatch()
    const createYupSpecific = (fields: { [key: string]: fieldSchema }): any => {
        let result = Object.keys(fields).map(fieldkey => {
            let field: any = fields[fieldkey]
            let arrayOfControlls: fieldSchema = { type: null, attributes: [] }
            if (field.type) {
                arrayOfControlls.type = field.type
            }

            if (field.required) {
                arrayOfControlls.attributes.push('required')
            }
            return { [fieldkey]: arrayOfControlls }
        }).reduce((acc, obj) => ({ ...acc, ...obj }), {});
        return result
    }
    const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({})
    const [disableStatusButton, setDisableStateButton] = useState<boolean>(true)
    const fields = excludeToObjIfIsReadOnly(entity.fields)
    const schema = yup.object().shape(createYupSchema(createYupSpecific(fields)))
    const checkValidation = () => {

        schema.validate(form, { abortEarly: false })
            .then(() => {
                setDisableStateButton(false)
                setFormErrors({})
            })
            .catch((validationErrors: yup.ValidationError) => {
                setDisableStateButton(true)
                parsedErrorForm(validationErrors)

            });
    }
    const parsedErrorForm = (errors: yup.ValidationError) => {
        const parsedErrors = errors.inner.reduce((errorList, error) => {
            if (error.path) {
                errorList[error.path] = error.message
            }
            return errorList
        }, {}
        )
        setFormErrors(parsedErrors)
    }

    const name = entity.name
    const path = customPath ? customPath : `/${name}`
    const [form, setForm] = useState<any>(data)

    useEffect(() => {
        if (type !== 'post') {
            setForm(data)
        }
    }, [])
    useEffect(() => {

        if (didMount.current) {
            checkValidation()
        } else {
            didMount.current = true
        }

    }, [form])
    const handleUpdate = (nameInput: string, value: any) => {
        setForm({ ...form, ...{ [nameInput]: value } })
    }

    const makePost = async () => {
        await apiCall(HTTPMethod.POST, path, form).then(() => {
            handleClose()
            dispatch({
                type: MessagesReducerTypeActions.SET, payload: {
                    type: MessageTypeEnum.SUCCESS,
                    message: intl.formatMessage({
                        id: 'Create.success'
                    })
                }
            })
        }).catch(error => {
            dispatch({
                type: MessagesReducerTypeActions.SET, payload: {
                    type: MessageTypeEnum.SUCCESS,
                    message: intl.formatMessage({
                        id: 'Create.error'
                    })
                }
            })
            console.log(error)
        })
    }

    return <>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton />
            <Modal.Body>
                {fields && Object.keys(fields).map((fieldName, key) => {
                    const fieldInfo = fields[fieldName]
                    return <div className='input-form-field' key={`${fieldName}-${key}`}>
                        <Field required={fields[fieldName].required}  nameEntity={name} formErrors={formErrors} name={fieldName} value={form[fieldName]} output={handleUpdate} fieldInfo={fieldInfo} ></Field>
                    </div>
                })}
            </Modal.Body>
            <Modal.Footer>
                <CustomButton disable={disableStatusButton} onClick={makePost}>
                    {intl.formatMessage({ id: 'MAIN.Salva' })}
                </CustomButton>
            </Modal.Footer>
        </Modal >
    </>

};

export default FormModal