import React, { FC, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";

import { apiCall } from "../../utils/serviceCall";
import * as yup from "yup";
import CustomButton from "../buttons/CustomButton";
import {
  excludeToObjIfIsReadOnly,
  createYupSchema,
  HTTPMethod,
  hydra,
  parseDashboardDetailsPath,
  takeIdFromIri,
  handleStore,
} from "../../utils/helper";
import { useIntl } from "react-intl";
import { Field } from "./Field";
import { useDispatch } from "react-redux";
import {
  MessagesReducerTypeActions,
  MessageTypeEnum,
} from "../../redux/stores/message/messageReducer";
import clsx from "clsx";
import { CombineReducersState } from "../../redux/store";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
interface EmailModalProps {
  entity: any;
  type: "put" | "post" | "patch";
  data?: {} | { [key: string]: any };
  show: boolean;
  customPath?: string;
  handleClose: () => void;
}
interface fieldProps {
  value: any;
  output: (name: string, value: any) => void;
  fieldInfo: any;
  name: string;
  nameEntity: string;
  formErrors: { [key: string]: string };
}

interface fieldSchema {
  type: null | string;
  attributes: string[];
}

const EmailModal: FC<EmailModalProps> = ({
  entity,
  type,
  data = {},
  customPath,
  show,
  handleClose,
}) => {
  const didMount = useRef<boolean>(false);
  const intl = useIntl();
  const dispatch = useDispatch();
  const { userData } = useSelector(
    (state: CombineReducersState) => state.userReducer
  );
  const location = useLocation();
  let pathstart = location.pathname;
  const hash = location.hash;
  const { pathId, pathName, hashId, hashName } = parseDashboardDetailsPath(
    pathstart,
    hash
  );
  const idOfCurator = pathName?.includes("curators")
    ? pathId
    : hashName?.includes
    ? hashId
    : null;
  const listGroupUser = userData?.groupUser.map((el) => el.group.id);
  const createYupSpecific = (fields: { [key: string]: fieldSchema }): any => {
    let result = Object.keys(fields)
      .map((fieldkey) => {
        let field: any = fields[fieldkey];
        let arrayOfControlls: fieldSchema = { type: null, attributes: [] };
        if (field.type) {
          arrayOfControlls.type = field.type;
        }

        if (field.required) {
          arrayOfControlls.attributes.push("required");
        }
        return { [fieldkey]: arrayOfControlls };
      })
      .reduce((acc, obj) => ({ ...acc, ...obj }), {});
    return result;
  };
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [disableStatusButton, setDisableStateButton] = useState<boolean>(true);
  const fields = excludeToObjIfIsReadOnly(entity.fields);
  const schema = yup.object().shape(createYupSchema(createYupSpecific(fields)));
  const checkValidation = () => {
    schema
      .validate(form, { abortEarly: false })
      .then(() => {
        setDisableStateButton(false);
        setFormErrors({});
      })
      .catch((validationErrors: yup.ValidationError) => {
        setDisableStateButton(true);
        parsedErrorForm(validationErrors);
      });
  };
  const parsedErrorForm = (errors: yup.ValidationError) => {
    const parsedErrors = errors.inner.reduce((errorList, error) => {
      if (error.path) {
        errorList[error.path] = error.message;
      }
      return errorList;
    }, {});
    setFormErrors(parsedErrors);
  };

  const name = entity.name;
  const path = customPath ? customPath : `/${name}`;
  const [form, setForm] = useState<any>(data);
  const [showModalTemplate, setShowModalTemplate] = useState<boolean>(false);
  const [listTemplate, setListTemplate] = useState<{ [key: string]: any }[]>();
  const [forceUpdate, setForceUpdate] = useState<number>(0);
  const [proceduresLink, setProceduresLink] = useState<{ [key: string]: any }>(
    {}
  );
  const [selectedTamplete, setSelectedTemplate] = useState<{
    [key: string]: any;
  }>();
  const takeModelTemplate = async () => {
    await apiCall(HTTPMethod.GET, `/mail_models`, {
      params: { ["group.id"]: listGroupUser },
    }).then((response) => {
      const { data } = response;
      setListTemplate(data[hydra.MEMBER]);
    });
  };
  const getCompanyId = async () => {
    await apiCall(HTTPMethod.GET, `/curator_companies`, {
      params: { curator: idOfCurator, itemsPerPage: 100 },
    }).then(async (response) => {
      const data = response.data;
      const listOfIdCompanies = data[hydra.MEMBER].map((el) =>
        takeIdFromIri(el.companies)
      );
      await apiCall(HTTPMethod.GET, `/companies`, {
        params: {
          id: listOfIdCompanies,
          itemsPerPage: listOfIdCompanies.length,
        },
      }).then(({ data }) => {
        handleStore(data[hydra.MEMBER], {
          pathName: "curators",
          pathId: idOfCurator,
          formField: "listResponse",
        });
      });
    });
  };
  useEffect(() => {
    takeModelTemplate();
    getCompanyId();
  }, []);
  useEffect(() => {
    if (selectedTamplete) {
      setForm((prev) => ({ ...prev, message: selectedTamplete.content }));
      setForceUpdate(forceUpdate + 1);
    }
  }, [selectedTamplete]);
  useEffect(() => {
    if (proceduresLink.name) {
      setForceUpdate(forceUpdate + 1);
    }
  }, [proceduresLink]);

  useEffect(() => {
    if (didMount.current) {
      checkValidation();
    } else {
      didMount.current = true;
    }
  }, [form]);
  const handleUpdate = (nameInput: string, data: any) => {
    let valueToSave = data;
    let formToSave = Object.assign({}, form);
    if (nameInput === "proceduresLink") {
      valueToSave = data.value.id.toString();
      formToSave.subject = data.value.name;
      setProceduresLink(data.value);
    }
    formToSave[nameInput] = valueToSave;
    setForm((prev) => ({ ...prev, ...formToSave }));
  };

  const makePost = async () => {
    setLoading(true);

    await apiCall(HTTPMethod.POST, path, form)
      .then((res) => {
        dispatch({
          type: MessagesReducerTypeActions.SET,
          payload: {
            type: MessageTypeEnum.SUCCESS,
            message: intl.formatMessage({
              id: "SendEmail.success",
            }),
          },
        });
        handleClose();
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: MessagesReducerTypeActions.SET,
          payload: {
            type: MessageTypeEnum.DANGER,
            message: intl.formatMessage({
              id: "SendEmail.error",
            }),
          },
        });
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <Modal
        dialogClassName="emailModal"
        animation={false}
        show={show}
        onHide={() => {
          handleClose();
          setShowModalTemplate(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title-lg">
            Scrivi Email
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {fields &&
            Object.keys(fields).map((fieldName, key) => {
              const fieldInfo = fields[fieldName];
              return (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className="input-form-field"
                  key={`${fieldName}-${key}`}
                >
                  <Field
                    id={key}
                    required={fields[fieldName].required}
                    key={forceUpdate}
                    nameEntity={name}
                    formErrors={formErrors}
                    accordion={false}
                    name={fieldName}
                    value={
                      fieldName === "proceduresLink"
                        ? proceduresLink
                        : form[fieldName]
                    }
                    output={handleUpdate}
                    fieldInfo={fieldInfo}
                  ></Field>
                  {fieldName === "message" && listTemplate && (
                    <div className="mt-5 d-flex gap-4 align-items-center">
                      <div
                        className="templateEmailIcon"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowModalTemplate(true);
                        }}
                      >
                        <i className="fa-regular fa-file-lines"></i>
                      </div>
                      <p
                        style={{
                          color: "black",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: "500",
                          lineHeight: "normal",
                          marginBottom: "0px",
                        }}
                      >
                        {intl.formatMessage({ id: "templateButtonLabel" })}
                      </p>
                    </div>
                  )}
                </div>
              );
            })}
        </Modal.Body>
        <Modal.Footer>
          <CustomButton
            loading={loading}
            disable={disableStatusButton}
            onClick={makePost}
          >
            {intl.formatMessage({ id: "MAIN.Invia" })}
          </CustomButton>
        </Modal.Footer>
      </Modal>
      <Modal
        animation={false}
        show={showModalTemplate}
        onHide={() => {
          setShowModalTemplate(false);
        }}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="listEmailTemplate"
          >
            <h3>{intl.formatMessage({ id: "templateModalAction" })}</h3>
            <div className="d-flex flex-column listContent">
              {listTemplate &&
                listTemplate.map((template, idx) => (
                  <div
                    key={`template-${idx}`}
                    className={clsx(
                      "templateEmail",
                      template["@id"] === selectedTamplete?.["@id"]
                        ? "templateSelected"
                        : ""
                    )}
                    onClick={() => {
                      setSelectedTemplate(template);
                      setShowModalTemplate(false);
                    }}
                  >
                    <h4>{template.name}</h4>
                    <p>{template.description}</p>
                  </div>
                ))}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EmailModal;
