import { SettingDetail } from "../../../typings/dn";

// details:{
// company:{
//     '88446':{ form{
//         visitNeed: true
//     }}
// }
// }
export interface DetailsState {
  [entity: string]: {
    [id: number]: {
      form: {
        [field: string]: any;
      };
      numberElement?: {
        [field: string]: number | null;
      };
    };
  };
}
const initialDetailsState: DetailsState = {};
export enum actionsTypeDetails {
  set = "SET_DETAILS_STATE",
  numberElement = "SET_NUMBER_ELEMENT",
  newSet = "NEW_SET_DETAILS_STATE",
  clean = "CLEAN_DETAILS_STATE",
}
export interface DetailNumber {
  pathName: string;
  pathId: string;
  nameField: string;
  value: number | null;
}
export interface DetailsReducerAction {
  type: actionsTypeDetails;
  payload: DetailsState | DetailNumber;
}
const checkIteretion = (value) => {
  return value || {};
};
export default function detailsReducer(
  state: DetailsState = initialDetailsState,
  action: DetailsReducerAction
): DetailsState {
  switch (action.type) {
    case actionsTypeDetails.set:
      return {
        ...state,
        ...(action.payload as DetailsState),
      };
    case actionsTypeDetails.newSet: {
      const { pathName, pathId, value, nameField } =
        action.payload as DetailNumber;
      if (!pathName || !pathId || typeof value === 'undefined'|| !nameField) {
        return { ...state };
      }
      return {
        ...state,
        [pathName]: {
          ...checkIteretion(state[pathName]),
          [pathId]: {
            ...checkIteretion(state[pathName]?.[pathId]),
            form: {
              ...checkIteretion(state[pathName]?.[pathId]?.["form"]),
              [nameField]: value,
            },
          },
        },
      };
    }
    case actionsTypeDetails.numberElement: {
      const { pathName, pathId, value, nameField } =
        action.payload as DetailNumber;
      if (!pathName || !pathId || typeof value === 'undefined' || !nameField) {
        return { ...state };
      }
      return {
        ...state,
        [pathName]: {
          ...checkIteretion(state[pathName]),
          [pathId]: {
            ...checkIteretion(state[pathName]?.[pathId]),
            numberElement: {
              ...checkIteretion(state[pathName]?.[pathId]?.["numberElement"]),
              [nameField]: value,
            },
          },
        },
      };
    }
    // return newState;
    case actionsTypeDetails.clean:
      return {
        ...state,
        ...initialDetailsState,
      };
    default:
      return state;
  }
}
