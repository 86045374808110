import {apiCall} from "../../../utils/serviceCall";
import {HTTPMethod} from "../../../utils/helper";

export interface SettingsReducerActions {
    type: SettingsReducerTypeActions,
    payload: SettingState
}

export enum SettingsReducerTypeActions {
    SET_ENTITIES = 'settings/set-entities',
    SET_ENTITY = 'settings/set-entities',
    REFRESH_ENTITIES = 'settings/refresh',
    CLEAR = 'settings/clear',
}


export interface SettingInterface {
    id: number | null
    list: {},
    data: {},
    name: string
}

export interface SettingState {
    entities: SettingInterface[] | null
}


const initialSettingState: SettingState = {
    entities: null,
};

export default function settingReducer(state: SettingState = initialSettingState, action: SettingsReducerActions): SettingState {
    switch (action.type) {

/*
        case SettingsReducerTypeActions.SET_ENTITY:

            if (action.payload.entities && action.payload.entities.length) {
                return {
                    ...state,
                    ...{
                        entities: action.payload.entities.map((item) => (
                            action.payload.name === item.name ? item : item)
                        )
                    }
                }
            }


        case SettingsReducerTypeActions.REFRESH_ENTITIES:

            apiCall(HTTPMethod.GET, '/settings').then((response) => {
                state.entities = response['hydra:members']
            })

            return state
*/
        case SettingsReducerTypeActions.SET_ENTITIES:
            return {
                ...state,
                ...{entities: action.payload.entities},
            }

        case SettingsReducerTypeActions.CLEAR:
            return initialSettingState

        default:
            return state
    }
}