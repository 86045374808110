import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import FsLightbox from "fslightbox-react";

import { Modal } from "react-bootstrap";
import CustomButton from "../../../buttons/CustomButton";
import {
  EIconType,
  HTTPMethod,
  handleStore,
  hydra,
  parseDashboardDetailsPath,
  saveNumberOfElementOnStore,
  takeIdFromIri,
} from "../../../../utils/helper";
import { useSelector } from "react-redux";
import { CombineReducersState } from "../../../../redux/store";
import { useLocation } from "react-router-dom";

import { apiCall, apiCallFile } from "../../../../utils/serviceCall";
import { Paginator } from "../../../paginator/paginator";
import { useIntl } from "react-intl";
import clsx from "clsx";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  MessageTypeEnum,
  MessagesReducerTypeActions,
} from "../../../../redux/stores/message/messageReducer";

import { Icon } from "../../../Icon/Icon";
import OutsideClickWrapper from "../../../OutsideWrapper/OutsideWrapper";
import ImageFull from "./ImageFull";
import ImagePreview from "./ImagePreview";
import useLogger from "../../../../hooks/useLogger";
import { current } from "@reduxjs/toolkit";
import { AttachmentDirectoryControllerApi } from "../../../../apis/attachmentDirectoryController";
import useToast from "../../../../hooks/useToast";
import { update } from "lodash";

interface FileCell {
  company: string;
  file: string;
  filePath: string;
  id: number;
  user: string;
  favorite?: boolean;
  createdAt: string;
}
enum showModeTypes {
  "SQUARE" = "square",
  "GRID" = "grid",
  "LIST" = "list",
}
interface SelectedAttachment {
  id: number;
  typology: string;
  name: string;
}
let numberOfElement: number = 1000;
export default function Attachments({ props }) {
  const dispatch = useDispatch();
  const {debug} = useLogger(Attachments.name);
  const { showToast } = useToast();

  const { type } = props;
  const intl = useIntl();
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const userData = useSelector(
    (state: CombineReducersState) => state.userReducer.userData
  )!;
  const [counter, setCounter] = useState<{ [key: string]: number }>();
  const [fullsize, setFullsize] = useState<any[]>([]);
  const currentUser = userData.id;
  const [errorMessage, setErrorMessage] = useState("");
  const [showModalAddFile, setShowModalAddFile] = useState<{
    typology: string;
    show: boolean;
  }>({ typology: "", show: false });
  const [loading, setLoading] = useState<{ [key: string]: boolean }>({});
  const [selectedAttachments, setSelectedAttachments] = useState<SelectedAttachment[] | null>(null);
  const [allSelected, setAllSelected] = useState(false);
  const toggleSelectedAttachment = (attachment: SelectedAttachment | null) => {
    if(attachment == null){
      setSelectedAttachments(null);
      return;
    }

    // If given attachment is already in the array it must be removed (toggle action)
    // If selectedAttachments is null it returns a new empty array
    let filtered = selectedAttachments ? selectedAttachments.filter(element => element.id != attachment.id) : [];

    // In case the filtered array is empty or has the same length of original attachments I must append the new attachment
    if(!selectedAttachments || selectedAttachments.length == filtered.length){
      filtered.push(attachment);
    }
    // TODO: Temporarily disable multi selection with forced "true". Remove it to enable again.
    filtered = [attachment];

    setSelectedAttachments(filtered);
    setAllSelected(false);
  };
  /** Gets the first selected attachment or null */
  const singleSelectedAttachment = () => selectedAttachments && selectedAttachments?.length>0 ? selectedAttachments[0] : null;
  const isAttachmentSelected = (id: number) => {
    if(!selectedAttachments){
      return false;
    }
    return selectedAttachments.map(element => element.id).includes(id);
  };
  const [listTotalItems, setTotalItems] = useState<{ [key: string]: number }>();
  const [currentPage, setCurrentPage] = useState<{ [key: string]: number }>({});
  const [search, setSearch] = useState<{ value: string; typology: string }>();
  const [currentView, setCurrentView] = useState<{ [key: string]: any }>({});
  const [showMode, setShowMode] = useState<{ [key: string]: showModeTypes }>(
    {}
  );
  const [listPreview, setListPreview] = useState<{
    [key: string]: { id: number; preview: any }[];
  }>({});
  const [grid, setGrid] = useState<{ [key: string]: FileCell[] }>({});
  const location = useLocation();
  const pathstart = location.pathname;
  const { path, pathId, pathName } = parseDashboardDetailsPath(pathstart);
  const initialDataToSendFile = {
    company: `/api${path}`,
    user: `/api/users/${currentUser}`,
    typology: type.editor.typologies[0],
    category: "attachments",
  };
  //
  const [currentPath, setCurrentPath] = useState<{ [key: string]: any }>({});
  const [subDirectory, setSubDirectory] = useState<{ [key: string]: any[] }>(
    {}
  );
  const [showDirectoryEditModal, setShowDirectoryEditModal] = useState<boolean>(false);
  const [curDir, setCurDir] = useState<{ [key: string]: any }>({});
  const [curDirName, setCurDirName] = useState<string>('');
  const [currentDirectory, setCurrentDirectory] = useState<{
    [key: string]: string;
  }>({});
  const [nameNewFolder, setNameNewFolder] = useState<string>("");
  const [showModalNewFolder, setShowModalNewFolder] = useState<{
    typology: string;
    show: boolean;
  }>({ typology: "", show: false });

  //
  const [form, setForm] = useState<any>(initialDataToSendFile);
  const [toggler, setToggler] = useState<boolean>(false);
  const [slide, setSlide] = useState<number>();
  const [textInput, setTextInput] = useState<string>(
    "Trascina i file o premi Scegli File"
  );
  const timerRef = useRef<any>(null);
  const handleShowMode = (value, typology) => {
    setShowMode((prev) => ({
      ...prev,
      [typology as string]: value,
    }));
  };
  const patchFavoriteValue = async (
    typology: FileCell,
    favorite: boolean,
    typologiesName
  ) => {
    try {
      const { data } = await apiCall(
        HTTPMethod.PATCH,
        `/${type.editor.path}/${typology.id}`,
        { favorite: favorite }
      );

      const overwriteTypology = grid[typologiesName];
      const indexToOverwrite = overwriteTypology.findIndex(
        (o) => o.id === data.id
      );
      if (indexToOverwrite !== -1) {
        overwriteTypology[indexToOverwrite] = data;
        setGrid((prev) => ({
          ...prev,
          [typologiesName as string]: overwriteTypology,
        }));
      }
    } catch (error) {
      return console.error(error.message);
    }
  };
  const checkNameNewFolder = (value) => {
    if (value.trim() === "") {
      setErrorMessage("il campo nome è obbligatorio");
    } else {
      setErrorMessage("");
    }
  };
  const createNewFolder = async () => {
    const currentTypology = showModalNewFolder.typology;
    setLoading((prev) => ({
      ...prev,
      [currentTypology as string]: true,
    }));
    await apiCall(HTTPMethod.POST, `/attachment_directories`, {
      name: nameNewFolder.trim(),
      fullPath: parsePath(currentPath[currentTypology]) + nameNewFolder.trim(),
      parent: currentDirectory[currentTypology],
      typology: currentTypology,
      company: `/api/companies/${pathId}`,
    })
      .then((response) => {
        initializeTypology();
      })
      .catch((err) => {
        dispatch({
          type: MessagesReducerTypeActions.SET,
          payload: {
            type: MessageTypeEnum.DANGER,
            message: intl.formatMessage({
              id: "Attachments.errCreateNewFolder",
            }),
          },
        });
      })
      .finally(() => {
        setShowModalNewFolder({ typology: "", show: false });
        setLoading((prev) => ({
          ...prev,
          [currentTypology as string]: false,
        }));
      });
  };
  useEffect(() => {
    if (listTotalItems) {
      debug("listTotalItems", listTotalItems);
      const listKeys = Object.keys(listTotalItems);
      let counter = 0;
      if (listKeys.length > 0) {
        listKeys.forEach((key) => {
          counter += listTotalItems[key];
          if(key === 'foto'){
          handleStore(counter, {
            pathName,
            pathId,
            formField: 'foto',
          });
          }
        });
        saveNumberOfElementOnStore(pathstart, props.name, counter);
      }
    }
  }, [listTotalItems]);
  let currentNumberOfElement = 0;
  const renderFavorite = (
    typology: FileCell,
    typologyName: string,
    id?: number
  ) => {
    return (
      <div
        className="favorite-icon"
        onClick={(e) => {
          e.stopPropagation();
          const favorite = !typology.favorite;
          patchFavoriteValue(typology, favorite, typologyName);
        }}
      >
        {id &&
          showMode[typologyName] === showModeTypes.LIST &&
          isAttachmentSelected(id) && (
            <>
              <i
                onClick={() => handleDownload(singleSelectedAttachment())}
                className={clsx("fa-solid fa-download active")}
              ></i>
              <i
                onClick={() =>
                  window.confirm(intl.formatMessage({ id: "window.delete" })) &&
                  deleteAttachments()
                }
                className={clsx("fa-solid fa-trash-can active")}
              ></i>
            </>
          )}
      </div>
    );
  };
  const renderShowMode = (typologiesName, index) => {
    const gridMode = (
      <div className="attachments-grid">
        {grid &&
          grid[typologiesName] &&
          grid[typologiesName].map((typology, TypologyIndex) => (
            <>
              <div
                key={typologiesName + index + TypologyIndex}
                onClick={(e) => {
                  const attachmentsToSave = {
                    id: typology.id,
                    typology: typologiesName,
                    name: typology.file,
                  };
                  //double click
                  if (e.detail === 2) {
                    if (typologiesName === "foto") {
                      handleFullsize(attachmentsToSave, TypologyIndex + 1);
                    } else {
                      handleDownload(attachmentsToSave);
                    }
                  }
                  toggleSelectedAttachment(attachmentsToSave);
                }}
                className="cell"
              >
                {renderFavorite(typology, typologiesName)}
                <div
                  className="cell-image"
                  style={{
                    border:
                      isAttachmentSelected(typology.id)
                        ? "2px solid #369BFF"
                        : "none",
                  }}
                >
                  {/* {imagePreview(typology.id, typologiesName, false)} */}
                  <ImagePreview id={typology.id} type={type} />
                </div>
                <div className="cell-text ">
                  <div>{typology.file}</div>
                  <div className="cell-text-date">[{moment(typology.createdAt).format("DD/MM/YYYY")}]</div>
                </div>
              </div>
            </>
          ))}
      </div>
    );

    const listMode = (
      <table className=" attachments-list table table-row-bordered  g-2">
        {grid &&
          grid[typologiesName] &&
          grid[typologiesName].map((typology, TypologyIndex) => (
            <>
              <tr
                style={{
                  background:
                    isAttachmentSelected(typology.id)
                      ? "#CCE5FF"
                      : "",
                }}
                className="attachments-list-row"
                key={typologiesName + index + TypologyIndex}
                onClick={() =>
                  toggleSelectedAttachment({
                    id: typology.id,
                    typology: typologiesName,
                    name: typology.file,
                  })
                }
              >
                <td>{moment(typology.createdAt).format("DD/MM/YYYY")}</td>
                <td className="row-list-image">
                  {/* {imagePreview(typology.id, typologiesName, true)} */}
                  <ImagePreview id={typology.id} type={type} />
                </td>
                <td className="row-list-text">{typology.file}</td>
                <td>{renderFavorite(typology, typologiesName, typology.id)}</td>
              </tr>
            </>
          ))}
      </table>
    );
    const squareMode = (
      <div className="attachments-square">
        {grid &&
          grid[typologiesName] &&
          grid[typologiesName].map((typology, TypologyIndex) => (
            <div
              key={typologiesName + index + TypologyIndex}
              onClick={() =>
                toggleSelectedAttachment({
                  id: typology.id,
                  typology: typologiesName,
                  name: typology.file,
                })
              }
              className="cell"
            >
              {renderFavorite(typology, typologiesName)}
              <div
                className="cell-image"
                style={{
                  border:
                  isAttachmentSelected(typology.id)
                      ? "2px solid #369BFF"
                      : "none",
                }}
              >
                {/* {imagePreview(typology.id, typologiesName, false)} */}
                <ImagePreview id={typology.id} type={type} />
              </div>

              <div className="cell-text">
                <div>{typology.file}</div>
                <div className="cell-text-date">[{moment(typology.createdAt).format("DD/MM/YYYY")}]</div>
              </div>
            </div>
          ))}
      </div>
    );
    if (showMode[typologiesName]) {
      switch (showMode[typologiesName]) {
        case showModeTypes.GRID:
          return gridMode;
        case showModeTypes.LIST:
          return listMode;
        case showModeTypes.SQUARE:
          return squareMode;
      }
    } else {
      return gridMode;
    }
  };

  const handleDownload = async (attachment) => {
    if (attachment) {
      setLoading((prev) => ({
        ...prev,
        [attachment.typology as string]: true,
      }));
      await apiCall(
        HTTPMethod.GET,
        `/${type.editor.path}/${attachment.id}/download`
      )
        .then((response) => {
          const result = response.data;
          const download = result.downLoad;

          if (download) {
            const link = document.createElement("a");
            link.href = download;
            link.download = attachment.name;
            // link.target = "_blank";
            document.body.appendChild(link);
            link.click();

            setTimeout(() => {
              document.body.removeChild(link);
            }, 1000);
          }
          return;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading((prev) => ({
            ...prev,
            [attachment.typology as string]: false,
          }));
        });
    }
  };

  const toggleSelectAll = (event, typologiesName: string) => {
    const checked = event.target.checked;
    const updated = checked ? grid[typologiesName].map(element => ({id: element.id, typology: typologiesName, name: element.file})) : [];
    setSelectedAttachments(updated);
    setAllSelected(checked);
  }

  const handleDownloadZip = async (directory) => {
    console.log("directory", directory);
    let id = takeIdFromIri(directory);
    console.log('id', id);
    if (directory) {
      setLoading((prev) => ({
        ...prev,
        [directory.typology as string]: true,
      }));
      await apiCall(
        HTTPMethod.GET,
        `/attachment_directories/${id}/download`,
        {responseType: 'blob'}
      )
        .then((response) => {
          const result = response.data;
          const download = result;

        if (download) {
          var blob = new Blob([download], { type: 'application/octet-stream' });
          let url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "download.zip";
          // link.target = "_blank";
          document.body.appendChild(link);
          link.click();

          setTimeout(() => {
            document.body.removeChild(link);
          }, 1000);
        }
          return;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading((prev) => ({
            ...prev,
            [directory.typology as string]: false,
          }));
        });
    }
  };

  const handleDeleteDirectory = async (directory, typology) => {
    if(!window.confirm(intl.formatMessage({id: "Delete.confirm"}))) return;
    console.log("directory", directory);
    let id = takeIdFromIri(directory);
    console.log('id', id);
    if (directory) {
      setLoading((prev) => ({
        ...prev,
        [directory.typology as string]: true,
      }));
      await AttachmentDirectoryControllerApi.delete(id+"")
      .then((response) => {
        showToast(intl.formatMessage({id: "Delete.success"}));
        let updatedCurrentPath = [...currentPath[typology]];
        updatedCurrentPath = updatedCurrentPath.slice(0, -1);
        setCurrentPath((prev) => ({
          ...prev,
          [typology]: updatedCurrentPath,
        }));
        window.location.reload()
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading((prev) => ({
          ...prev,
          [directory.typology as string]: false,
        }));
      });
    }
  };

  const editCurrentDirectory = async () => {
    console.log("directory", curDir);
    let id = curDir.id;
    if (id) {
      setLoading((prev) => ({
        ...prev,
        [curDir.typology as string]: true,
      }));
      AttachmentDirectoryControllerApi.patch(id, {name: curDirName})
      .then((response) => {
          showToast(intl.formatMessage({id: "SaveSuccess"}));
          let data = response.data;
          let typology = data.typology
          let updatedCurrentPath = [...currentPath[typology]];
          updatedCurrentPath[updatedCurrentPath.length-1] = data;
          setCurrentPath((prev) => ({
            ...prev,
            [typology]: updatedCurrentPath,
          }));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading((prev) => ({
          ...prev,
          [curDir.typology as string]: false,
        }));
        setShowDirectoryEditModal(false);
        setCurDirName('');
        setCurDir({});
      });
    }
  };

  /**
   * If attachment is not null it toggle the value for FsLightbox
   * @param attachment - Reference attachment object
   * @param index - Index of slide to show
   */
  const handleFullsize = async (attachment, index) => {
    if (attachment) {
      console.debug("change toggler");
      console.debug("change slider slide", index);
      setSlide(index);
      setToggler(!toggler);
    }
  };
  const deleteAttachments = async () => {
    await apiCall(
      HTTPMethod.DELETE,
      `/${type.editor.path}/${singleSelectedAttachment()!.id}`
    )
      .then(() => {
        toggleSelectedAttachment(null);
        initializeTypology();
      })
      .catch((err) => console.log(err));
  };

  const initializeFile = async ({
    typology,
    page,
    valueToFilter,
  }: {
    typology?: string;
    page?: number;
    valueToFilter?: string;
  } = {}): Promise<void> => {
    setLoading((prev) => ({
      ...prev,
      [typology as string]: true,
    }));
    const pageNumber = page ? page : 1;

    const params = {
      params: {
        company: pathId,
        page: pageNumber,
        // path: parsePath(currentPath[typology as string]),
        directory: currentDirectory[typology as string],
        itemsPerPage: numberOfElement,
        typology: typology as string,
        file: valueToFilter,
      },
    };
    params.params["exists[directory]"] = currentDirectory[typology as string] != null;
    if (!valueToFilter) {
      delete params.params.file;
    }

    // Call Api removing directory in order to get the total count
    const totalCountParams = {
      params: {
        company: pathId,
        page: pageNumber,
        itemsPerPage: 1,
        typology: typology as string,
      },
    };
    apiCall(HTTPMethod.GET, `/${type.editor.path}`, totalCountParams)
      .then((response) => {
        const foundTotal = response.data?.[hydra.TOTAL];
        debug("Found Total", foundTotal, "params", totalCountParams);
        setTotalItems((prev) => ({
          ...prev,
          [typology as string]: response.data[hydra.TOTAL],
        }));
      })
      .catch((err) => console.log(err));
    await apiCall(HTTPMethod.GET, `/${type.editor.path}`, params)
      .then((response) => {
        const result = response.data[hydra.MEMBER];
        console.debug("Attachments result", result);
        const view = response.data?.[hydra.VIEW];
        setCounter((prevState) => ({
          ...prevState,
          [typology as string]: response.data?.[hydra.TOTAL],
        }));
        setGrid((prev) => ({
          ...prev,
          [typology as string]: result,
        }));
        setCurrentPage((prev) => ({
          ...prev,
          [typology as string]: pageNumber,
        }));
        setFullsize(result);
        if (view) {
          setCurrentView((prev) => ({
            ...prev,
            [typology as string]: view,
          }));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading((prev) => ({
          ...prev,
          [typology as string]: false,
        }));
      });
  };
  interface subDirectoryProps {
    parent?: string | null;
    typology?: string;
  }
  const initializeSubDirectory = async ({
    parent = null,
    typology,
  }: subDirectoryProps) => {
    const filter = {
      typology: typology,
      company: pathId,
      parent,
    };
    filter["exists[parent]"] = parent != null;
    await apiCall(HTTPMethod.GET, `/attachment_directories`, {
      params: filter,
    }).then(({ data }) => {
      const currentDirectoryForTypology = () => {
        return data[hydra.MEMBER].length > 0
          ? data[hydra.MEMBER].filter(
              (directory) => directory.typology === typology
            )
          : [];
      };
      setSubDirectory((prev) => ({
        ...prev,
        [typology as string]: currentDirectoryForTypology(),
      }));
    });
  };
  const initializeTypology = () => {
    type?.editor?.typologies.forEach(async (typology, index) => {
      if (index === 0 && currentNumberOfElement > 0) {
        currentNumberOfElement = 0;
      }
      await initializeSubDirectory({
        typology,
        parent: currentDirectory[typology],
      });
      await initializeFile({
        typology,
      });
    });
  };
  /**
   * @deprecated Preview fetching is in ImagePreview component
   * @param Params for preview fetching - 
   */
  const fetchPreview = async ({
    id,
    typology,
  }: {
    id: number;
    typology: string;
  }) => {
    if (typology) {
      // await apiCall(HTTPMethod.GET, `/${type.editor.path}/${id}/preview`, {
      //   headers: "accept: image/jpeg",
      //   responseType: "blob",
      // })
      //   .then((response) => {
      //     const result = response.data;
      //
      //     setListPreview((prev) => {
      //       const newArray = [
      //         ...(prev[typology] || []), // Copia l'array esistente se esiste, altrimenti inizia da un array vuoto
      //         {
      //           id: id,
      //           preview: result as string,
      //         },
      //       ];
      //
      //       return {
      //         ...prev,
      //         [typology]: newArray,
      //       };
      //     });
      //     return;
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });

      return;
    }
  };
  useEffect(() => {
    initializeTypology();
  }, [currentDirectory]);

  useEffect(() => {
    Object.keys(grid).length > 0 &&
      Object.keys(grid).forEach((typologyName) => {
        const gridFiltered = grid[typologyName].filter((fileToFilter) => {
          if (
            Object.keys(listPreview).length > 0 &&
            listPreview[typologyName] &&
            listPreview[typologyName].some((el) => el.id === fileToFilter.id)
          ) {
            return false;
          } else {
            return true;
          }
        });
        grid[typologyName].length > 0 &&
          gridFiltered.forEach((file) => {
            fetchPreview({ id: file.id, typology: typologyName });
          });
      });
  }, [grid]);
  const hideHeader = () => {
    const header = document?.getElementById("kt_app_header");
    if(header)
        header.style.setProperty("z-index", "0", "important");
  }
  const showHeader = () => {
    const header = document?.getElementById("kt_app_header");
    if(header)
        header.style.setProperty("z-index", "999", "important");
  }
  useEffect(() => {
    const setup = async() => {
      setForm((prevState) => ({
        ...prevState,
        ["typology"]: showModalAddFile.typology,
      }));
      if (showModalAddFile.show) {
        document.getElementById("fileInput")?.click();
      }
    }
    setup();
  }, [showModalAddFile.typology, showModalAddFile.show]);
  const handleFileInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setErrorMessage("");
      let files: FileList = e.target.files;
      let imageFiles;
      //     file.type.startsWith("image/")
      if (form["typology"] === "foto") {
        const check = Object.keys(files).some(
          (key) => !files[key].type.includes("image/")
        );
        if (check) {
          setErrorMessage("I file appena inseriti non sono formato immagine");
          return;
        }
      }
      const fileArray = Array.from(imageFiles || files).map((file) => ({
        file,
      }));
      fileArray.forEach((element) => {
        setForm((prevState) => ({
          ...prevState,
          file: [...(prevState.files || []), ...fileArray],
        }));
      });
      setTextInput(`Hai caricato ${files.length} file`);
    }
  };

  useEffect(() => {
    if(!form || !form.file || form.file.length == 0) return;
    makePost();
  },[form.file])

  const makePost = async () => {
    setLoadingButton(true);

    try {
      const formData = new FormData();
      form.file.forEach((element, index) => {
        formData.append("files[]", element.file);
      });
      formData.append("company", form["company"]);
      formData.append("user", form["user"]);
      if(currentDirectory[form["typology"]]){
        formData.append("directory", currentDirectory[form["typology"]]);
      }
      formData.append("typology", form["typology"]);
      formData.append("category", form["category"]);
      // Show loading message
        dispatch({
          type: MessagesReducerTypeActions.SET,
          payload: {
            type: MessageTypeEnum.SUCCESS,
            message: intl.formatMessage({
              id: "Attachments.loading",
            }),
          },
        });
      apiCallFile(HTTPMethod.POST, `/${type.editor.path}`, formData).then(res => {
      // Show success message and initialize list
        dispatch({
          type: MessagesReducerTypeActions.SET,
          payload: {
            type: MessageTypeEnum.SUCCESS,
            message: intl.formatMessage({
              id: "Attachments.addSuccess",
            }),
          },
        });
       initializeFile({ typology: form.typology });
      })
        .catch(error => {
          // Show error message for attachments loading
          console.log(error);
          dispatch({
            type: MessagesReducerTypeActions.SET,
            payload: {
              type: MessageTypeEnum.DANGER,
              message: intl.formatMessage({
                id: "Attachments.addError",
              }),
            },
          });
        }
        );
      setShowModalAddFile({
        typology: form.typology,
        show: false,
      });
      // const ids = response.data.map((item) => item["@id"]);

      // props.setValue(props.name, [...props.data, ...ids]);
    } catch (error) {
      console.log(error);
      dispatch({
        type: MessagesReducerTypeActions.SET,
        payload: {
          type: MessageTypeEnum.DANGER,
          message: intl.formatMessage({
            id: "Attachments.addError",
          }),
        },
      });
    }
    setLoadingButton(false);

    setTextInput("Trascina i file o premi Scegli File");
  };

  function keyUp() {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      initializeFile({
        typology: search?.typology,
        valueToFilter: search?.value,
      });
    }, 500);
  }
  function keyDown() {
    clearTimeout(timerRef.current);
  }
  // const convertBinaryToBlobUrl = (el: string): string => {

  // }
  const imagePreview = useCallback(
    (id: number, nameTipology: string, showEye: boolean) => {
      if (listPreview[nameTipology]) {
        const findPreview = listPreview[nameTipology].find(
          (preview) => preview.id === id
        );
        if (findPreview) {
          let blobUrl = window.URL.createObjectURL(findPreview.preview);
          return <img src={blobUrl}></img>;
        }
      }
      return (
        <>
          <div>{intl.formatMessage({ id: "Attachments.noPreview" })}</div>
        </>
      );
    },
    [listPreview]
  );
  const changePage = (typologiesName: string, newPage: number) => {
    if (currentPage[typologiesName] !== newPage) {
      initializeFile({ typology: typologiesName, page: newPage });
      setCurrentPage((prev) => ({
        ...prev,
        [typologiesName]: newPage,
      }));
    }
  };
  const parsePath = (value) => {
    if (!value || value.length === 0) {
      return "/";
    } else {
      return "/" + value.map((el) => el.name).join("/") + "/";
    }
  };
  const changeFolder = (idx, typology) => {
    let newValue;
    if (idx === null) {
      newValue = [];
    } else {
      newValue = currentPath[typology].filter((el, index) => {
        return index <= idx;
      });
    }

    const newDirectory =
      newValue.length > 0 ? newValue[newValue.length - 1]["@id"] : null;
    if (newValue !== currentPath[typology]) {
      setCurrentPath((prev) => ({
        ...prev,
        [typology]: newValue,
      }));
      setCurrentDirectory((prev) => ({
        ...prev,
        [typology]: newDirectory,
      }));
    }
  };
  return (
    <>
      <div className="attachments" style={{ flex: 1 }}>
        {type?.editor?.typologies &&
          Object.keys(type?.editor?.typologies).length > 0 &&
          type?.editor?.typologies.map((typologiesName, index) => {
            return (
              <div
                key={`${typologiesName}-${props.name}-${index}`}
                className="accordion accordion-icon-toggle"
                id={`kt_accordion_${typologiesName}`}
              >
                <div
                  className="accordion-header py-3 d-flex align-center show"
                  aria-expanded="true"
                  data-bs-toggle="collapse"
                  data-bs-target={`#kt_accordion_${typologiesName}_item_${index}`}
                >
                  <span
                    style={{
                      backgroundColor: "#3699FF",
                      padding: "2px",
                      borderRadius: "4px",
                      width: "25px",
                      height: "25px",
                      textAlign: "center",
                    }}
                    className="accordion-icon"
                  >
                    <i className="fa-solid text-white fa-angles-right">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                  </span>
                  <h3 className="fs-4 fw-semibold mb-0 ms-4">
                    {intl.formatMessage({ id: `TYPOLOGY.${typologiesName}` })}
                  </h3>
                  <span className="badge badge-secondary ms-4">
                    {counter?.[typologiesName] || 0}
                  </span>
                  <span className="badge ms-2">
                    {`(${intl.formatMessage({id: 'Attachments.currentDirectory'})})`}
                  </span>
                </div>
                <div
                  id={`kt_accordion_${typologiesName}_item_${index}`}
                  className="fs-6 collapse ps-10 show"
                  data-bs-parent={`#kt_accordion_${typologiesName}`}
                >
                  <div className="attachments-grid-search gap-2">
                    <CustomButton
                      onClick={() =>
                        setShowModalNewFolder({
                          typology: typologiesName,
                          show: true,
                        })
                      }
                    >
                      {intl.formatMessage({ id: "createNewFolder" })}
                    </CustomButton>
                    <div className="input-custom">
                      <input
                        placeholder={intl.formatMessage({
                          id: "Attachments.find",
                        })}
                        onChange={(e) =>
                          setSearch({
                            value: e.target.value,
                            typology: typologiesName,
                          })
                        }
                        onKeyUp={() => keyUp()}
                        onKeyDown={() => keyDown()}
                      ></input>
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </div>
                  </div>
                  <div className="d-flex p-5">
                    <ol className=" breadcrumb breadcrumb-line text-muted fs-6 fw-semibold">
                      {currentPath[typologiesName]?.length > 0 && (
                        <li
                          onClick={() => changeFolder(null, typologiesName)}
                          role="button"
                          className={clsx("breadcrumb-item")}
                        >
                          / {intl.formatMessage({ id: `TYPOLOGY.${typologiesName}` })}
                        </li>
                      )}
                      {currentPath[typologiesName]?.map((path, idx) => (
                        <>
                          {
                            <>
                              <li
                                onClick={() =>
                                  changeFolder(idx, typologiesName)
                                }
                                role="button"
                                className={clsx(
                                  "breadcrumb-item",
                                  currentPath[typologiesName].length === idx + 1
                                    ? "text-primary"
                                    : ""
                                )}
                              >
                                {path.name}
                              </li>
                            </>
                          }
                        </>
                      ))}
                    </ol>
                      {currentPath[typologiesName]?.length > 0 && (
                        <div className="d-flex cursor-pointer attachments-directory-download" 
                          onClick={() => {
                          const directory = currentPath[typologiesName][currentPath[typologiesName].length - 1];
                          setCurDir(directory);
                          setShowDirectoryEditModal(true);
                        }}>
                          <i
                            className={clsx(
                              "fa-solid fa-pencil active ms-2"
                            )}
                          ></i>
                        </div>
                      )}
                  </div>
                  {currentPath[typologiesName]?.length > 0 && (
                    <div className="d-flex">
                      <div className="d-flex p-5 pt-0 fs-6 text-gray-900 text-hover-primary cursor-pointer attachments-directory-download" 
                        onClick={() => handleDownloadZip(currentDirectory[typologiesName])}>
                        {intl.formatMessage({ id: `Attachments.download_directory` })}
                        <i
                          className={clsx(
                            "fa-solid fa-download active ms-2"
                          )}
                        ></i>
                      </div>
                      <div className="d-flex p-5 pt-0 fs-6 text-gray-900 text-hover-danger cursor-pointer attachments-directory-delete" 
                        onClick={() => handleDeleteDirectory(currentDirectory[typologiesName], typologiesName)}>
                        {intl.formatMessage({ id: `Attachments.delete_directory` })}
                        <i
                          className={clsx(
                            "fa-solid fa-trash active ms-2"
                          )}
                        ></i>
                      </div>
                    </div>
                  )}
                  <div className="accordion-body d-flex gap-4">
                    <div
                      key={typologiesName + index}
                      className="attachments-add"
                    >
                      <div
                        onClick={() =>
                          setShowModalAddFile({
                            typology: typologiesName,
                            show: true,
                          })
                        }
                        className="add-icon icon-wrapper"
                      >
                        <i className="fa-solid fa-plus" />
                      </div>
                    </div>
                    <div className="attachments-content">
                      {loading[typologiesName] ? (
                        <div
                          className="spinner-border mx-auto p-5"
                          style={{ color: "#369BFF" }}
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <>
                          {subDirectory[typologiesName]?.length > 0 && (
                            <div className="subdirectory-section d-flex flex-wrap flex-row gap-4">
                              {subDirectory[typologiesName].map(
                                (subFolder, idx) => (
                                  <div
                                    key={`directory-${typologiesName}-${subFolder.name}-${idx}`}
                                    onClick={() => {
                                        console.log("IRI", subFolder["@id"]);
                                      setCurrentDirectory((prev) => ({
                                        ...prev,
                                        [typologiesName]: subFolder["@id"],
                                      }));
                                      let currentPathClone =
                                        currentPath[typologiesName] || [];
                                      currentPathClone.push(subFolder);
                                      setCurrentPath((prev) => ({
                                        ...prev,
                                        [typologiesName]: currentPathClone,
                                      }));
                                    }}
                                    className="folder d-flex flex-column align-items-center p-2 w-100px"
                                  >
                                    <Icon
                                      nameIcon={"folder"}
                                      className="iconSubdirectory"
                                      type={EIconType.MATERIAL}
                                    />
                                    <span className="cell-text">
                                      {subFolder.name}
                                    </span>
                                  </div>
                                )
                              )}
                            </div>
                          )}
                          {renderShowMode(typologiesName, index)}
                        </>
                      )}
                      {loading[typologiesName] === false &&
                        grid[typologiesName] &&
                        grid[typologiesName].length > 0 &&
                        currentView[typologiesName] && (
                          <Paginator
                            key={`page-${currentPage}`}
                            view={currentView[typologiesName]}
                            currentPage={currentPage[typologiesName]}
                            callback={(page) =>
                              changePage(typologiesName, page)
                            }
                          />
                        )}
                    </div>
                    <div className="attachments-choose-disposition">
                      <i
                        onClick={() =>
                          handleShowMode(showModeTypes.SQUARE, typologiesName)
                        }
                        className={clsx(
                          "fa-regular fa-square",
                          showMode[typologiesName] === showModeTypes.SQUARE
                            ? "active"
                            : ""
                        )}
                      ></i>
                      <i
                        onClick={() =>
                          handleShowMode(showModeTypes.GRID, typologiesName)
                        }
                        className={clsx(
                          "fa-solid fa-table-cells-large",
                          showMode[typologiesName] === showModeTypes.GRID
                            ? "active"
                            : ""
                        )}
                      ></i>
                      <i
                        onClick={() =>
                          handleShowMode(showModeTypes.LIST, typologiesName)
                        }
                        className={clsx(
                          "fa-solid fa-list",
                          showMode[typologiesName] === showModeTypes.LIST
                            ? "active"
                            : ""
                        )}
                      ></i>

                      {singleSelectedAttachment() &&
                        singleSelectedAttachment()!.typology === typologiesName &&
                        showMode[typologiesName] !== showModeTypes.LIST && (
                          <>
                            <div className="attachments-actions">
                              <input className='form-check-input mt-2 m-auto' type='checkbox' checked={allSelected} defaultChecked={allSelected} onChange={(event) => toggleSelectAll(event, typologiesName)} />
                              <i
                                onClick={() =>
                                  handleDownload(singleSelectedAttachment())
                                }
                                className={clsx(
                                  "fa-solid fa-download active m-auto",
                                  showMode[typologiesName] ===
                                    showModeTypes.LIST
                                    ? "active"
                                    : ""
                                )}
                              ></i>
                              <i
                                onClick={() =>
                                  window.confirm(
                                    intl.formatMessage({ id: "window.delete" })
                                  ) && deleteAttachments()
                                }
                                className={clsx("fa-solid fa-trash-can active m-auto")}
                              ></i>
                              {/* {typologiesName === "foto" && (
                            
                              )} */}
                            </div>
                          </>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        <FsLightbox
          onOpen={hideHeader}
          onClose={showHeader}
          exitFullscreenOnClose={true}
          toggler={toggler}
          slide={slide}
          disableSlideSwiping={true}
          sources={
            fullsize.map((val) => {
              return (<div><ImageFull id={val.id} type={type}/></div>)
            })
          }
          key={JSON.stringify(fullsize)}
        />
      </div>

      <>
        <Modal
          show={showModalAddFile.show}
          onHide={() => {
            setShowModalAddFile({ typology: "", show: false });
          }}
        >
          <Modal.Header closeButton />
          <Modal.Body>
            <div>
              <input
                type="file"
                id="fileInput"
                style={{ color: "transparent", width: "400px" }}
                name="file"
                accept={form["typology"] === "foto" ? "image/*" : "*"}
                onChange={(e) => handleFileInputChange(e)}
                multiple
              ></input>
              <label
                htmlFor="fileInput"
                style={{
                  position: "relative",
                  left: "160px",
                  bottom: "35px",
                  border: "none",
                  cursor: "pointer",
                  zIndex: "1",
                }}
              >
                {textInput}
              </label>
            </div>
            {form["typology"] === "foto" && errorMessage && (
              <span style={{ color: "red" }}>{errorMessage}</span>
            )}
          </Modal.Body>
          <Modal.Footer>
            <CustomButton loading={loadingButton} onClick={() => makePost()}>
              {intl.formatMessage({ id: "Attachments.save" })}
            </CustomButton>
          </Modal.Footer>
        </Modal>

        {/* create folder */}
        <Modal
          show={showModalNewFolder.show}
          onHide={() => {
            setShowModalNewFolder({ typology: "", show: false });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Crea una cartella</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="input-form-field">
              <label htmlFor="nameNewFolder">Nome *</label>
              <input
                type="text"
                name="nameNewFolder"
                onChange={(e) => {
                  checkNameNewFolder(e.target.value);
                  setNameNewFolder(e.target.value);
                }}
              ></input>
            </div>
            {errorMessage && (
              <span style={{ color: "red" }}>{errorMessage}</span>
            )}
          </Modal.Body>
          <Modal.Footer>
            <CustomButton
              disable={errorMessage !== "" && nameNewFolder.trim() === ""}
              loading={loadingButton}
              onClick={() => createNewFolder()}
            >
              {intl.formatMessage({ id: "Attachments.save" })}
            </CustomButton>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showDirectoryEditModal}
          onHide={() => {
            setShowDirectoryEditModal(false)
          }}
        >
          <Modal.Header closeButton />
          <Modal.Body>
            <div>
              <label
                htmlFor="directoryName"
              >
              {intl.formatMessage({ id: "Attachments.renameDirectory" })}: {curDir?.name!}
              </label>
              <input
                className="form-control  basiInput form-control-solid"
                type="text"
                id="directoryName"
                name="directoryName"
                defaultValue={curDir?.name!}
                onChange={(e) => {
                  setCurDirName(
                    e.target.value
                  );
                }}
              ></input>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <CustomButton loading={loadingButton} onClick={() => editCurrentDirectory()}>
              {intl.formatMessage({ id: "Attachments.save" })}
            </CustomButton>
          </Modal.Footer>
        </Modal>
      </>
    </>
  );
}
