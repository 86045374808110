import { useDispatch } from "react-redux";
import {
  MessagesReducerTypeActions,
  MessageTypeEnum,
} from "../redux/stores/message/messageReducer";

const useToast = () => {
  const dispatch = useDispatch();
  const showSuccessToast = (message: string) => {
    dispatch({
      type: MessagesReducerTypeActions.SET,
      payload: {
        type: MessageTypeEnum.SUCCESS,
        message: message,
      },
    });
  };
  return {
    showToast: showSuccessToast,
  };
};

export default useToast;
