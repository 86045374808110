import Skeleton from "react-loading-skeleton";
import { useEffect, useMemo, useRef, useState } from "react";

import AsyncSelect from "react-select/async";
import { apiCall } from "../../utils/serviceCall";
import {
  HTTPMethod,
  getOptionsAsyncSelect,
  hydra,
  isMultIriReference,
  schemaTypeComponents,
  takeIdFromIri,
  typeOfObject,
  uniteValues,
} from "../../utils/helper";
import OutsideClickWrapper from "../OutsideWrapper/OutsideWrapper";
import { customStyles } from "./styleReactSelect";
import { useIntl } from "react-intl";
interface autocompleteProps {
  name: string;
  value: string | string[];
  type: any;
  typeFilter?: boolean;
  setValue: (name: string, value: any) => void;
  setShowInput?: (value: boolean) => void;
  customFilter?: string;
  filterToUse?: string;
  disabled?: boolean;
  isClearable?: boolean;
}

export default function AutocompleteFilter({
  name,
  value,
  type,
  setValue,
  setShowInput,
  customFilter,
  filterToUse,
  disabled,
  isClearable = true,
}: autocompleteProps) {
  const intl = useIntl();
  const isMulti: boolean = isMultIriReference(type);
  const [loading, setLoading] = useState<boolean>(true);
  const [completeSelected, setcompleteSelected] = useState<
    typeOfObject | typeOfObject[] | null
  >(null);
  const [iriOfSelectElement, setIriOfSelectElement] = useState<
    string | string[]
  >(value);
  const { path }: { path: string } = type.editor;
  const mainField: string | string[] = type.editor.mainField || "name";
  const fieldNamefilter = filterToUse
    ? filterToUse
    : customFilter
    ? customFilter
    : "name";
  const timerRef = useRef(null);
  const [valueInput, setValueInput] = useState<string>(
    customFilter ? intl.formatMessage({ id: `allFilter${name}` }) : ""
  );

  interface selectValueStandard {
    value: string;
    label: string;
  }
  const initializeValue = (): void => {
    setLoading(true);
    try {
      getValue();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const getOptions = async (inputValue: string = "") => {
    return getOptionsAsyncSelect(inputValue, timerRef, async () => {
      const optionalParams = path === "locations" ? { language: "IT" } : {}; // TODO NON FUNZIONA
      const params =
        inputValue === ""
          ? {}
          : { params: { [fieldNamefilter]: inputValue, ...optionalParams } };
      try {
        const { data } = await apiCall(HTTPMethod.GET, `/${path}`, params);
        const response = data[hydra.MEMBER].map((element) =>
          computedList(element)
        );

        return response;
      } catch (err) {
        console.log(err);
      }
    });
  };
  const computedList = (
    element
  ): selectValueStandard | selectValueStandard[] => {
    if (Array.isArray(element)) {
      if (element.length === 0) return [];
 
      return element.map((item) => ({
        value: customFilter
          ? { filterValue: item[customFilter], iri: element["@id"] }
          : item["@id"],
        label: uniteValues(item, mainField),
      }));
    } else {
      // Se element è un oggetto singolo, restituiamo un array contenente un solo oggetto
      return (
        element && {
          value: customFilter
            ? { filterValue: element[customFilter], iri: element["@id"] }
            : element["@id"],
          label: uniteValues(element, mainField),
        }
      );
    }
  };
  const saveSingleIri = (value) => {
    if (!customFilter) {
      setIriOfSelectElement(value);
      setValue(name, value);
    } else {
      setIriOfSelectElement('');
      setValue(name, value?.filterValue);
    }
  };
  const saveMultiIri = (elements) => {
    if (!customFilter) {
      const iriToSave = elements.map((element) => element.value);

      setIriOfSelectElement(iriToSave);
      setValue(name, iriToSave);
    } else {
      const iriToSave = elements.map((element) => element.value.iri);
      const valueToSave = elements.map((element) => element.value.filterValue);
      setIriOfSelectElement(iriToSave);
      setValue(name, valueToSave);
    }
  };
  const getValue = async () => {
    const params = takeIdFromIri(iriOfSelectElement)
      ? { params: { id: takeIdFromIri(iriOfSelectElement) } }
      : {};
    const { data } = await apiCall(HTTPMethod.GET, `/${path}`, params);
    const selected = isMulti ? data[hydra.MEMBER] : data[hydra.MEMBER][0];
    setcompleteSelected(selected);
  };
  const { computedSelectedValue } = useMemo(() => {
    return {
      computedSelectedValue:value ? {value:value, label:value}: completeSelected?.length > 0
        ? computedList(completeSelected)
        : {label: intl.formatMessage({ id: `allFilter${name}` }),value: null},
    };
  }, [completeSelected,value]);
  useEffect(() => {
    if (iriOfSelectElement && !Array.isArray(iriOfSelectElement)) {
      initializeValue();
    }
    if (Array.isArray(iriOfSelectElement) && iriOfSelectElement.length > 0) {
      initializeValue();
    } else {
      setcompleteSelected([]);
    }
    setLoading(false);
  }, [iriOfSelectElement]);
  return (
    <>
      {loading ? (
        <Skeleton />
      ) : (
        <>
          <OutsideClickWrapper
            onClickOutside={() => setShowInput && setShowInput(false)}
          >
            {" "}
            <AsyncSelect
              cacheOptions
              isClearable={isClearable}
              defaultOptions
              isMulti={isMulti}
              styles={customStyles}
              isDisabled={disabled}
              classNamePrefix="react-select"
            //   defaultInputValue={valueInput}
              value={computedSelectedValue}
              onChange={(e: any) => {
                if (isMulti) {
                  saveMultiIri(e);
                } else {
                  saveSingleIri(e?.value || null);
                }
              }}
              loadOptions={getOptions}
            />
          </OutsideClickWrapper>
        </>
      )}
    </>
  );
}
