import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { parseDashboardDetailsPath } from "../../../utils/helper";
import { useSelector } from "react-redux";
import { CombineReducersState } from "../../../redux/store";
import CustomOffCanvas from "../../CustomOffCanvas/CustomOffCanvas";
import FormAccordionEditor from "./FormAccordionEditor";

export default function LinkDetails({ props }) {
  const { fieldToWatch, label } = props.type.editor;
  const [show, setShow] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState<boolean>(false);
  const intl = useIntl();
  // location
  const location = useLocation();
  let pathstart = location.pathname;
  const { pathId, pathName } = parseDashboardDetailsPath(pathstart);
  const state = useSelector(
    (state: CombineReducersState) => state.detailsReducer
  );

  useEffect(() => {
    if (state[pathName]?.[pathId]?.["form"]) {
      setShow(state[pathName][pathId]["form"][fieldToWatch]);
    }
  }, [state]);
  useEffect(() => {
    if (show === false) {
      setShowOffcanvas(show);
    }
  }, [show]);
  return (
    <>
      {show && (
        <div className={clsx("LinkDetails", props.name)}>
          <div
            className={"linkDetails"}
            onClick={() => {
              setShowOffcanvas(true);
            }}
          >
            {intl.formatMessage({ id: label })}{" "}
            <i className="fa-solid fa-link"></i>
          </div>
        </div>
      )}
      {showOffcanvas && (
        <CustomOffCanvas
          direction="left"
          onExit={() => setShowOffcanvas(false)}
          className={clsx(`offcanvas-${props.name}`, "second-panel")}
          showOffcanvas={showOffcanvas}
        >
          <div
            style={{
              flex: 1,
              height: "5px",
              backgroundColor: "#1BC5BD",
            }}
          ></div>
          <FormAccordionEditor props={props}></FormAccordionEditor>
        </CustomOffCanvas>
      )}
    </>
  );
}
