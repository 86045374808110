import { useEffect, useMemo, useRef, useState } from "react";

import AsyncSelect from "react-select/async";
import { apiCall } from "../../utils/serviceCall";
import {
  HTTPMethod,
  eliminateDuplicates,
  getOptionsAsyncSelect,
  handleStore,
  hydra,
  parseDashboardDetailsPath,
  takeIdFromIri,
} from "../../utils/helper";
import OutsideClickWrapper from "../OutsideWrapper/OutsideWrapper";
import { customStyles } from "./styleReactSelect";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { CombineReducersState } from "../../redux/store";
import moment from "moment";

interface autocompleteCompaniesUserProps {
  name: string;
  value: string[];
  type: any;
  typeFilter?: boolean;
  setValue: (name: string, value: any) => void;
  setShowInput?: (value: boolean) => void;
  customFilter?: string;
}

export default function AutocompleteCompaniesUser({
  name,
  value,
  type,
  setValue,
  customFilter,
}: autocompleteCompaniesUserProps) {
  const [showInput, setShowInput] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [completeSelected, setCompleteSelected] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [iriOfSelectElement, setIriOfSelectElement] = useState<string[]>(value);
  const { path }: { path: string } = type.editor;
  const location = useLocation();
  const pathstart = location.pathname;
  const { pathName, pathId } = parseDashboardDetailsPath(pathstart);
  const timerRef = useRef(null);
  const [prevValue, setPrevValue] = useState<any[]>([])
  const userData = useSelector(
    (state: CombineReducersState) => state.userReducer.userData
  )!;
  const group = userData.groupUser?.[0]?.group["@id"];


  const initializeValue = async () => {
    setLoading(true);
    try {
      await getValue();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(()=> {
   if(completeSelected  !== prevValue){
    setPrevValue(completeSelected)
    handleStore(completeSelected.map(el => el.label), {
      pathName,
      pathId,
      formField: name,
    });
   } 
  },[completeSelected])

  const getUsers = async (usersId) => {
    const filteredUserId = usersId.filter(
      (id) => !users?.some((user) => user.id === id)
    );
    if (filteredUserId.length > 0) {
      try {
        const { data } = await apiCall(HTTPMethod.GET, `/users`, {
          params: {
            id: filteredUserId,
            "groupUser.group": group,
            active: true,
            "exists[deletedAt]": false,
          },
        });
        const newUsers = [...users, ...data[hydra.MEMBER]];

        setUsers(newUsers);
        return newUsers;
      } catch (err) {
        console.log(err);
      }
    }
  };
  const getOptions = async (inputValue: string = "") => {
    return getOptionsAsyncSelect(inputValue, timerRef, async () => {
      const defaultParams = {
        "groupUser.group": group,
        active: true,
        "exists[deletedAt]": false,
      };
      const params =
        inputValue === ""
          ? { params: defaultParams }
          : { params: { ["firstName"]: inputValue, ...defaultParams } };
      try {
        const { data } = await apiCall(HTTPMethod.GET, `/users`, params);

        const listUser = data[hydra.MEMBER];

        let response = listUser.map((element) => {
          if (element) {
            return {
              value: element["@id"],
              label: element.fullName,
              isDisabled: completeSelected.some(
                (el) => el.label === element.fullName
              ),
            };
          }
        });
        return response;
      } catch (err) {
        console.log(err);
      }
    });
  };
  const getCurrentUser = (userToFind, listUser) => {
    const userToCheck = listUser ? listUser : users;
    const userToUse = userToCheck.find(
      (user) => user["@id"] === userToFind["@id"]
    );
    return userToUse?.fullName;
  };

  const computedListSelected = ({ element, listUser }): any => {
    if (element && element.length === 0) return [];
    return {
      value: element["@id"],
      label: getCurrentUser(element.user, listUser),
      user: element.user,
    };
  };
  const saveMultiIri = async (elements) => {
    let addElement;

    for (let newElement of elements) {
      let elementoTrovato = completeSelected.find(
        (oldElement) => oldElement.value === newElement.value
      );
      if (!elementoTrovato) {
        addElement = newElement; // Restituisci l'elemento aggiunto
      } else {
        addElement = null;
      }
    }
    if (addElement) {
      setLoading(true);
      // TODO quando si aggiunge va fatto un controlle se gia presente con deleted at settato, quindi PUT altrimenti POST
      try {
        const { data } = await apiCall(HTTPMethod.POST, `/companies_user/create`, {
          user: addElement.value,
          companies: `/api/${pathName}/${pathId}`,
        });

        const iriToSave = [...iriOfSelectElement, data["@id"]];

        setIriOfSelectElement(iriToSave);
        setValue(name, iriToSave);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }
  };
  function findMissingElement(array1, array2) {
    const valuesSet = new Set(array2.map((element) => element.value));
    return array1.find((element) => !valuesSet.has(element.value)) || null;
  }
  const removeElement = async (elements) => {
    setLoading(true);
    let removeElement = findMissingElement(completeSelected, elements);
    if (removeElement) {
      const iriToSave = iriOfSelectElement.filter(
        (el) => el !== removeElement.value
      );
      setIriOfSelectElement(iriToSave);
      setValue(name, iriToSave);
      // TODO qui serve un path su companies_user che setta deleted at
      try {
        await apiCall(
          HTTPMethod.PATCH,
          `/${path}/${removeElement.value.split("/").pop()}`,
          {
            deletedAt: moment().format(),
            alert: null,
          }
        );
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }
  };

  const getValue = async () => {
    setLoading(true);
    const defaultParams = {
      "groupUser.group": group,
      active: true,
      "exists[deletedAt]": false,
    };
    const params = takeIdFromIri(iriOfSelectElement)
      ? { params: { id: takeIdFromIri(iriOfSelectElement), ...defaultParams } }
      : {};

    try {
      const { data } = await apiCall(HTTPMethod.GET, `/${path}`, params);
      const selected = data[hydra.MEMBER];
      const listUserId = eliminateDuplicates(
        selected.map((element) => element.user["@id"].split("/").pop()) 
      );
      const listUser = await getUsers(listUserId);

      const parseSelected = data[hydra.MEMBER]
        .map((element) => {
          if (element) {
            return computedListSelected({ element, listUser });
          }
        })
        .filter((el) => el.label);
      setCompleteSelected(parseSelected);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (Array.isArray(iriOfSelectElement) && iriOfSelectElement.length > 0) {
      initializeValue();
      getOptions();
    } else {
      setCompleteSelected([]);
    }
    setLoading(false);
  }, [iriOfSelectElement, value]);

  return (
    <>
      {showInput ? (
        <OutsideClickWrapper onClickOutside={() => setShowInput(false)}>
          <AsyncSelect
            cacheOptions
            defaultOptions
            isClearable={false}
            isMulti
            isLoading={loading}
            styles={customStyles}
            classNamePrefix="react-select"
            value={completeSelected}
            onChange={(e: any, { action }) => {
              if (action === "remove-value") {
                removeElement(e);
              }
              if (action === "select-option") {
                saveMultiIri(e);
              }
            }}
            loadOptions={getOptions}
          />
        </OutsideClickWrapper>
      ) : (
        <div
          className="readonly-companiesuser cursor-pointer"
          onClick={() => setShowInput(true)}
        >
          {completeSelected
            .map((el, index) => el.label)
            .filter((el) => el)
            .join(", ")}
        </div>
      )}
    </>
  );
}
