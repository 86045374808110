import React from "react";
import {Badge} from "react-bootstrap";
import CellInterface from "./CellInterface";
import moment from "moment";

const CellAsFormat = ({value, type, element}: CellInterface) => {

    const renderSwitchFormat = (format) => {
        switch (format) {
            case 'date-time':
                return <>
                    <h3>{moment(value).format("DD/MM/YYYY")}</h3>
                </>;
            default:
                return  <><p>CellAsFormat {format}</p><h4>{value}</h4></>

        }
    }


    return (<>
        <div className={'CellAsFormat'}>
            {renderSwitchFormat(type.format)}
        </div>
    </>);
}

export default CellAsFormat;