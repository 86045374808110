import React, {useCallback} from "react";

import {isIriReference, takeNameFromIri, uniteValues} from "../../utils/helper";
import CellInterface from "./CellInterface";
import {useSelector} from "react-redux";
import {CombineReducersState} from "../../redux/store";

const CellAsIri = ({value, type, element, key}: CellInterface) => {
    const stateReference = useSelector((state: CombineReducersState) => state.referenceReducer)


    const mainField = (el: any, type: any) => {

        const mainField = type.editor?.mainField ? type.editor.mainField : 'name'
        const mainFieldReference = type.editor?.mainFieldReference

        if (el[mainField]) {
            if (mainFieldReference) {
                const mainFieldReferencePath = mainFieldReference.path
                const mainFieldReferenceFieldName = mainFieldReference.field
                const currentStateReference = [mainFieldReferencePath]
                const iri = el[mainFieldReferenceFieldName]
                const findValue = currentStateReference.find((el) => {
                    return el['@id'] === iri
                })
                if (findValue && currentStateReference) {

                    return uniteValues(findValue, mainFieldReference.mainField)
                } else {
                    return null
                }
            }
            return el[mainField]
        } else {
            return null
        }
    }
    const renderedContent = useCallback((type, currentReference, element, key) => {
        if (type.type === 'string') {
            const findEl = currentReference.find((el) => el['@id'] === element[key]);
            return findEl ? <>{mainField(findEl, type)}</> : '-->'+element[key];
        } else if (type.type === 'array') {
            return (
                <>
                    {element[key].length > 0 &&
                        element[key].map((el, index) => {
                            const findEl = currentReference.find((elRef) => elRef['@id'] === el);

                            if (findEl && mainField(findEl, type)) {
                                return (
                                    <div key={index}>
                                        {mainField(findEl, type) + ','}
                                    </div>
                                );
                            }
                            return <p>array</p>;
                        })}
                </>
            );
        }
        return <p>boh</p>;
    }, [stateReference]);


    const name = takeNameFromIri(element)
    if (name) {
        const currentReference = stateReference[name]

        if (currentReference) {
            const renderedContentComponent = renderedContent(type, currentReference, element, key)
            return (<>
                <div className={'cellAsIri'}>
                    {renderedContentComponent}
                </div>
            </>);
        }
    }

}

export default CellAsIri;