import { FC, useState, useRef } from "react";
import { useIntl } from "react-intl";
import {
  isIriReference,
  parseDashboardDetailsPath,
  typeInput,
} from "../../utils/helper";
import Autocomplete from "../Input/Autocomplete";
import TagifyWraper from "../TagifyWrapper/TagifyWrapper";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment";
import { RadioInput } from "../Input/RadioInput";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { CombineReducersState } from "../../redux/store";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { customStyles2 } from "../Input/styleReactSelect.js";
interface fieldProps {
  value: any;
  form?: any;
  output: (name: string, value: any) => void;
  fieldInfo: any;
  name: string;
  nameEntity: string;
  formErrors?: { [key: string]: string };
  id?: number;
  disabled?: boolean;
  accordion?: boolean;
  translate?: string;
  required?: boolean;
}
export const Field: FC<fieldProps> = (props) => {
  const intl = useIntl();
  const state = useSelector(
    (state: CombineReducersState) => state.detailsReducer
  );
  const location = useLocation();
  const pathstart = location.pathname;
  const hashPath = location.hash;
  const { pathId, pathName, hashName, hashId } = parseDashboardDetailsPath(
    pathstart,
    hashPath
  );
  let pathToUtilize = {
    pathName: pathName,
    pathId: pathId,
  };
  if (hashPath !== "") {
    pathToUtilize.pathName = hashName;
    pathToUtilize.pathId = hashId;
  }
  const getData = () => {
    const listOptions =
      state[pathToUtilize.pathName]?.[pathToUtilize.pathId]?.form?.listResponse || [];
    const listOptionsFormatted = listOptions.map((el) => {
      return { value: el, label: el.name };
    });
    return listOptionsFormatted;
  };
  const {
    value,
    output,
    fieldInfo,
    name,
    nameEntity,
    id,
    disabled = false,
    form,
    translate,
    accordion,
  } = props;
  const currentError = props.formErrors && props.formErrors[name];
  const timerRef = useRef<any>(null);
  const formatedDate =
    typeInput[fieldInfo.format || fieldInfo.type] === "datetime-local" && value
      ? moment(new Date(value)).format("YYYY-MM-DD HH:mm:ss")
      : null;
  const [currentValue, setCurrentValue] = useState(
    formatedDate || (value ? value : value === false ? value : "")
  );

  function keyUp() {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      output(name, currentValue);
    }, 500);
  }
  function keyDown() {
    clearTimeout(timerRef.current);
  }

  const propsInput = {
    name: name,
    value: currentValue,
    checked: currentValue,
    data: currentValue,
  };

  const renderCkEditor = () => {
    return (
      <CKEditor
        editor={ClassicEditor}
        {...propsInput}
        onChange={(event, editor) => {
          const newData = editor.getData();
          output(name, newData);
          setCurrentValue(newData);
        }}
      />
    );
  };
  const propsSelect = {
    name: name,
    value: currentValue === "" ? null : currentValue,
  };
  const handleBlur = () => {
    keyUp();
  };
  const convertInNumber = (value, type) => {
    return type === "number" ? +value : value;
  };
  const handleSave = (value, type) => {
    const valueToSave = convertInNumber(value, type);
    output(name, valueToSave);
    setCurrentValue(value);
  };
  const handleInputToUse = () => {
    if (fieldInfo) {
      if (fieldInfo.form) {
        switch (fieldInfo.form.name) {
          case "SelectField":
            const listOptions = Array.isArray(fieldInfo.form.options)
              ? fieldInfo.form.options
              : Object.values(fieldInfo.form.options);
            return (
              <select
                {...propsSelect}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  handleSave(e.target.value, fieldInfo.type);
                }}
                // onBlur={propsSelect.value && handleBlur}
              >
                {fieldInfo.form.emptyOptions && <option value={""}></option>}
                {listOptions &&
                  listOptions.map((option, idx) => (
                    <option
                      selected={fieldInfo.form.defaultOptions === option}
                      key={`${name}-${option}-${idx}`}
                      value={option}
                    >
                      {fieldInfo.form.translate
                        ? intl.formatMessage({
                            id: `${fieldInfo.form.translate}.${option}`,
                          })
                        : option}
                    </option>
                  ))}
              </select>
            );
          case "RadioField":
            return (
              <RadioInput
                note={form && form[`${name}Note`]}
                name={name}
                value={value}
                setValue={({ name, value }) => {
                  output(name, value);
                  setCurrentValue(value);
                }}
              />
            );
          case "SelectStore":
            const listData: [] = getData();
            const selected: any = listData.find(el => el['value']['@id'] === currentValue['@id']) || null;
            return (
              <>
                {getData() && (
                  <Select
                    name={name}
                    styles={customStyles2}
                    options={getData()}
                    value={{ value: selected, label: selected?.label || '' }}
                    onChange={(value) => {
                      output(name, value);
                      setCurrentValue(value);
                    }}
                  ></Select>
                )}
              </>
            );
          case "TextareaField": {
            return (
              <>
                <textarea
                  value={currentValue}
                  onChange={(e) => {
                    output(name, e.target.value);
                    setCurrentValue(e.target.value);
                  }}
                />
              </>
            );
          }
        }
      }
      if (fieldInfo.editor && isIriReference(fieldInfo)) {
        if (name !== "companyCurators") {
          return (
            <Autocomplete
              {...propsInput}
              disabled={disabled}
              type={fieldInfo}
              setValue={output}
            />
          );
        } else {
          return;
        }
      }
      if (fieldInfo.editor) {
        if (fieldInfo.editor.name === "TagifyWrapper") {
          return (
            <TagifyWraper
              {...propsInput}
              onChange={(value) => {
                output(name, value);
                setCurrentValue(value);
              }}
            ></TagifyWraper>
          );
        }
        if (
          fieldInfo.editor.name === "WysiwygInput" ||
          fieldInfo.form?.name === "WysiwygInput"
        ) {
          return (
            <>
              {accordion ? (
                // <div
                //   className="accordion accordion-icon-toggle"
                //   id={`kt_accordion_${name}`}
                // >
                <div className="mb-5">
                  <div
                    className={clsx(
                      "accordion-header py-3 d-flex align-items-center gap-4"
                    )}
                    // data-bs-toggle="collapse"
                    // data-bs-target={`#kt_accordion_${name}_item_${id}`}
                  >
                    {/*<span className="accordion-icon">*/}
                    {/*  <i className="fa-solid fa-chevron-right"></i>*/}
                    {/*</span>*/}
                    <label>
                      {intl.formatMessage({ id: `${nameEntity}.${name}` })}
                    </label>
                  </div>
                  {/*<div*/}
                  {/*  id={`kt_accordion_${name}_item_${id}`}*/}
                  {/*  className={clsx("fs-6 collapse")}*/}
                  {/*  data-bs-parent={`#kt_accordion_${name}`}*/}
                  {/*>*/}
                  {renderCkEditor()}
                  {/*</div>*/}
                  {/*</div>*/}
                </div>
              ) : (
                <>{renderCkEditor()}</>
              )}
            </>
          );
        }
      }
      if (fieldInfo.type === "boolean") {
        return (
          <input
            className="form-check-input p-3"
            {...propsInput}
            type={typeInput[fieldInfo.type]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              output(name, e.target.checked);
              setCurrentValue(e.target.checked);
            }}
          ></input>
        );
      }
    }
    if (fieldInfo)
      return (
        <input
          onKeyUp={() => keyUp()}
          onKeyDown={() => keyDown()}
          {...propsInput}
          type={typeInput[fieldInfo.format || fieldInfo.type]}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleSave(e.target.value, fieldInfo.type)
          }
          onBlur={handleBlur}
        ></input>
      );
  };

  return (
    <>
      {accordion !== true && (
        <label>
          {intl.formatMessage({ id: `${translate || nameEntity}.${name}` })}
          {props.required && <span className="text-danger">*</span>}
        </label>
      )}
      {handleInputToUse()}
      {currentError && <p className="error-form">{currentError}</p>}
    </>
  );
};
