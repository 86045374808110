import React, { FC, useEffect, useState } from "react";
import * as yup from "yup";
import CustomButton from "../buttons/CustomButton";
import {
  createYupSchema,
  filterObject,
  isObjectEquals,
  parseDashboardDetailsPath,
} from "../../utils/helper";
import { useIntl } from "react-intl";

import { Field } from "../FormModal/Field";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { CombineReducersState } from "../../redux/store";
import { useLocation } from "react-router";
import {
  DetailsState,
  actionsTypeDetails,
} from "../../redux/stores/details/detailsReducer";
import { useDispatch } from "react-redux";
interface FormProps {
  fields: { [key: string]: any };
  data?: { [key: string]: any };
  nameEntity: string;
  onSave?: (e: { [key: string]: any }) => void;
  onStatusChange?: (e: boolean) => void;
  stepLabel?: string[];
  listOpenAccordion?: number[];
}

interface fieldSchema {
  type: null | string;
  attributes: string[];
}

const Form2: FC<FormProps> = ({
  fields,
  data = {},
  nameEntity,
  onSave,
  onStatusChange,
  stepLabel,
  listOpenAccordion,
}) => {
  const intl = useIntl();
  const state = useSelector(
    (state: CombineReducersState) => state.detailsReducer
  );

  const createYupSpecific = (fields: { [key: string]: fieldSchema }): any => {
    let result = Object.keys(fields)
      .map((fieldkey) => {
        let field: any = fields[fieldkey];
        let arrayOfControlls: fieldSchema = { type: null, attributes: [] };
        if (field.type) {
          arrayOfControlls.type = field.type;
        }

        if (field.required) {
          arrayOfControlls.attributes.push("required");
        }
        return { [fieldkey]: arrayOfControlls };
      })
      .reduce((acc, obj) => ({ ...acc, ...obj }), {});
    return result;
  };
  // filtra i fields in base a form.hidden
  const filteredFields = filterObject(fields, (value) => !value.form?.hidden);
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [disableStatusButton, setDisableStateButton] = useState<boolean>(true);
  const [steps, setSteps] = useState<{ [key: string]: any }[]>([]);
  const [textInput, setTextInput] = useState<string>(
    "Trascina i file o premi Scegli File"
  );

  const handleFileInputChange = (e: any) => {
    if (e.target.files) {
      const files: FileList = e.target.files;

      if (files.length === 1) {
        const file = files[0];
        setForm((prevState) => ({
          ...prevState,
          file: [file],
        }));
        setTextInput(`Hai caricato ${files.length} file`);
      } else {
        const fileArray = Array.from(files).map((file) => ({ file }));
        fileArray.forEach((element) => {
          setForm((prevState) => ({
            ...prevState,
            file: [...(prevState.files || []), ...fileArray],
          }));
        });
        setTextInput(`Hai caricato ${files.length} file`);
      }
    }
  };
  const schema = yup
    .object()
    .shape(createYupSchema(createYupSpecific(filteredFields)));
  const checkValidation = () => {
    schema
      .validate(form, { abortEarly: false })
      .then(() => {
        setDisableStateButton(false);
        setFormErrors({});
      })
      .catch((validationErrors: yup.ValidationError) => {
        setDisableStateButton(true);
        parsedErrorForm(validationErrors);
      });
  };
  const [order, setOrder] = useState<string[][]>();
  const parsedErrorForm = (errors: yup.ValidationError) => {
    const parsedErrors = errors.inner.reduce((errorList, error) => {
      if (error.path) {
        errorList[error.path] = error.message;
      }
      return errorList;
    }, {});
    setFormErrors(parsedErrors);
  };
  const [form, setForm] = useState<any>(data);
  useEffect(() => {
    if (!isObjectEquals(data, form)) {
      checkValidation();
    }
  }, [form]);
  useEffect(() => {
    setForm(data);
  }, [data]);
  const handleUpdate = (nameInput: string, value: any) => {
    setForm({ ...form, ...{ [nameInput]: value } });
  };
  function createOrder(obj) {
    const result: any = [];
    const valueMap = {};
    const noRow: any = [];
    for (const key in obj) {
      const value = parseFloat(obj[key].form?.row); // Converto il valore in un numero
      if (!isNaN(value)) {
        if (!valueMap[value]) {
          valueMap[value] = [key];
        } else {
          valueMap[value].push(key);
        }
      } else {
        noRow.push(key);
      }
    }

    const sortedValues = Object.keys(valueMap)
      .map(parseFloat)
      .sort((a, b) => a - b);

    sortedValues.forEach((value) => {
      result.push(valueMap[value]);
    });
    noRow.forEach((key) => {
      result.push([key]);
    });
    setOrder(result);
  }
  function createStep(obj: Record<string, any>) {
    const step1Objects = {};
    const step2Objects = {};
    const noStepObjects = {};

    for (const key in obj) {
      const item = obj[key];
      if (item.form && item.form.step) {
        const step = item.form?.step;
        const hidden = item.form?.hidden;
        if (step === 1) {
          step1Objects[key] = item;
        } else if (step === 2) {
          step2Objects[key] = item;
        } else {
          if (!hidden) {
            noStepObjects[key] = item;
          }
        }
      } else {
        noStepObjects[key] = item;
      }
    }
    const resultArray = [step1Objects, step2Objects, noStepObjects];
    setSteps(resultArray);
  }
  useEffect(() => {
    createOrder(filteredFields);
    createStep(filteredFields);
  }, [fields]);
  return (
    <>
      <>
        {fields && order && (
          <>
            {steps.map((step, idx) => (
              <>
                {Object.keys(step).length > 0 && (
                  <div
                    key={`accordion-${idx}`}
                    className="accordion accordion-icon-toggle"
                    id={`kt_accordion_${idx}_${nameEntity}`}
                  >
                    <div className="mb-5">
                      <div
                        className={clsx(
                          "accordion-header py-3 d-flex gap-5",
                          listOpenAccordion && listOpenAccordion.includes(idx)
                            ? ""
                            : "collapsed"
                        )}
                        data-bs-toggle="collapse"
                        data-bs-target={`#kt_accordion_${idx}_${nameEntity}_item_${nameEntity}`}
                      >
                        <span className="accordion-icon">
                          <i className="fa-solid fa-chevron-right"></i>
                        </span>
                        {stepLabel && stepLabel[idx] && (
                          <span className="dynamic-editor-label">
                            {intl.formatMessage({ id: stepLabel[idx] })}
                          </span>
                        )}
                      </div>
                      <div
                        id={`kt_accordion_${idx}_${nameEntity}_item_${nameEntity}`}
                        className={clsx(
                          "fs-6 collapse",
                          listOpenAccordion && listOpenAccordion.includes(idx)
                            ? "show"
                            : ""
                        )}
                        data-bs-parent={`#kt_accordion_${idx}_${nameEntity}`}
                      >
                        <div className="container">
                          {order.map((row) => {
                            return (
                              <div className="row">
                                {row.map((fieldName, idx) => {
                                  const fieldInfo = step[fieldName];
                                  const colSize =
                                    row.length === 1 ? "col-12" : "col-6";
                                  return (
                                    <>
                                      {fieldInfo && (
                                        <div
                                          className={clsx(
                                            colSize,
                                            "input-form-field",
                                            "mx-0"
                                          )}
                                          key={`${fieldName}`}
                                        >
                                          <Field
                                            required={fields[fieldName].required}
                                            nameEntity={nameEntity}
                                            formErrors={formErrors}
                                            name={fieldName}
                                            form={form}
                                            id={idx}
                                            accordion={
                                              fieldInfo?.editor?.name ===
                                              "WysiwygInput"
                                            }
                                            value={form[fieldName]}
                                            output={handleUpdate}
                                            fieldInfo={fieldInfo}
                                          ></Field>
                                        </div>
                                      )}
                                    </>
                                  );
                                })}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {idx < 1 && (
                  <div
                    style={{
                      flex: 1,
                      height: "5px",
                      backgroundColor: "#1BC5BD",
                    }}
                  ></div>
                )}
              </>
            ))}
            {/* <div className="d-flex justify-content-between align-items-center">
              <input
                type="file"
                id="fileInput"
                style={{
                  color: "transparent",
                  position: "relative",
                  backgroundColor: "#f1faff",
                  border: "1px dashed #3E97FF",
                  width: "400px",
                  marginBottom: "20px",
                }}
                name="file"
                onChange={(e) => handleFileInputChange(e)}
                multiple
              ></input>
              <label
                htmlFor="fileInput"
                style={{
                  position: "relative",
                  border: "none",
                  cursor: "pointer",
                  zIndex: "1",
                }}
              >
                {textInput}
              </label>
            </div> */}
          </>
        )}
      </>

      <CustomButton
        disable={disableStatusButton}
        onClick={() => onSave && onSave(form)}
        className="my-5"
      >
        {intl.formatMessage({ id: "MAIN.Salva" })}
      </CustomButton>
    </>
  );
};

export default Form2;
