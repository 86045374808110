/* Editors */
import Tabs from "./Editors/TabsView";
import BasicInput from "./Editors/BasicInput";
import DateTimeInput from "./Editors/DateTimeInput";
import WysiwygEditor from "./Editors/WysiwygEditor";
import CurrencyEditor from "./Editors/CurrencyEditor";
import TextAreaEditor from "./Editors/TextAreaEditor";
import IconBooleanEditor from "./Editors/IconBooleanEditor";
import InputAsButtonEditor from "./Editors/InputAsButtonEditor";
import SelectAsButtonEditor from "./Editors/SelectEditor";

export interface EditorProps {
    typeBasicInput?: string
    data: any
    name: string
    setValue: (name: string, value: any) => void
    setShowInput: (show: boolean) => void
    type?: {
        editor: {
            format?: string;
            icon?: string
            color?: string
            options?: any[]
            default?: string
            reloadAlarms?: boolean
        }
    }
}

/**
 * Default props for the editor
 * Placeholder for the interface
 */
export const DefaultEditorProps: EditorProps = {
    data: null,
    name: 'no-name-provided',
    setValue: function (name: string, value: any): void {
        throw new Error(`Function not implemented. ${name} ${value}`)
    },
    setShowInput: function (show: boolean): void {
        throw new Error(`Function not implemented. ${show}`)
    },
}

export const DynamicComponents = {
    Tabs,
    BasicInput,
    DateTimeInput,
    WysiwygEditor,
    CurrencyEditor,
    TextAreaEditor,
    IconBooleanEditor,
    InputAsButtonEditor,
    SelectAsButtonEditor,
};

export enum DynamicComponentType {
    Tabs = 'Tabs',
    id = 'CurrencyEditor',
    createdAt = 'DateTimeInput',
    updatedAt = 'DateTimeInput',
    deletedAt = 'DateTimeInput',
  }


export enum DynamicComponentNameFields {
  CompanyCurators = "companyCurators"
}
