import React, { Fragment, useEffect, useState } from "react";
import OutsideClickWrapper from "../../OutsideWrapper/OutsideWrapper";
import { useIntl } from "react-intl";
import { store } from "../../../redux/store";
import { parseDataFromGlobalContext } from "../../../redux/stores/rootContext";

import EmailModal from "../../FormModal/EmailModal";

interface ListEditorProps {
  props: any; // Sostituire 'any' con il tipo appropriato
  name: string; // Sostituire con il tipo appropriato
  setValue: (name: string, value: string) => void; // Sostituire con il tipo appropriato
}

export default function ListEditor({ props }: ListEditorProps) {
  const [data, setData] = useState<{ [key: string]: string }>({});
  const [showInput, setShowInput] = useState(false);
  const globalState = store.getState();
  const [currentEmail, setCurrentEmail] = useState<string>("");
  const allEntity = parseDataFromGlobalContext(globalState.rootContext.apiDocs);
  const findEntity = allEntity
    ? allEntity.find((entity) => entity.name === "email")
    : null;
  const intl = useIntl();
  const addElement = () => {
    const newIndex = Object.keys(data).length.toString();
    setData((prevData) => ({
      ...prevData,
      [newIndex]: "",
    }));
  };
  const removeElement = (index) => {
    let cloneData = data;
    delete cloneData[index];
    setData({ ...cloneData });
  };
  const handleSelectionEmail = (
    e: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    e.stopPropagation();
    setCurrentEmail(value);
    setShowModal(true);
  };

  const parseObjectToString = (dataObj: { [key: string]: string }) => {
    const deleteEmpty = Object.values(dataObj).filter((el) => el.trim() !== "");
    return Object.values(deleteEmpty).join(",");
  };

  const handleSend = () => {
    props.setValue(props.name, parseObjectToString(data));
    setShowInput(false);
  };

  const EmptyEl = () => (
    <>
      <i
        onClick={() => {
          addElement();
          setShowInput(true);
        }}
        className="fa-solid fa-plus iconStandard"
      ></i>
    </>
  );
  useEffect(() => {
    const newData = {};
    if (props.data && props.data.includes(",")) {
      const emailArray = props.data.split(",");
      emailArray.forEach((email, index) => {
        newData[index.toString()] = email.trim();
      });
    } else if (props.data) {
      newData["0"] = props.data.trim();
    }

    const indexToDelete = Object.keys(newData).filter(
      (key) => newData[key].data === ""
    );
    indexToDelete.forEach((el) => delete newData[el]);
    setData(newData);
  }, [props.data]);
  const [showModal, setShowModal] = useState(false); // Aggiungi questa riga
  useEffect(() => {
    if (showInput === false) {
      let cloneData = data;
      Object.keys(cloneData).forEach((key) => {
        if (cloneData[key].trim() === "") {
          delete cloneData[key];
        }
      });
      if (Object.keys(cloneData).length !== Object.keys(data).length) {
        setData(cloneData);
      }
    }
  }, [showInput]);
  return (
    <>
      {showInput ? (
        <OutsideClickWrapper
          className="d-flex flex-column gap-1"
          onClickOutside={() => {
            handleSend();
          }}
        >
          {Object.keys(data).map((key) => (
            <Fragment key={key}>
              <div className="d-flex align-items-center gap-2">
                <input
                  style={{
                    position: "relative",
                    borderRadius: "12px",
                    padding: "2px 8px",
                    backgroundColor: "#F0F3F5",
                    color: "#000",
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                  type="text"
                  id={`emailEditorInput-${key}`}
                  value={data[key]}
                  className="form-control form-control-solid"
                  placeholder={intl.formatMessage({id: 'Action.addTag'})}
                  onChange={(event) => {
                    const { id, value } = event.target;
                    const dataIndex = parseInt(id.split("-")[1]);
                    setData((prevData) => ({
                      ...prevData,
                      [dataIndex]: value,
                    }));
                  }}
                />
                {Object.keys(data).length > 1 && (
                  <i
                    onClick={() => removeElement(key)}
                    className="fa-solid fa-minus iconStandard"
                  ></i>
                )}
              </div>
              {key === Object.keys(data)[Object.keys(data).length - 1] && (
                <p>
                  <i
                    onClick={addElement}
                    className="fa-solid fa-plus iconStandard iconAdd me-2"
                  ></i>
                  <span>{intl.formatMessage({id: 'undefined.CREATE'})}</span>
                </p>
              )}
            </Fragment>
          ))}
        </OutsideClickWrapper>
      ) : (
        <div className="d-flex flex-column gap-2">
          {Object.values(data).map((el, key) => (
            <div
              key={`${el}-${key}`}
              onClick={() => setShowInput(true)}
              className="d-flex gap-2"
            >
              <div>{el}</div>
              {props.type.editor.path && (
                <i
                  onClick={(e) => {
                    handleSelectionEmail(e, el);
                  }}
                  className="fa-solid fa-share iconStandard"
                ></i>
              )}
            </div>
          ))}
          {Object.keys(data).length === 0 && <EmptyEl />}
        </div>
      )}
      {showModal && (
        <EmailModal
          entity={findEntity}
          show={showModal}
          customPath="/email/send"
          data={{ to: [currentEmail] }}
          type="post"
          handleClose={() => {
            setShowModal(false);
          }}
        ></EmailModal>
      )}
    </>
  );
}
