import clsx from "clsx";
import { FC, useEffect, useState } from "react";
import { WithChildren } from "../../vendor/_metronic/helpers";

/* Metronic */
import { ILayout, useLayout } from "../../vendor/_metronic/layout/core";

/* Custom */
import { SidebarLogo } from "./Logo";

const Sidebar: FC<WithChildren> = ({ children }) => {
  const { config } = useLayout()

  useEffect(() => {
    updateDOM(config)
  }, [config])
  
  return (
    <>
      {(config.layoutType === 'dark-sidebar' || config.layoutType === 'light-sidebar') && (
        <div id='kt_app_sidebar' className={clsx('app-sidebar', config.app?.sidebar?.default?.class)} >
          
          <div className="app-sidebar-logo px-6" id="kt_app_sidebar_logo">
            <SidebarLogo logoRef='/media/logos/logo01.png' />
            <div id="kt_app_sidebar_toggle" className="app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate " data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body" data-kt-toggle-name="app-sidebar-minimize">
              <i className="ki-duotone ki-double-left fs-2 rotate-180 active" ><span className="path1"></span><span className="path2"></span></i>
            </div>
          </div>

          <div className='app-sidebar-menu overflow-hidden flex-column-fluid'>
            <div className='app-sidebar-manu flex-column-fluid active'>
              <div
                id='kt_app_sidebar_menu_wrapper'
                className='app-sidebar-wrapper hover-scroll-overlay-y my-5'
                data-kt-scroll='true'
                data-kt-scroll-activate='true'
                data-kt-scroll-height='auto'
                data-kt-scroll-dependencies='#kt_app_sidebar_logo, #kt_app_sidebar_footer'
                data-kt-scroll-wrappers='#kt_app_sidebar_menu'
                data-kt-scroll-offset='5px'
                data-kt-scroll-save-state='true'
              >
                <div
                  className='menu menu-column menu-rounded menu-sub-indention px-3'
                  id='#kt_app_sidebar_menu'
                  data-kt-menu='true'
                  data-kt-menu-expand='false'
                >
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}






export default Sidebar;

const updateDOM = (config: ILayout) => {
  if (config.layoutType === 'dark-sidebar' || config.layoutType === 'light-sidebar') {
    /* Alignment with other components on the screen */
    if (config.app?.sidebar?.default?.push) {
      if (config.app?.sidebar?.default?.push?.header) {
        document.body.setAttribute('data-kt-app-sidebar-push-header', 'true')
      }

      if (config.app?.sidebar?.default?.push?.toolbar) {
        document.body.setAttribute('data-kt-app-sidebar-push-toolbar', 'true')
      }

      if (config.app?.sidebar?.default?.push?.footer) {
        document.body.setAttribute('data-kt-app-sidebar-push-footer', 'true')
      }
    }

    if (config.app?.sidebar?.default?.stacked) {
      document.body.setAttribute('app-sidebar-stacked', 'true')
    }

    document.body.setAttribute('data-kt-app-sidebar-enabled', 'true')
    document.body.setAttribute(
      'data-kt-app-sidebar-fixed',
      config.app?.sidebar?.default?.fixed?.desktop?.toString() || ''
    )

    const appSidebarDefaultDrawerEnabled = config.app?.sidebar?.default?.drawer?.enabled
    let appSidebarDefaultDrawerAttributes: { [attrName: string]: string } = {}
    if (appSidebarDefaultDrawerEnabled) {
      appSidebarDefaultDrawerAttributes = config.app?.sidebar?.default?.drawer?.attributes as {
        [attrName: string]: string
      }
    }

    const appSidebarDefaultStickyEnabled = config.app?.sidebar?.default?.sticky?.enabled
    let appSidebarDefaultStickyAttributes: { [attrName: string]: string } = {}
    if (appSidebarDefaultStickyEnabled) {
      appSidebarDefaultStickyAttributes = config.app?.sidebar?.default?.sticky?.attributes as {
        [attrName: string]: string
      }
    }

    setTimeout(() => {
      const sidebarElement = document.getElementById('kt_app_sidebar')
      // sidebar
      if (sidebarElement) {
        const sidebarAttributes = sidebarElement
          .getAttributeNames()
          .filter((t) => t.indexOf('data-') > -1)
        sidebarAttributes.forEach((attr) => sidebarElement.removeAttribute(attr))

        if (appSidebarDefaultDrawerEnabled) {
          for (const key in appSidebarDefaultDrawerAttributes) {
            if (appSidebarDefaultDrawerAttributes.hasOwnProperty(key)) {
              sidebarElement.setAttribute(key, appSidebarDefaultDrawerAttributes[key])
            }
          }
        }

        if (appSidebarDefaultStickyEnabled) {
          for (const key in appSidebarDefaultStickyAttributes) {
            if (appSidebarDefaultStickyAttributes.hasOwnProperty(key)) {
              sidebarElement.setAttribute(key, appSidebarDefaultStickyAttributes[key])
            }
          }
        }
      }
    }, 0)
  }
}