import React, { useEffect, useState } from 'react'
import InputMask from 'react-input-mask'
import FocusWrapper from '../../FocusWrapper/FocusWrapper'

export default function CurrencyEditor({ props }) {
  const [data, setData] = useState(props.data)

  const handleValueChange = (e) => {
    const inputValue = e.target.value.replace(/[^0-9.]/g, '') // Remove non-numeric and non-decimal characters
    setData(inputValue)
  }

  return (
    <>
      <FocusWrapper>
        <InputMask
          value={data}
          mask={`${props.type.editor.currency} 9,999,999`}
          maskChar={null} // Remove this line if you want to display the mask character
          // formatChars={{
          //   '9': '[0-9]',
          //   'a': '[A-Za-z]',
          //   '*': '[A-Za-z0-9]'
          // }}
          onChange={handleValueChange}
          onBlur={() => {
            props.setValue(props.name, data)
            props.setShowInput(false)
          }}
        /></FocusWrapper>
    </>
  )
}