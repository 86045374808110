import QueryString from "qs";

export interface FilterState {
  [key: string]: string;
}
const initialFilterState: FilterState = {};
export enum actionsTypeFilter {
  set = "SET_FILTER_STATE",
  clear = "CLEAR_FILTER_STATE",
}
interface payloadFilter {
  name: string;
  value: any;
}
export interface FilterReducerAction {
  type: actionsTypeFilter;
  payload: payloadFilter;
}
function implementsPayloadFilter(obj: any): obj is payloadFilter {
  return "name" in obj && "value" in obj;
}
export default function filterReducer(
  state: FilterState = initialFilterState,
  action: FilterReducerAction
): FilterState {
  const payload = action.payload;

  const name = payload?.name;
  const value: string = payload?.value;
  // console.log(action.type,name,value)
  switch (action.type) {
    case actionsTypeFilter.set:
      return {
        ...state,
        ...{ [name]: value },
      };

    case actionsTypeFilter.clear: {
      if (payload) {
        return { [name]: QueryString.stringify(value) };
      }
      return initialFilterState;
    }
    default:
      return state;
  }
}
