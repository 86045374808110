import { useState } from "react";
import FocusWrapper from "../../FocusWrapper/FocusWrapper";
import { HTTPMethod, parseDashboardDetailsPath } from "../../../utils/helper";
import { apiCall } from "../../../utils/serviceCall";
import { useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

export default function BasicInput({ props, typeBasicInput }) {
  const [data, setData] = useState<number | string>(props.data);
  const [loading, setLoading] = useState<boolean>(false);
  const editor = props.type?.editor;
  const location = useLocation();
  let pathstart = location.pathname;

  const { pathId, pathName } = parseDashboardDetailsPath(pathstart);

  const toogleSaveChange = async () => {
    const updateValue = data;

    const updateByItself: boolean | "makeGet" = editor?.path ? "makeGet" : false;
    if (editor?.path) {
      setLoading(true);
      try {
        await apiCall(
          HTTPMethod.POST,
          `/${pathName}/${pathId}/${editor.path}`,
          {
            [editor.field]: updateValue,
          }
        );
        props.setValue(props.name, updateValue, updateByItself);
        props.setShowInput(false);
        return 
      } catch (error) {
        console.log(error);
        return;
      } finally {
        setLoading(false);
      }
    }
    props.setValue(props.name, updateValue);
    props.setShowInput(false);
  };
  return (
    <>
      {loading ? (
        <div className="skeleton-wrapper">
          <Skeleton></Skeleton>
        </div>
      ) : (
        <FocusWrapper>
          <input
            type={typeBasicInput}
            value={data}
            className="form-control  basiInput form-control-solid"
            onChange={(e) => {
              setData(
                typeBasicInput === "number" ? +e.target.value : e.target.value
              );
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                toogleSaveChange();
              }
            }}
            onBlur={() => {
              toogleSaveChange();
            }}
          />
        </FocusWrapper>
      )}
    </>
  );
}
