import Skeleton from "react-loading-skeleton";
import { useEffect, useMemo, useRef, useState } from "react";

import AsyncSelect from "react-select/async";
import defaultFilter from "../../../../json/defaultFilter.json";

import { useIntl } from "react-intl";
import {
  typeOfObject,
  getOptionsAsyncSelect,
  HTTPMethod,
  hydra,
  uniteValues,
  takeIdFromIri,
} from "../../../../utils/helper";
import { apiCall } from "../../../../utils/serviceCall";
import OutsideClickWrapper from "../../../OutsideWrapper/OutsideWrapper";
import EmptyPlaceholder from "../../../EmptyPlaceholder/EmptyPlaceholder";
interface autocompleteProps {
  name: string;
  value: string | string[];
  type: any;
  nameField: string;
  nameDetail: string;
  typeFilter?: boolean;
  setValue: (name: string, value: any) => void;
  setShowInput?: (value: boolean) => void;
  customFilter?: string;
  filterToUse?: string;
  disabled?: boolean;
}

export default function LocationAutocomplete({
  name,
  value,
  type,
  setValue,
  customFilter,
  filterToUse,
  disabled,
  nameDetail,
  nameField,
}: autocompleteProps) {
  const intl = useIntl();
  const [loading, setLoading] = useState<boolean>(true);
  const [completeSelected, setcompleteSelected] = useState<
    typeOfObject | typeOfObject[] | null
  >(null);
  const [iriOfSelectElement, setIriOfSelectElement] = useState<
    string | string[]
  >(value);
  const [showInput, setShowInput] = useState<boolean>(false);
  const { path }: { path: string } = type.editor;
  const timerRef = useRef(null);
  const [valueInput, setValueInput] = useState<string>(
    customFilter ? intl.formatMessage({ id: `allFilter${name}` }) : ""
  );

  interface selectValueStandard {
    value: string;
    label: string;
  }

  const initializeValue = (): void => {
    setLoading(true);
    try {
      getValue();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const getOptions = async (inputValue: string = "") => {
    return getOptionsAsyncSelect(inputValue, timerRef, async () => {
      const optionalParams = defaultFilter[path] || {};
      const params = inputValue === "" ? {} : { ["search"]: inputValue };

      try {
        const { data } = await apiCall(HTTPMethod.GET, `/${path}`, {
          params: { ...params, ...optionalParams },
        });
        const response = data[hydra.MEMBER].map((element) =>
          computedList(element)
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    });
  };
  const makeLabel = (element) => {
    const { region1, iso2, region3, postcode } = element;
    return `${region3} (${iso2.split('-').pop()} - ${region1} - ${postcode})`;
  };
  const computedList = (
    element
  ): selectValueStandard | selectValueStandard[] => {
    if (Array.isArray(element)) {
      if (element.length === 0) return [];
      return element.map((item) => ({
        value: customFilter
          ? { filterValue: item[customFilter], iri: element["@id"] }
          : item["@id"],
        label: makeLabel(item),
      }));
    } else {
      // Se element è un oggetto singolo, restituiamo un array contenente un solo oggetto

      return (
        element && {
          value: customFilter
            ? { filterValue: element[customFilter], iri: element["@id"] }
            : element["@id"],
          label: makeLabel(element),
        }
      );
    }
  };
  const saveSingleIri = (value) => {
    if (!customFilter) {
      setIriOfSelectElement(value);
      setValue(name, value);
    } else {
      setIriOfSelectElement(value.iri);
      setValue(name, value.filterValue);
    }
  };
  const getValue = async () => {
    const params = takeIdFromIri(iriOfSelectElement)
      ? { params: { id: takeIdFromIri(iriOfSelectElement) } }
      : {};
    const { data } = await apiCall(HTTPMethod.GET, `/${path}`, params);
    const selected = data[hydra.MEMBER][0];
    setcompleteSelected(selected);
  };
  const { computedSelectedValue } = useMemo(() => {
    return {
      computedSelectedValue: completeSelected
        ? computedList(completeSelected)
        : null,
    };
  }, [completeSelected]);
  const checkElement = (element) => {
    const { region1, region2, region3, postcode } = element;
    return region1 && region2 && region3 && postcode;
  };
  useEffect(() => {
    if (iriOfSelectElement && !Array.isArray(iriOfSelectElement)) {
      initializeValue();
    }
    if (Array.isArray(iriOfSelectElement) && iriOfSelectElement.length > 0) {
      initializeValue();
    } else {
      setcompleteSelected([]);
    }
    setLoading(false);
  }, [iriOfSelectElement]);

  return (
    <>
      {loading ? (
        <Skeleton />
      ) : showInput ? (
        <>
          <>
            <OutsideClickWrapper onClickOutside={() => setShowInput(false)}>
              {" "}
              <AsyncSelect
                cacheOptions
                defaultOptions
                isDisabled={disabled}
                classNamePrefix="react-select"
                defaultInputValue={valueInput}
                value={computedSelectedValue}
                onChange={(e: any) => {
                  saveSingleIri(e.value);
                }}
                loadOptions={getOptions}
              />
            </OutsideClickWrapper>
          </>
        </>
      ) : (
        <div onClick={() => setShowInput(true)}>
          {completeSelected && checkElement(completeSelected) ? (
            makeLabel(completeSelected)
          ) : (
            <EmptyPlaceholder nameDetail={nameDetail} nameField={nameField} />
          )}
        </div>
      )}
    </>
  );
}
