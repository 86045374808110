const useLogger = (tag: string) => {

  const info = (...data: any[]) => {
    console.info(`INFO [${tag}]:`, ...data);
  }

  /**
   * Logs debug messages.
   * @param {...any[]} data - The data to log.
   */
  const debug = (...data: any[])  => {
    console.debug(`DEBUG [${tag}]:`, ...data);
  }

  /**
   * Logs error messages.
   * @param {...any[]} data - The data to log.
   */
  const error = (...data: any[]) => {
    console.error(`ERROR [${tag}]:`, ...data);
  }

  // return methods
  return {info, debug, error};
};

export default useLogger;
