import { HTTPMethod } from "../utils/helper";
import { apiCall } from "../utils/serviceCall";

export interface CompanyUserListInput {
  id: [];
  "user.groupUser.group"?: string;
  "user.active": boolean;
  "exists[deletedAt]"?: boolean;
}

/**
 * API Controller for companyUser entity
 */
export class CompanyUserControllerApi {
  /**
   * Retrieves a list of companyUser entity by input parameters
   * @param params - Optional parameters
   * @returns An array of CompanyUsers matching given filters
   */
  static async getCompanyUsers(params?: CompanyUserListInput) {
    return apiCall(HTTPMethod.GET, `/companies_users`, {params: params});
  }
}
