import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { HTTPMethod, parseDashboardDetailsPath } from "../../../utils/helper";
import { useDispatch } from "react-redux";
import { CombineReducersState, store } from "../../../redux/store";
import { useSelector } from "react-redux";
import { parseDataFromGlobalContext } from "../../../redux/stores/rootContext";
import Form2 from "../../Form/Form2";
import { apiCall, apiCallFile } from "../../../utils/serviceCall";
import { useIntl } from "react-intl";
import {
  MessagesReducerTypeActions,
  MessageTypeEnum,
} from "../../../redux/stores/message/messageReducer";
export default function FormAccordionEditor({ props }) {
  // location
  const location = useLocation();
  let pathstart = location.pathname;
  const { pathId, pathName } = parseDashboardDetailsPath(pathstart);
  const pathImage = "/attachments";
  const [data, setData] = useState<{ [key: string]: any }>({
    companyAssestments: `/api/${pathName}/${pathId}`,
  });
  const { name, path,stepLabel } = props.type.editor;
  const intl = useIntl();

  //store
  const globalState = store.getState();
  const dispatch = useDispatch();

  // fintEntity
  const allEntity = parseDataFromGlobalContext(globalState.rootContext.apiDocs);
  const findEntity = allEntity
    ? allEntity.find((entity) => {
        return entity.name === path;
      })
    : null;
  const userData = useSelector(
    (state: CombineReducersState) => state.userReducer.userData
  )!;
  const fields = findEntity?.fields;

  const getValue = async () => {
    const id = props.data.split("/").pop();
    try {
      const { data } = await apiCall(HTTPMethod.GET, `/${path}/${id}`);
      setData(data);
    } catch (err) {
      console.log(err);
    }
  };
  const handleChangeForm = async (form) => {
    const objToSend = { companyPreAssesstment: `/api/${pathName}/${pathId}` };

    if (form.file) {
      form.file.forEach(async (element, index) => {
        const formData = new FormData();
        formData.append("typology", "file");
        formData.append("company", form.companyAssestments);
        formData.append("user", userData["@id"]);
        for (const key in form) {
          if (key === "file") {
            if (form[key].length === 1) {
              formData.append(key, form[key][0]);
            } else {
              formData.append(key, element.file);
            }
          }
        }
        await apiCallFile(HTTPMethod.POST, `${pathImage}`, formData)
          .then((response) => {
            dispatch({
              type: MessagesReducerTypeActions.SET,
              payload: {
                type: MessageTypeEnum.SUCCESS,
                message: intl.formatMessage({
                  id: "Attachments.addSuccess",
                }),
              },
            });
          })
          .catch((error) => {
            dispatch({
              type: MessagesReducerTypeActions.SET,
              payload: {
                type: MessageTypeEnum.DANGER,
                message: intl.formatMessage({
                  id: "Attachments.addError",
                }),
              },
            });
          });
      });
    }
    if (!props.data) {
      try {
        const { data } = await apiCall(HTTPMethod.POST, `/${path}`, {
          ...form,
          ...objToSend,
        });
        props.setValue({ name, value: data["@id"] });
        dispatch({
          type: MessagesReducerTypeActions.SET,
          payload: {
            type: MessageTypeEnum.SUCCESS,
            message: intl.formatMessage({ id: "SaveSuccess" }),
          },
        });
      } catch (error) {
        dispatch({
          type: MessagesReducerTypeActions.SET,
          payload: {
            type: MessageTypeEnum.DANGER,
            message: intl.formatMessage({ id: "SaveError" }),
          },
        });
      }
    } else {
      const idToChange = props.data.split("/").pop();
      try {
        await apiCall(HTTPMethod.PATCH, `/${path}/${idToChange}`, {
          ...form,
          ...objToSend,
        });
        dispatch({
          type: MessagesReducerTypeActions.SET,
          payload: {
            type: MessageTypeEnum.SUCCESS,
            message: intl.formatMessage({ id: "SaveSuccess" }),
          },
        });
      } catch (error) {
        dispatch({
          type: MessagesReducerTypeActions.SET,
          payload: {
            type: MessageTypeEnum.DANGER,
            message: intl.formatMessage({ id: "SaveError" }),
          },
        });
      }
    }
  };
  useEffect(() => {
    if (props.data) {
      getValue();
    }
  }, [props.data]);


  return (
    <>
      {fields && (
        <>
          <Form2
            fields={fields}
            data={data}
            listOpenAccordion={[0]} //lista delle accordion da aprire
            key={JSON.stringify(data)}
            stepLabel={stepLabel}
            nameEntity={path}
            onSave={(e) => {
              handleChangeForm(e);
            }}
          />
        </>
      )}
    </>
  );
}
