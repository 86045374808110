import React from "react";
import {Badge} from "react-bootstrap";
import CellInterface from "./CellInterface";
import { useIntl } from "react-intl";

const CellStatusBoolean = ({value, type, element}: CellInterface) => {
    let variant = value == 'true' ? 'success' : 'danger' ;
   const intl = useIntl()
    return (<>
        <div className={'CellStatusBoolean'}>
            <Badge bg={variant}>{intl.formatMessage({id:`Status.${value}`})}</Badge>
        </div>
    </>);
}

export default CellStatusBoolean;
