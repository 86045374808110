/* Reducers importing */
import rootContext, { GlobalContextState } from "./stores/rootContext";
import referenceReducer, {
  ReferenceState,
} from "./stores/reference/referenceReducer";
import settingReducer, { SettingState } from "./stores/setting/settingReducer";
import messageReducer, { MessageState } from "./stores/message/messageReducer";
import notificationReducer, {
  NotificationState,
} from "./stores/notification/notificationReducer";
/* Redux */
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";

/* Redux persist */
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import dndReducer, { DndState } from "./stores/dnd/dndReducer";
import userReducer, { UserState } from "./stores/user/userReducer";
import detailsReducer, { DetailsState } from "./stores/details/detailsReducer";
import filterReducer, {
  FilterState,
} from "./stores/filterReducer/filterReducer";
import statusDetailReducer, {
  statusDetailState,
} from "./stores/statusDetail/statusDetailReducer";
const persistConfig = {
  key: "root",
  storage,
};
export interface EntityParsed {
  name: string;
  $ref: string;
  tag: string | null;
  filters: any;
  fields: any;
  required?: string[];
}
export interface CombineReducersState {
  rootContext: GlobalContextState;
  referenceReducer: ReferenceState;
  dndReducer: DndState;
  userReducer: UserState;
  settingsReducer: SettingState;
  messageReducer: MessageState[];
  notificationReducer: NotificationState;
  detailsReducer: DetailsState;
  filterReducer: FilterState;
  statusDetailReducer: statusDetailState;
}
/* Reducers */
const rootReducer = persistReducer(
  persistConfig,
  combineReducers({
    /* All reducer functions below this line */
    rootContext,
    referenceReducer,
    dndReducer,
    userReducer,
    settingReducer,
    messageReducer,
    notificationReducer,
    detailsReducer,
    filterReducer,
    statusDetailReducer,
  })
);

/* Store */
export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
  reducer: rootReducer,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

/* Export store */
export const persistor = persistStore(store);
