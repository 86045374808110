import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";

interface WysiwygEditorProps {
    name: string;
    data: string;
    setValue: (name: string, value: string) => void;
    setShowInput: (show: boolean) => void;
}

export default function WysiwygEditor({props}) {
    function useOutsideAlerter(ref: React.RefObject<HTMLDivElement>) {
        useEffect(() => {
            function handleClickOutside(event: MouseEvent) {
                if (ref.current && !ref.current.contains(event.target as Node)) {
                    props.setValue(props.name, data);
                    props.setShowInput(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref, data]);
    }

    const [data, setData] = useState(props.data);
    const wrapperRef = useRef<HTMLDivElement>(null);
    useOutsideAlerter(wrapperRef);

    return (
        <div className={clsx('WysiwygEditor', `WysiwygEditor-${props.name}`)} ref={wrapperRef}>
            <CKEditor
                editor={ClassicEditor}
                data={data}
                onChange={(event, editor) => {
                    const newData = editor.getData();
                    setData(newData);
                }}
            />
        </div>
    );
}
