/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useCallback, useEffect, useState } from "react";
import { Paginator } from "../paginator/paginator";
import { useLocation, useNavigate } from "react-router-dom";
import {
  isIriReference,
  takeIdFromIri,
  takeNameFromIri,
  uniteValues,
} from "../../utils/helper";
import { useSelector } from "react-redux";
import { CombineReducersState } from "../../redux/store";
import CellAsString from "../cell/CellAsString";
import { DynamicCellComponents } from "../cell";
import { useIntl } from "react-intl";
import clsx from "clsx";
import Skeleton from "react-loading-skeleton";
import { Entities } from "../../apis/entities";
import { elements } from "chart.js";

interface ColumnSelectedInterface {
  selected: boolean;
  name: string;
  id: string;
  order: number;
}

interface TableProps {
  list: any[] | null;
  currentPage: number;
  view: any;
  fields: any | null;
  entity: any | null;
  loading: boolean;
  columnsSelected: ColumnSelectedInterface[];
  fieldToDelete?: string | null;
  multiSelect?: boolean;
  actions?: {
    delete: boolean;
    details: boolean;
    detailsOverridePath?: string;
    detailsOverrideId?: string;
  };
  selectedIds?: number[];
  deleteElement?: (id: number) => void;
  select?: (iri: string, checked: boolean) => void;
  allSelect?: (checked: boolean) => void;
}

const onClickStopPropagation = (e: any) => e.stopPropagation();

const Table: FC<TableProps> = ({
  list,
  currentPage,
  view,
  fields,
  loading,
  entity,
  columnsSelected,
  multiSelect,
  actions,
  fieldToDelete = null,
  selectedIds,
  deleteElement,
  select,
  allSelect
}) => {
  const intl = useIntl();

  const [forceReload, setForceReload] = useState(0);
  const [allSelected, setAllSelected] = useState(false);
  const stateReference = useSelector(
    (state: CombineReducersState) => state.referenceReducer
  );
  const location = useLocation();
  const [keys, setKeys] = useState<string[]>([]);
  const navigate = useNavigate();

  const now = new Date();

  useEffect(() => {
    if (list && list.length > 0) {
      setKeys(Object.keys(list[0]));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setForceReload(forceReload + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const toogleClickRow = (elementId) => {
    navigate(`${elementId}`);
  };
  const mainField = (el: any, type: any) => {
    const mainField = type.editor?.mainField ? type.editor.mainField : "name";
    const mainFieldReference = type.editor?.mainFieldReference;
    if (mainFieldReference) {
      const mainFieldReferencePath = mainFieldReference.path;
      const mainFieldReferenceFieldName = mainFieldReference.field;
      const currentStateReference = stateReference[mainFieldReferencePath];
      const iri = el[mainFieldReferenceFieldName];

      const findValue = currentStateReference.find((el) => {
        return el["@id"] === iri;
      });
      if (findValue && currentStateReference) {
        return uniteValues(findValue, mainFieldReference.mainField);
      } else {
        return null;
      }
    }
    if (el[mainField]) {
      return el[mainField];
    } else {
      return null;
    }
  };
  const renderedContent = useCallback(
    (type, currentReference, element, key) => {
      if (type.type === "string" || type.type.includes("string")) {
        const findEl = currentReference.find(
          (el) => el["@id"] === element[key]
        );
        return findEl ? <>{mainField(findEl, type)}</> : null;
      } else if (type.type === "array") {
        return (
          <>
            {element[key].length > 0 &&
              element[key].map((el, index) => {
                const findEl = currentReference.find(
                  (elRef) => elRef["@id"] === el
                );
                if (findEl && mainField(findEl, type)) {
                  return <div key={index}>{mainField(findEl, type) + ","}</div>;
                }
                return null;
              })}
          </>
        );
      }
      return null;
    },
    [stateReference]
  );

  const handleSelection = (iri: string, selected: boolean) => {
    if(!multiSelect || !select) return;
    select(iri, selected);
    setAllSelected(false);
  }

  const handleAllSelection = (selected: boolean) => {
    if(!multiSelect || !allSelect) return;
    allSelect(selected);
    setAllSelected(selected)
  }

  return (
    <>
      {!loading ? (
        <>
          {list && list.length > 0 ? (
            <>
              <div className={`tablesWidget card-xl-stretch mb-xl-8`}>
                <table className="table-rounded-rows w-100">
                  <thead>
                    <tr>
                      {list.some((e) => e.state) && (
                        <th className="stateTableHeader"></th>
                      )}
                      {columnsSelected
                        ?.filter((column, index) => column.selected)
                        .map((column, index) => (
                          <th key={`th-${index}-${column.id}`}>
                            <p className="titleList">
                              {intl.formatMessage({
                                id: `${entity.tag}.${column.name}`,
                              })}
                            </p>
                          </th>
                        ))}
                      {actions?.delete && (
                        <th>
                          <p className="titleList">
                            {intl.formatMessage({
                              id: `action`,
                            })}
                          </p>
                        </th>
                      )}
                    </tr>
                    <tr>
                      {multiSelect && (<th className="selectCell"><input className='form-check-input mt-2 m-auto border-success' type='checkbox' checked={allSelected} defaultChecked={allSelected} onChange={(event) => handleAllSelection(event.target.checked)} /></th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((element, index) => {
                      return (
                        <tr
                          className={clsx('row-element', `customRowBackground-${element.state}`, {
                            [`customRowBackground-Important`]: entity.name == Entities.COMPANIES && element.isImportant,
                            [`customRowBackground-Urgent`]: entity.name == Entities.COMPANIES && element.isUrgent
                          })}
                          key={`row-${index}`}
                          onClick={() =>{
                            if(actions?.details === false) return;
                            var id = element["@id"];
                            if(actions?.detailsOverrideId){
                              const identifiers = actions?.detailsOverrideId.split(".");
                              id = identifiers.reduce((a,b) => a[b], element);
                            }
                            if(actions?.detailsOverridePath){
                              return window.open(`${window.location.origin}`+(actions?.detailsOverridePath || '') + id.split("/").pop(), '_blank');
                            }
                            return toogleClickRow(id.split("/").pop())
                          }
                          }
                        >
                          {element.state && (
                            <div
                              className={clsx(
                                "barStatus", `customBorder-${element.state}`,
                                 {
                                  [`customBorder-future`]: entity.name == Entities.COMPANIES && element.alertDate && new Date(element.alertDate) > now,
                                  [`customBorder-past`]: entity.name == Entities.COMPANIES && !element.alertDate || (element.alertDate && new Date( element.alertDate ) <= now),
                                })}
                            ></div>
                          )}
                          {multiSelect && (<td className="selectCell">
                            <input className='form-check-input mt-2 m-auto border-success' checked={selectedIds?.includes(Number(takeIdFromIri(element['@id'])))} type='checkbox' onChange={(event)=> handleSelection(element["@id"], event.target.checked)} onClick={onClickStopPropagation}/>
                          </td>)}
                          {columnsSelected
                            ?.filter((column, index) => column.selected)
                            .map((column, idx) => {
                              const dynamicComponentName =
                                typeof fields[column.name]?.list !== "undefined"
                                  ? fields[column.name]?.list.name
                                  : fields[column.name]?.format;

                              const DynamicCellComponent =
                                DynamicCellComponents[dynamicComponentName];

                              if (DynamicCellComponent) {
                                return (
                                  <td key={`row-td-${idx}`}>
                                    <DynamicCellComponent
                                      value={element[column.name]}
                                      type={fields[column.name]}
                                      element={element[column.name]}
                                      key={column.name}
                                    ></DynamicCellComponent>
                                  </td>
                                );
                              }
                              if (
                                fields &&
                                fields[column.name] &&
                                isIriReference(fields[column.name])
                              ) {
                                const type = fields[column.name];
                                const name = takeNameFromIri(
                                  element[column.name]
                                );
                                if (name) {
                                  const currentReference = stateReference[name];
                                  if (currentReference) {
                                    return (
                                      <td key={`row-td-${idx}`}>
                                        {renderedContent(
                                          type,
                                          currentReference,
                                          element,
                                          column.name
                                        )}
                                      </td>
                                    );
                                  }
                                }
                              }

                              return (
                                <td key={`row-td-${idx}`}>
                                  <CellAsString
                                    value={element[column.name]?.toString()}
                                    type={fields[column.name]}
                                    element={element[column.name]}
                                  ></CellAsString>
                                </td>
                              );
                            })}
                          {actions && (
                            <td>
                              {actions.delete && deleteElement && (
                                <div
                                  className="icon-wrapper"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    window.confirm(
                                      intl.formatMessage({
                                        id: "window.delete",
                                      })
                                    ) &&
                                      deleteElement(
                                        fieldToDelete
                                          ? element[fieldToDelete]
                                          : parseInt(
                                              element["@id"].split("/").pop()
                                            )
                                      );
                                  }}
                                >
                                  <i className="fa-solid fa-trash-can" />
                                </div>
                              )}
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <Paginator
                key={location.search}
                view={view}
                currentPage={currentPage}
              />
            </>
          ) : (
            <div className="empty-element">
              <i className="fa-regular fa-file"></i>
              <p>{intl.formatMessage({ id: "Empty.elements" })}</p>
            </div>
          )}
        </>
      ) : (
        <>
          <Skeleton
            style={{ minHeight: "100px", borderRadius: "6px" }}
            count={5}
          ></Skeleton>
        </>
      )}
    </>
  );
};

export default Table;
