//Da fare 


export enum statusDetailReducerTypeActions {
    SET = 'statusDetail/set-statusDetail'
}

export interface statusDetailReducerActions {
    type: statusDetailReducerTypeActions,
    payload: statusDetailState
}

export interface statusDetailState {
   modifiedDetails:boolean
}

const initialstatusDetailState: statusDetailState = {
    modifiedDetails:false
};

export default function statusDetailReducer(state: statusDetailState = initialstatusDetailState, action: statusDetailReducerActions): statusDetailState {
    switch (action.type) {

        case statusDetailReducerTypeActions.SET:
        return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}