import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, createSearchParams, useLocation } from "react-router-dom";
import {
  filterSchemaData,
  parseDataFromGlobalContext,
} from "../../redux/stores/rootContext";
import { CombineReducersState, store } from "../../redux/store";
import CustomButton from "../buttons/CustomButton";
import clsx from "clsx";
import { FilterInput } from "./FilterInput";
import { actionsTypeFilter } from "../../redux/stores/filterReducer/filterReducer";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  filterObject,
  getDefaultFilterValue,
  parseRequestQuery,
} from "../../utils/helper";
import { DashboardContext } from "../../context/DashboardContext";

export function FilterHeader() {
  const [enableFilterState] = useContext(DashboardContext);
  const [filters, setFilters] = useState<filterSchemaData[]>([]);
  const stateFilter = useSelector(
    (state: CombineReducersState) => state.filterReducer
  );
  const componentRef = useRef<null | HTMLDivElement>(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const globalState = store.getState();
  let pathname = location.pathname.split("/")[1];
  const dashboard: boolean = location.pathname.includes("/dashboard");
  if (dashboard) {
    pathname = "companies";
  }

  const allEntity = parseDataFromGlobalContext(globalState.rootContext.apiDocs);
  const currentEntity = allEntity?.find((entity) => entity.name === pathname);
  // controlla se sono presenti valori di default guardare layout.json defaultValue
  const defaultValueFilter = getDefaultFilterValue(currentEntity, dashboard);
  const filterFromUrl =
    location.search !== ""
      ? parseRequestQuery(location.search)
      : defaultValueFilter;
  const [form, setForm] = useState(filterFromUrl);

  const [disable, setDisable] = useState<boolean>(true);
  const [focus, setFocus] = useState<boolean>(false);
  const navigate = useNavigate();
  const [key, setKey] = useState<number>(0);

  const handleSubmit = () => {
    let changeFilter = form;
    let filtersToRemove = Object.keys(changeFilter).filter(
      (key) => changeFilter[key] === "" || !changeFilter[key]
    );
    console.log(changeFilter)

    filtersToRemove.forEach((filter) => delete changeFilter[filter]);
    console.log(changeFilter)
    // debugger
    if (Object.keys(changeFilter).length > 0) {
      navigate({
        search: `?${createSearchParams(changeFilter)}`,
      });
    }else{
      handleClearFilters()
    }
  };

  const handleValue = (obj) => {
    setForm((prevState) => ({
      ...prevState,
      ...obj,
    }))
  };

  const handleClearFilters = () => {
    dispatch({
      type: actionsTypeFilter.clear,
      payload: { name: pathname, value: defaultValueFilter },
    });
    setForm(defaultValueFilter);
    navigate({
      search: "",
    });
    setKey(key + 1);
  };

  const initialFilter = () => {
    let result = allEntity?.find((entity) => {
      return entity.name === pathname;
    });

    if (result) {
      if (result.filters) {
        const filteredFilters = result.filters.filter(
          (filter) =>
            !filter.name.includes("itemsPerPage") &&
            !filter.name.includes("page") &&
            !(dashboard && filter.name === "following")
        );
        setFilters(filteredFilters);
      }
    }
  };

  useEffect(() => {
    if (allEntity) {
      initialFilter();
    }
  }, []);

  useEffect(() => {
    setForm(filterFromUrl);
  }, [location]);
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter" && focus) {
        handleSubmit();
      }
    };
    const handleDocumentClick = (event) => {
      // Verifica se l'evento di clic si è verificato all'esterno del componente
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setFocus(false);
      } else {
        setFocus(true);
      }
    };
    // document.addEventListener("click", () => setFocus(true));
    document.addEventListener("keydown", handleKeyPress);
    document.addEventListener("mousedown", (e) => {
      handleDocumentClick(e);
    });

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
      document.removeEventListener("mousedown", (e) => {
        handleDocumentClick(e);
      });
    };
  }, [focus, form]);
  let cloneFilter: any = filters;
  if (!enableFilterState) {
    cloneFilter = cloneFilter.filter((el) => el.name !== "companyGroups.state");
  }
  return (
    <div ref={componentRef} className="w-100">
      {cloneFilter && cloneFilter.length > 0 && pathname && (
        <>
          <div className={"filter-header"} key={key}>
            <div
              className={clsx("d-flex", "filters")}
              style={{ flex: 1, gap: "5px" }}
            >
              <div className="col-3" style={{ marginRight: "15px" }}>
                {cloneFilter
                  .filter(
                    (filter) => filter.schema && filter.schema.column === 1
                  )
                  .map((filter, idx) => (
                    <FilterInput
                      key={idx}
                      filter={filter}
                      idx={idx}
                      form={form}
                      handleValue={handleValue}
                    />
                  ))}
              </div>
              <div className="col-3" style={{ marginRight: "15px" }}>
                {cloneFilter
                  .filter(
                    (filter) => filter.schema && filter.schema.column === 2
                  )
                  .map((filter, idx) => (
                    <FilterInput
                      key={idx}
                      filter={filter}
                      idx={idx}
                      form={form}
                      handleValue={handleValue}
                    />
                  ))}
              </div>
              <div className="col-3" style={{ marginRight: "15px" }}>
                {cloneFilter
                  .filter(
                    (filter) => filter.schema && filter.schema.column === 3
                  )
                  .map((filter, idx) => (
                    <FilterInput
                      key={idx}
                      filter={filter}
                      idx={idx}
                      form={form}
                      handleValue={handleValue}
                    />
                  ))}
              </div>
              <div className="col-3">
                {cloneFilter
                  .filter(
                    (filter) => filter.schema && filter.schema.column === 4
                  )
                  .map((filter, idx) => (
                    <FilterInput
                      key={idx}
                      filter={filter}
                      idx={idx}
                      form={form}
                      handleValue={handleValue}
                    />
                  ))}
              </div>
            </div>

            <div className="d-flex flex-column gap-3">
              <CustomButton onClick={handleSubmit}>Cerca</CustomButton>
              <CustomButton
                disable={
                  !(
                    location.search !== "" ||
                    stateFilter[location.pathname.split("/")[1]] == undefined
                  )
                }
                onClick={handleClearFilters}
              >
                Cancella Filtri
              </CustomButton>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
